import React from "react";
import { Modal, Box, Typography, IconButton, Paper, List, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./RiskRegister.css";
import { 
  ErrorOutline as ErrorOutlineIcon, 
  WarningAmber as WarningAmberIcon, 
  Dangerous as DangerousIcon, 
  CheckCircle, 
  Warning, 
  Error 
} from "@mui/icons-material";
import "./RiskRegister.css";

const RiskPopup = ({ open, onClose, type }) => {
    
  const getTitleAndContent = () => {
    switch (type) {
      case "impact":
        return {
          content: (
            <Box sx={{ p: 2 ,mt: 0}}>
            <Typography className="impact-typography1" variant="h5" component="p" gutterBottom>
               🚨 Severity / Impact:
             </Typography>
       
             <List className="impact-list">
               <Paper className="low-impact">
                 <ErrorOutlineIcon className="low-error-icon" />
                 <Typography variant="body1" className="impact-typography">
                   The impact may either be controlled or would commonly result in less than moderate or acceptable damage to the business.
                 </Typography>
               </Paper>
       
               <Paper className=" moderate-impact">
                 <WarningAmberIcon className="warning-icon" />
                 <Typography variant="body1" className="impact-typography">
                   The impact may commonly cause moderate damage to the business, requiring immediate corrective action.
                 </Typography>
               </Paper>
       
               <Paper className="high_impact">
                 <DangerousIcon className="danger-icon" />
                 <Typography variant="body1" className="impact-typography">
                   The impact may commonly cause severe damage to the business, requiring immediate cessation of the unsafe operation.
                 </Typography>
               </Paper>
       
             </List>
           </Box>
          )
        };

      case "likelihood":
        return {
          title: "Probability / Likelihood",
          content: (
            <List sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
  <Paper sx={{ width: "90%", maxWidth: "400px", padding: "10px", backgroundColor: "#c8e6c9" }}>
    <Typography variant="body1">Unlikely but possible to occur rarely during standard operations</Typography>
  </Paper>

  <Paper sx={{ width: "90%", maxWidth: "400px", padding: "10px", backgroundColor: "#ffecb3", marginTop: "10px" }}>
    <Typography variant="body1">Likely to occur a few times during standard operations</Typography>
  </Paper>

  <Paper sx={{ width: "90%", maxWidth: "400px", padding: "10px", backgroundColor: "#ffccbc", marginTop: "10px" }}>
    <Typography variant="body1">Likely to occur often during standard operations</Typography>
  </Paper>
</List>
          )
        };

      case "riskMatrix":
        return {
          title: "Risk Matrix",
          content: (
            <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: "20px",
  }}
>
  <table
    className="risk-matrix-table"
    style={{
      width: "100%", // Makes the table take full width of the modal
      maxWidth: "700px", // Increase size but limit excessive stretching
      height: "auto",
      borderCollapse: "collapse",
    }}
  >
    <thead>
      <tr>
        <th style={{ color: "black", fontSize: "18px", padding: "12px" }}></th>
        <th style={{ color: "black", fontSize: "18px", padding: "12px" }}>Low</th>
        <th style={{ color: "black", fontSize: "18px", padding: "12px" }}>Medium</th>
        <th style={{ color: "black", fontSize: "18px", padding: "12px" }}>High</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style={{ fontWeight: "bold", color: "black" }}>High</td>
        <td className="medium" style={{ padding: "20px" }}>Medium</td>
        <td className="high" style={{ padding: "20px" }}>High</td>
        <td className="high" style={{ padding: "20px" }}>High</td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold", color: "black" }}>Medium</td>
        <td className="low" style={{ padding: "20px" }}>Low</td>
        <td className="medium" style={{ padding: "20px" }}>Medium</td>
        <td className="high" style={{ padding: "20px" }}>High</td>
      </tr>
      <tr>
        <td style={{ fontWeight: "bold", color: "black" }}>Low</td>
        <td className="low" style={{ padding: "20px" }}>Low</td>
        <td className="low" style={{ padding: "20px" }}>Low</td>
        <td className="medium" style={{ padding: "20px" }}>Medium</td>
      </tr>
    </tbody>
  </table>
</Box>

          )
        };

      case "criteria":
        return {
          title: "Risk Value Criteria",
          content: (
            <>
              <Typography variant="body1" component="p" className="risk-rating1">
                 Risk Rating = Likelihood * (sum of Impact on CIA)
               </Typography>
               <Grid container spacing={3} sx={{ mt: 1 }} justifyContent="center">
  <Grid item xs={10} sm={6} md={3.8}>
    <Paper
      className="criteria-low"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "180px", // Set a fixed height for all boxes
        padding: "16px",
      }}
    >
      <CheckCircle className="check-icon" />
      <Typography variant="h6" className="risk-level">
        <strong>Low</strong>
      </Typography>
      <Typography variant="body1" className="risk-rating-low">
        Risk Rating: Less than or Equal to 9
      </Typography>
    </Paper>
  </Grid>

  <Grid item xs={10} sm={6} md={3.8}>
    <Paper
      className="criteria-medium"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "180px", // Fixed height for all boxes
        padding: "16px",
      }}
    >
      <Warning className="warning-icon" />
      <Typography variant="h6" className="risk-level">
        <strong>Medium</strong>
      </Typography>
      <Typography variant="body1" className="risk-rating-medium">
        Risk Rating: Greater than 9 and up to 18
      </Typography>
    </Paper>
  </Grid>

  <Grid item xs={10} sm={6} md={3.8}>
    <Paper
      className="criteria-high"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        height: "180px", // Fixed height for all boxes
        padding: "16px",
      }}
    >
      <Error className="error-icon" />
      <Typography variant="h6" className="risk-level">
        <strong>High</strong>
      </Typography>
      <Typography variant="body1" className="risk-rating-high">
        Risk Rating: Greater than 18
      </Typography>
    </Paper>
  </Grid>
</Grid>

            </>
          )
        };

      default:
        return { title: "", content: null };
    }
  };

  const { title, content } = getTitleAndContent(type);

  return (
    <Modal
  open={open}
  onClose={onClose}
  aria-labelledby="info-modal-title"
  aria-describedby="info-modal-description"
  sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }} // Centers modal
>
  <Box
    sx={{
      backgroundColor: "white",
      borderRadius: 2,
      boxShadow: 24,
      padding: 3,
      maxWidth: "600px", // Limits max width for better balance
      width: "90%", // Ensures it expands equally on all screen sizes
      display: "flex",
      flexDirection: "column",
    }}
  >
    {/* Title and Close Button */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        paddingBottom: 2,
        marginBottom: 2,
      }}
    >
      <Typography id="info-modal-title" variant="h6" component="h2" sx={{ flexGrow: 1, textAlign: "center" }}>
        {title}
      </Typography>
      <IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
        <CloseIcon />
      </IconButton>
    </Box>

    {/* Content Section */}
    <Box
      sx={{
        overflowY: "auto",
        maxHeight: "400px", // Ensures it does not overflow too much
        paddingX: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Ensures content stays centered
        textAlign: "center", // Centers text
      }}
    >
      {content}
    </Box>
  </Box>
</Modal>

  );
};

export default RiskPopup;
