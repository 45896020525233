import { createContext, useState, useEffect } from "react";
import Loadingimg from "../img/loadinghumming.gif"; // Import the loading image

// Create User Context
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Initialize state with sessionStorage if available, otherwise default to "guest"
  const [sessionUser, setSessionUser] = useState(() => {
    const storedUser = sessionStorage.getItem("user");
    return storedUser
      ? JSON.parse(storedUser)
      : { userRole: "guest", userName: "Guest User", profileImage: "" }; // Default values
  });

  // State to manage logout loading
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  // Function to update user data
  const updateUser = (userData) => {
    const updatedUser = {
      ...userData,
      userRole: userData.userRole || "guest", // Set default to "guest" if undefined
      userName: userData.userName || "Guest User", // Default name
      profileImage: userData.profileImage || "", 
    };

    setSessionUser(updatedUser);
    sessionStorage.setItem("user", JSON.stringify(updatedUser)); // Store in sessionStorage
  };

  // Function to clear user session (Logout)
  const logoutUser = () => {
    // Show loading screen
    setIsLoggingOut(true);

    // Clear session storage
    sessionStorage.removeItem("user");

    // Set state to default first
    setSessionUser({ userRole: "guest", userName: "Guest User", profileImage: "" });

    // Wait a moment to ensure React updates before reloading
    setTimeout(() => {
      window.location.reload();
    }, 1500); // 1.5 seconds delay for smooth logout transition
  };

  return (
    <UserContext.Provider value={{ sessionUser, updateUser, logoutUser }}>
      {isLoggingOut ? ( // Show loading screen when logging out
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            background: "#fefefe",
          }}
        >
          <img src={Loadingimg} alt="Logging out..." />
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  );
};
