import React, { useState, useEffect, useMemo, useContext, useCallback } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Form } from "react-bootstrap";
import { IconButton } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem, Checkbox } from '@mui/material';
import { UserContext } from "../../../auth/UserContext";
import AssignUserComponent from "../../../ReusableComponents/AssignUserComponent.js";
import CustomButton from "../../../ReusableComponents/CustomButton.js";
import SearchBox from "../../../ReusableComponents/SearchBox.js";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Popper from "@mui/material/Popper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import ArifactInfo from "./ArtifactInfo";
import Assignuser12 from "../../AssignuserModels/ArtifactAssignusermodel";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import S3 from "react-aws-s3";
import Modal from "@mui/material/Modal";
import AttachFileIcon from "@mui/icons-material/FilePresent";
import Badge from "@mui/material/Badge";
import TaskAltIcon from "@mui/icons-material/InsertDriveFile";
import useWindowDimensions from "../../../useWindowDimensions";
import { deepOrange, deepPurple } from "@mui/material/colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import "../../../ButtonStyles/AppStyles.css";
import CustomTable from "../../../ReusableComponents/CustomTable.js";


import cookie from "react-cookies";
import Skeleton from "@mui/material/Skeleton";
import { decryption } from "../../../auth/utilities";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Avatar,
  Backdrop,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Drawer,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Switch,
  TextField,
  Snackbar,
  Alert
} from "@mui/material";

import PersonIcon from "@mui/icons-material/Person";
import { blue } from "@mui/material/colors";
// import Addsubartifact_popup from "./Addsubartifact_popup";
import Loadingbutton from "@mui/lab/LoadingButton";
import LinearProgress from "@mui/material/LinearProgress";
import fetchData from "../../../api/apiService.js";
import MultiSelect from "../../../ReusableComponents/MultiSelect.js";
import { useSnackbar } from "../../../ReusableComponents/SnackbarManager.js"; // Import CustomButton
import { debounce } from "lodash";


window.Buffer = window.Buffer || require("buffer").Buffer;
const style1 = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const style12 = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "80%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const style = {
  position: "absolute",
  top: "70%",
  left: "30%",
  transform: "translate(-50%, -50%)",
  minWidth: 200,
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 170,
    maxWidth: "100%",
    textOoverflow: "ellipsis",

    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
function Myartifacts(event) {

  const subdomain = window.location.host.split(".")[0];
  const [checkList, setCheckList] = useState([]);
  const [question, setQuestion] = useState(null);
  const [openid, setopenid] = useState();
  const [Loading, setLoading] = React.useState(false);
  const [gaps, setgaps] = useState([]);
  const { height, width } = useWindowDimensions();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Open1, setOpen1] = useState(false);
  const [SubArtifact, setSubArtifact] = useState([]);
  const [modalopen, setmodalOpen] = React.useState(false);
  const handlemodalOpen = () => setmodalOpen(true);
  const handlemodalClose = () => setmodalOpen(false);
  const [tenant_artifact_id, settenant_artifact_id] = useState({});
  const [openform, setOpenform] = React.useState(false);
  const handleOpenform = () => setOpenform(true);
  const [EveidanceDate, setEveidanceDate] = React.useState("");
  const [Eveidance_exp_Date, setEveidance_exp_Date] = React.useState("");
  const [S3filelink, setS3filelink] = React.useState(null);
  const [Mapping, setMapping] = React.useState(false);
  const [Artifact, setartifact] = useState([1]);
  const [openform0, setOpenform0] = React.useState(false);
  const [Uploading, setUploading] = React.useState(false);
  const handleOpenform0 = () => setOpenform0(true);
  const [openform01, setOpenform01] = React.useState(false);
  const handleOpenform01 = () => setOpenform01(true);
  const handleCloseform01 = () => setOpenform01(false);
  const [EveidanceName0, setEveidanceName0] = React.useState("");
  const [EveidanceDate0, setEveidanceDate0] = React.useState("");
  const [Eveidance_exp_Date0, setEveidance_exp_Date0] = React.useState("");
  const [userList, setUserList] = useState([]);
  const [selectedCompliance, setSelectedCompliance] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState([]);
  const [selectedControl, setSelectedControl] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");  
  const [orderBy, setOrderBy] = useState("artifact_name"); 
  const [page, setPage] = useState(0);   
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const { sessionUser, updateUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [totalFilteredCount, setTotalFilteredCount] = useState(0); // Stores total count of filtered items
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 10,
  });
  const [artifactData, setArtifactData] = useState([]);
  const [complianceOptions, setComplianceOptions] = useState([]);
  const [processOptions, setProcessOptions] = useState([]);
  const [standardOptions, setStandardOptions] = useState([]);
  const showSnackbar = useSnackbar(); // ✅ Assign function correctly


  






  const columns = [
   
    {
      key: "artifact_name",
      title:
        { ar: "اسم العملية", sp: "Spanish" }[cookie.load("Language")] ||
        "Directory Name",
      width: "40%",
      render: (item) => (
        <div
          onClick={() => {
            getSubArtifact(item.tenant_artifact_id);
            settenant_artifact_id(item.tenant_artifact_id);
          }}
          style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}
        >
          <span style={{ fontWeight: "bold", color: "#001b2b", fontSize: "14px" }}>
            {item.artifact_name}
          </span>
          <span style={{ color: "#808080", marginTop: "5px" }}>
            {item.artifact_description}
          </span>
        </div>
      ),
    },
    {
      key: "assigned_to",
      title:
        { ar: "اسم العملية", sp: "Spanish" }[cookie.load("Language")] ||
        "Assign User",
      width: "10%",
      render: (item) => (
        <AssignUserComponent
          selectedUser={item.assigned_to || "Unassigned"}
          profileImage={item.profile_image}
          availableUsers={userList}
          onUserSelect={(user) => handleAssignUser(user,item.tenant_artifact_id) }
        />
      ),
    },
  ];

  
 
  const handleCloseform0 = () => {
    setOpenform0(false); 
    setS3filelink(null);  
    setUploading(false); 
    setS3filelink0(null); 
  };

  const handleCloseform = () => {
    setOpenform(false);   
    setS3filelink(null);   
    setUploading(false);   
    setS3filelink0(null); 
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Assuming that question is the data you want to filter.
  const safeData = question || [];


  const [S3filelink0, setS3filelink0] = React.useState(null);
  const [Mappind_masterdata, setMappind_masterdata] = useState({
    compliance: [
      {
        id: 1,
        label: "Loading...",
      },
    ],
    process: [
      {
        id: 1,
        label: "Loading...",
      },
    ],
    control: [
      {
        id: 1,
        label: "Loading...",
      },
    ],
  });




 








// Filtered data to render the artifacts
const processedData = useMemo(() => {
  const filtered = safeData.filter((item) => {
    const complianceMatch =
      selectedCompliance.length === 0 ||
      item.cpca?.some((c) => selectedCompliance.includes(c.compliance_name));

    const processMatch =
      selectedProcess.length === 0 ||
      item.cpca?.some((c) => selectedProcess.includes(c.process_name));

    const controlMatch =
      selectedControl.length === 0 ||
      item.cpca?.some((c) => selectedControl.includes(c.control_name));

    const standardMatch =
      selectedStandard.length === 0 ||
      item.standards?.some((s) => selectedStandard.includes(s.standard_name));

    const searchMatch =
      searchTerm === "" ||
      item.artifact_name?.toLowerCase().includes(searchTerm.toLowerCase()) || // ✅ NOW CHECKS ARTIFACT NAME
      item.cpca?.some(
        (c) =>
          c.compliance_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          c.process_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          c.control_name?.toLowerCase().includes(searchTerm.toLowerCase())
      ) ||
      item.standards?.some((s) =>
        typeof s === "string"
          ? s.toLowerCase().includes(searchTerm.toLowerCase())
          : s.standard_name?.toLowerCase().includes(searchTerm.toLowerCase())
      );

    return complianceMatch && processMatch && controlMatch && standardMatch && searchMatch;
  });

  const sorted = applySortFilter(filtered, getComparator(order, orderBy));
  return {
    filteredData: sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    totalFilteredCount: filtered.length 
  };
}, [
  safeData, 
  selectedCompliance, 
  selectedProcess, 
  selectedControl, 
  selectedStandard, 
  searchTerm, 
  order, 
  orderBy, 
  page, 
  rowsPerPage
]);

const { filteredData } = processedData;


  // Function to check the status of sub-artifacts and return the appropriate chip label
  const getArtifactChipLabel = (artifact) => {
    if (!artifact.SubArtifact || artifact.SubArtifact.length === 0) {
      return "Not Uploaded";
    }

    const allSubArtifactsExpired = artifact.SubArtifact.every((subArtifact) => {
      return (
        !subArtifact.artifacts ||
        subArtifact.artifacts.length === 0 ||
        subArtifact.artifacts.every((artifact) => new Date(artifact.date_of_expiry) < new Date())
      );
    });

    if (allSubArtifactsExpired) {
      return `${artifact.artifact_name}: Expired`;
    } else {
      return "Uploaded";
    }
  };
  const [SubArtifactid, setSubArtifactid] = useState({});
  const [SelectedCompliance_id, setSelectedCompliance_id] = useState({});
  const [SelectedProcess_id, setSelectedProcess_id] = useState({});
  const [SelectedControl_id, setSelectedControl_id] = useState({});
  const handleOnChangecompliance = (event, value) => {
    setSelectedCompliance_id(value.id);
  };
  const handleOnChangeprocess = (event, value) => {
    setSelectedProcess_id(value.id);
  };
  const handleOnChangecontrol = (event, value) => {
    setSelectedControl_id(value.id);
  };
  const toggleDrawer1 = (newOpen) => () => {
    setOpen1(newOpen);
  };
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleUpload = (file, artifactId) => {
    setUploading(true);
    if (!file) {
      setSnackbarMessage("No file selected. Please select a file to upload.");
      setSnackbarType("error"); 
      setOpenSnackbar(true); 
      setUploading(false);
      return;
    }

    const filePath = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const contentType = file.type || "application/octet-stream";
  
    const apiUrl =
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/generate-presigned-urls/";

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": cookie.load("csrftoken"),
        },
        credentials: "include",
        body: JSON.stringify({
          artifact_id: artifactId,
          files: [
            {
              file_path: filePath,
              content_type: contentType,
            },
          ],
        }),
      })
      .then((response) => {
        if (!response.ok) 
          throw new Error("Error while uploading, please retry!");
        return response.json();
      })
      .then((data) => {
        const presignedUrl = data.presigned_urls[0]?.presigned_url;
        if (!presignedUrl) 
          throw new Error("Failed to upload!");

        return fetch(presignedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": contentType,
          },
          body: file,
        }).then((uploadResponse) => {
          if (!uploadResponse.ok) 
            throw new Error("Failed to upload file.");

          const uploadedFileUrl = presignedUrl.split("?")[0];
          setS3filelink(uploadedFileUrl);
          setUploading(false);
          setSnackbarMessage("File uploaded successfully!");
          setSnackbarType("success");
          setOpenSnackbar(true);
        });
      })
      .catch((error) => {
        setUploading("error");
        setSnackbarMessage(error.message || "An unexpected error occurred.");
        setSnackbarType("error"); 
        setOpenSnackbar(true); 
      }
    );
  };
 
  const renderFileViewer = (link) => {
    const mainPart = link.split("?AWSAccessKeyId")[0];
    const fileExtension = mainPart.split(".").pop().toLowerCase();
    if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={link}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["doc", "docx", "ppt", "pptx", "txt"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(link)}&embedded=true`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    
    if (["xls", "xlsx"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(link)}`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      return (
        <img
          src={link}
          alt="Artifact"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      );
    }
    return <Typography variant="h6">Unsupported file type</Typography>;
  };
  const handleUpload0 = (file, artifactId) => {
    setUploading(true);
    if (!file) {
      setSnackbarMessage("No file selected. Please select a file to upload.");
      setSnackbarType("error"); 
      setOpenSnackbar(true); 
      setUploading(false);
      return;
    }
  
    const filePath = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const contentType = file.type || "application/octet-stream";
  
    const apiUrl =
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/generate-presigned-urls/";

      fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": cookie.load("csrftoken"),
        },
        credentials: "include",
        body: JSON.stringify({
          artifact_id: artifactId,
          files: [
            {
              file_path: filePath,
              content_type: contentType,
            },
          ],
        }),
      })
      .then((response) => {
        if (!response.ok) 
          throw new Error(" Error while uploading, please retry!");
        return response.json();
      })
      .then((data) => {
        const presignedUrl = data.presigned_urls[0]?.presigned_url;
        if (!presignedUrl) 
          throw new Error("Failed to upload file!");

        return fetch(presignedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": contentType,
          },
          body: file,
        }).then((uploadResponse) => {
          if (!uploadResponse.ok) 
            throw new Error("Failed to upload file");

          const uploadedFileUrl = presignedUrl.split("?")[0];
          setS3filelink0(uploadedFileUrl);
          setUploading(false);
          setSnackbarMessage("File uploaded successfully!");
          setSnackbarType("success");
          setOpenSnackbar(true);
        });
      })
      .catch((error) => {
        setUploading("error");
        setSnackbarMessage(error.message || "An unexpected error occurred.");
        setSnackbarType("error"); 
        setOpenSnackbar(true); 
      }
    );
  };
  const [openModal, setOpenModal] = useState(false); // To control the modal open state
  const [artifactLink, setArtifactLink] = useState(null); // To store the link of the artifact
  const [isFullscreen, setIsFullscreen] = useState(false); // To control fullscreen mode

  // Function to open the modal with the selected artifact link
  const handleOpenArtifactModal = (link) => {
    setArtifactLink(link);
    setOpenModal(true);
  };

  // Function to close the modal
  const handleCloseArtifactModal = () => {
    setOpenModal(false);
    setArtifactLink(null);
  };

  // Function to toggle fullscreen mode
  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const Mappinglist = () => {
    fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/mappinglist/",
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setMappind_masterdata(data);
          Loading(false);
        } else {
          Loading(false);
        }
      })
      .catch((err) => { });
  };

  // Fetch Artifact Data & User List
  const fetchTenantArtifactData = useCallback(
    async (page = pagination.currentPage, pageSize = pagination.pageSize) => {
    if (!pageSize) return; // Prevent request with undefined pageSize

      setIsLoading(true);
      const queryParams = new URLSearchParams({
        page,
        page_size: pagination.pageSize,
      });

      if (selectedCompliance.length)
        queryParams.append("compliance", selectedCompliance.map((i) => i.id).join(","));
      if (selectedProcess.length)
        queryParams.append("business_process", selectedProcess.map((i) => i.id).join(","));
      if (selectedStandard.length)
        queryParams.append("standard", selectedStandard.map((i) => i.id).join(","));
      if (searchTerm) queryParams.append("search", searchTerm);

      try {
        const data = await fetchData(`artifact/tenanatartifactlist/?${queryParams.toString()}`);
        if (data) {
          setArtifactData(data.artifacts || []);
          setUserList(data.user_list || []);

          setPagination({
            totalItems: data.total_items,
            totalPages: data.total_pages,
            currentPage: data.current_page,
            pageSize: 10,
          });
        }
      } catch (error) {
        showSnackbar("Failed to fetch artifact data. Please try again.", "error");
      } finally {
        setIsLoading(false);
      }
    },
    [pagination.pageSize, selectedCompliance, selectedProcess, selectedStandard, searchTerm]
  );
   // Fetch Metadata for Dropdowns (Runs Only Once)
   useEffect(() => {
    fetchData("artifact/tenanatartifactlist/")
    .then((data) => {
      if (data) {
        const complianceMap = new Map(
          data.compliance_list.map((item) => [item.compliance_id, item.compliance_name])
        );
  
        setComplianceOptions(
          data.compliance_list.map((item) => ({
            id: item.compliance_id,
            name: item.compliance_name,
          }))
        );
  
        setProcessOptions(
          data.business_process_list.map((item) => ({
            id: item.business_process_id,
            name: item.business_process_name,
          }))
        );
  
        setStandardOptions(
          data.standard_list.map((item) => ({
            id: item.standard_id,
            name: ` ${item.standard_name} [ ${complianceMap.get(item.compliance) || "Unknown"} : ${item.standard_code} ]`,
          }))
        );
  
        setUserList(data.user_list || []); // Store user list
      }
    })
    .catch((error) => showSnackbar("An unexpected error occurred", "error"));
  
  }, []);

  // Debounce Search and Filter Changes
  useEffect(() => {
    const debouncedFetch = debounce(() => fetchTenantArtifactData(), 500);
    debouncedFetch();
    return () => debouncedFetch.cancel();
  }, [fetchTenantArtifactData]);

  // Pagination Handling
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages && newPage !== pagination.currentPage) {
      setPagination((prev) => ({ ...prev, currentPage: newPage }));
      fetchTenantArtifactData(newPage);
    }
  };

  
  const getSubArtifact = (e) => {
    setLoading(true);
    fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/getsubartifactlist/" +
      e +
      "/",
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setSubArtifact(data);
          setOpen1(true);
          handlemodalOpen();
          setLoading(false);
        } else {
          setSubArtifact([]);
          Loading(false);
        }
      })
      .catch((err) => { });
  };
  const getQuestionlistartifact = () => {
    fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/auditmodule/listartifact/" +
      SubArtifact.sub_artifact_id +
      "/",
      {
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setartifact(data);
          setLoading(false);
          handleCloseform();
        } else {
          setartifact(null);
          setLoading(false);
        }
      })
      .catch((err) => { });
  };

  
  let finalAns = Object.values(
    checkList.reduce(
      (acc, cur) => Object.assign(acc, { [cur.question_id]: cur }),
      {}
    )
  );

  const onClickshow = (e, f) => {
    if (f === openid) {
      setopenid(0);
    } else {
      setopenid(f);
    }
  };

  const parentFunction = (points, audit_header_id, audit_gap_id) => {
    setLoading(true);
    const requestOption = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        user: points,
        modified_by: sessionUser.userId,
      }),
    };

    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/auditmodule/assignartifactuser/" +
      audit_header_id +
      "/" +
      audit_gap_id +
      "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 200) {
          fetchTenantArtifactData();
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      (error) => { }
    );
  };

  const handleSubmit11 = (event) => {
    event.preventDefault();
    setLoading(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",

      body: JSON.stringify({
        artifact_path: S3filelink,
        artifact_uri: null,
        date_of_capture: event.target.eve_capture.value,
        date_of_expiry: event.target.eve_end.value,
      }),
    };
    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/adddocument/" +
      SubArtifactid +
      "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 201) {
          res.json().then((json) => { });
          handleCloseform(false);
          handleCloseform0(false);
          getSubArtifact(tenant_artifact_id);
          setS3filelink(null);
        } else {
          res.json().then((json) => { });
          setLoading(false);
        }
      },
      (error) => { }
    );
  };
  const handleSubmit121 = (event) => {
    event.preventDefault();
    setLoading(true);
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        is_document_added: S3filelink0 ? true : false,
        sub_artifact_name: event.target.Eve_name.value,
        artifact_path: S3filelink0,
        date_of_capture: event.target.date_of_capture.value,
        date_of_expiry: event.target.Expire_date.value,
      }),
    };
    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/addsubartifact/" +
      tenant_artifact_id +
      "/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 200) {
          res.json().then((json) => { });
          handleCloseform();
          handleCloseform0();
          getSubArtifact(tenant_artifact_id);
          setS3filelink0(null);
          setLoading(false);
        } else {
          res.json().then((json) => { });
          setLoading(false);
        }
      },
      (error) => { }
    );
  };

  const handleSubmit12121 = (event) => {
    setLoading(true);

    event.preventDefault();
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        is_mapping: Mapping,
        artifact_name: event.target.artifact_name.value,
        compliance_id: SelectedCompliance_id,
        process_id: SelectedProcess_id,
        control_id: SelectedControl_id,
      }),
    };
    const response = fetch(
      process.env.REACT_APP_BACKEND +
      subdomain +
      "/" +
      (cookie.load("Language") || "en") +
      "/artifact/createcustomartifact/",
      requestOption
    ).then(
      (res) => {
        if (res.status === 201 || 200) {
          res.json().then((json) => { });
          handleCloseform();
          handleCloseform0();
          handleCloseform01();

          fetchTenantArtifactData();
        } else {
          res.json().then((json) => { });
          setLoading(false);
        }
      },
      (error) => { }
    );
  };
  const handleAssignUser = (user, artifact_id) => {
   
  
    setLoading(true);
    
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        assigned_to: user.user,
      }),
    };
  
    fetch(
      `${process.env.REACT_APP_BACKEND}${subdomain}/${cookie.load("Language") || "en"}/artifact/assigntenantartifact/${artifact_id}/`,
      requestOption
    )
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then((json) => {
        fetchTenantArtifactData();
        handleClose3();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error assigning user:", error);
        setLoading(false);
      });
  };
  
  const [open3, setOpen3] = React.useState(false);

  const handleClickOpen = () => {
    setOpen3(true);
  };
  function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props;

    const handleClose = () => {
      onClose(selectedValue);
    };
    const navigate = useNavigate();

    const handleListItemClick = (value) => {
      onClose(value);
    };


    return (
      <Dialog style={{ left: "40%" }} onClose={handleClose} open={open}>
        <Backdrop
          sx={{
            color: "#fff",
          }}
          open={Loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogTitle>Select User</DialogTitle>
        <List>
          <Box sx={{ pt: 0, height: 400, width: 300, overflow: "scroll" }}>
            {userList.map((user) => (
              <ListItem disableGutters key={user}>
                <ListItemButton onClick={() => handleAssignUser(user)}>
                  <ListItemAvatar>
                    <Avatar
                      alt={user.profile_name}
                      // src="/static/images/avatar/1.jpg"
                      sx={{ bgcolor: blue[100], color: blue[600] }}
                    >
                      {/* <PersonIcon /> */}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={user.profile_name} />
                </ListItemButton>
              </ListItem>
            ))}
          </Box>
          <ListItem disableGutters>
            <ListItemButton autoFocus onClick={() => navigate("/user&roles")}>
              <ListItemAvatar>
                <Avatar>
                  <AddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Add User" />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
    );
  }
  const handleClose3 = (value) => {
    setOpen3(false);
  };
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 500,
      },
    },
  };
  return (
    <div>

      
      <SimpleDialog open={open3} onClose={handleClose3} />
      <div style={{ background: "#eeeeee", padding: "10px" }}>
        <div
          style={{
            height: height - 170,
            overflow: "hidden",
            overflowY: "scroll",
          }}
        >
          <Modal
            open={openform01}
            onClose={handleCloseform01}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box>
              <Box sx={style1}>
                <div style={{ color: "#000" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {{ ar: "تقديم الأدلة", sp: "Spanish" }[
                      cookie.load("Language")
                    ] || "Add Directory"}
                  </Typography>
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                    onSubmit={handleSubmit12121}
                    className="upload-steps"
                  >
                    <TextField
                      name="artifact_name"
                      onChange={(e) => {
                        setEveidanceName0(e.target.value);
                      }}
                      id="standard-basic"
                      label={
                        { ar: "اسم الأدلة", sp: "Spanish" }[
                        cookie.load("Language")
                        ] || "Directory Name"
                      }
                      variant="outlined"
                      required
                    />
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={() => {
                              setMapping(!Mapping);
                            }}
                            defaultChecked={Mapping}
                          />
                        }
                        label="Mapping"
                      />
                    </FormGroup>
                    {Mapping ? (
                      <Box
                        gap={"10px"}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        {" "}
                        <Autocomplete
                          disablePortal
                          fullWidth
                          id="combo-box-demo"
                          options={Mappind_masterdata.compliance}
                          getOptionLabel={(option) => option.label} // Use option.label to display in the input
                          onChange={handleOnChangecompliance}
                          renderInput={(params) => (
                            <TextField
                              name="map_compliance"
                              required={Mapping}
                              {...params}
                              label="Map Compliance"
                            />
                          )}
                        />
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={Mappind_masterdata.process}
                          onChange={handleOnChangeprocess}
                          renderInput={(params) => (
                            <TextField
                              name="map_process"
                              required={Mapping}
                              {...params}
                              label="Map Process"
                            />
                          )}
                        />
                        <Autocomplete
                          fullWidth
                          disablePortal
                          id="combo-box-demo"
                          options={Mappind_masterdata.control}
                          onChange={handleOnChangecontrol}
                          renderInput={(params) => (
                            <TextField
                              name="map_control"
                              required={Mapping}
                              {...params}
                              label="Map Control"
                            />
                          )}
                        />
                      </Box>
                    ) : null}

                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "10px",
                      }}
                    >
                      <Button className="cancel-button"
                        onClick={() => {
                          setS3filelink(null);
                          handleCloseform01();
                        }}
                      //   style={{
                      //     background: "#eeeeee",
                      //     color: "#808080",
                      //     marginLeft: "10px",
                      //   }}
                      //  variant="contained"
                      >
                        {{ ar: "يلغي", sp: "Spanish" }[
                          cookie.load("Language")
                        ] || "Cancel"}
                      </Button>
                      <Button className="submit-button"
                        type="submit"
                   
                      >
                        {{ ar: "يُقدِّم", sp: "Spanish" }[
                          cookie.load("Language")
                        ] || "Submit"}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Box>
            </Box>
          </Modal>
          <div style={{}}>
                <div
                  style={{
                    marginBottom: "20px",
                    display: "flex",
                    flexDirection: "column",
                    
                  }}
                >
                  <Box height={"fit-content"}>
                    
                      <CustomButton
  onClick={handleOpenform01}
  label="Add Custom Directory"
  buttonType="large"
  disabled={false} // Set to true if needed
/>
<Box
  sx={{
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    justifyContent: "flex-start",
    alignItems: "center",
    mb: 2, // Added bottom margin
  }}
>        <SearchBox value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} labelEn="Search Artifacts" labelAr="بحث عن القطع الأثرية" />
        <MultiSelect label="Select Compliance" selectedValues={selectedCompliance} options={complianceOptions} onChange={(event, newValue) => setSelectedCompliance(newValue)} />
        <MultiSelect label="Select Process" selectedValues={selectedProcess} options={processOptions} onChange={(event, newValue) => setSelectedProcess(newValue)} width="350px" />
        <MultiSelect label="Select Standard" selectedValues={selectedStandard} options={standardOptions} onChange={(event, newValue) => setSelectedStandard(newValue)} width="450px" />
      </Box>


            
                  </Box>
                  <Drawer
                    sx={{
                      width: "40%",
                      maxWidth: "40%",
                      height: "100%",
                      flexShrink: 0,
                      overflow: "scroll",
                      "& .MuiDrawer-paper": {
                        width: "40%",
                        maxWidth: "40%",
                        overflow: "scroll", // Changed to hidden to manage overflow better within the drawer
                      },
                    }}
                    anchor={"right"}
                    open={modalopen}
                    onClose={handlemodalClose}
                  >
                    {" "}
                    <div
                      style={{
                        color: "#001b2b",
                        marginBottom: 20,
                        borderRadius: 5,
                        padding: 40,
                      }}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        {" "}
                        <h3>Artifacts</h3>
                        <br />

                        <Modal
                          open={openform0}
                          onClose={handleCloseform0}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box>
                            <Box sx={style12}>
                              <div style={{ color: "#000" }}>
                                <Typography
                                  id="modal-modal-title"
                                  variant="h6"
                                  component="h2"
                                >
                                  {{ ar: "تقديم الأدلة", sp: "Spanish" }[
                                    cookie.load("Language")
                                  ] || "Add New Artifact"}
                                </Typography>
                                <Form
                                  onSubmit={handleSubmit121}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 20,
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    name="Eve_name"
                                    id="standard-basic"
                                    label={
                                      { ar: "اسم الأدلة", sp: "Spanish" }[
                                      cookie.load("Language")
                                      ] || "Artifact Name"
                                    }
                                    variant="outlined"
                                    required
                                  />
                                  <TextField
                                    helperText={
                                      Uploading === "error" ? (
                                        <a style={{ color: "red" }}>
                                          Error while uploading, please retry!
                                        </a>
                                      ) : Uploading ? (
                                        <a style={{ color: "orange" }}>
                                          Please wait, uploading...
                                        </a>
                                      ) : S3filelink0 ? (
                                        <a style={{ color: "green" }}>
                                          File Uploaded
                                        </a>
                                      ) : (
                                        ""
                                      )
                                    }
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    onChange={(e) => {
                                      handleUpload0(e.target.files[0]);
                                    }}
                                    type="file"
                                    id="standard-basic"
                                    label={
                                      { ar: "رفع ملف", sp: "Spanish" }[
                                      cookie.load("Language")
                                      ] || "Upload File"
                                    }
                                    required
                                    variant="outlined"
                                  />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "20px",
                                    }}
                                  >
                                    {" "}
                                    <TextField
                                      name="date_of_capture"
                                      fullWidth
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      type="date"
                                      id="standard-basic"
                                      label={
                                        { ar: "تاريخ الالتقاط", sp: "Spanish" }[
                                        cookie.load("Language")
                                        ] || "Date of Capture"

                                      }
                                      variant="outlined"
                                      required
                                    />{" "}
                                    <TextField
                                      required
                                      name="Expire_date"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth
                                      type="date"
                                      id="standard-basic"
                                      label={
                                        {
                                          ar: "تاريخ انتهاء الصلاحية",
                                          sp: "Spanish",
                                        }[cookie.load("Language")] ||
                                        " Expire Date"
                                      }
                                      variant="outlined"
                                    />
                                  </Box>{" "}
                                  <div
                                    style={{
                                      float: "right",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Button className= "cancel-button"
                                      onClick={() => {
                                        setS3filelink(null);
                                        handleCloseform0();
                                      }}
                                      // style={{
                                      //   background: "#eeeeee",
                                      //   color: "#808080",
                                      // }}
                                      // variant="contained"
                                    >
                                      {{ ar: "يلغي", sp: "Spanish" }[
                                        cookie.load("Language")
                                      ] || "Cancel"}
                                    </Button>
                                    <Loadingbutton className="submit-button"
                                      type={!Uploading ? "submit" : "button"}
                                      loading={Loading}
                                      // style={{
                                      //   background: "#00304c",
                                      //   marginLeft: "10px",
                                      //   color: "#ffffff",
                                      // }}
                                      // variant="contained"
                                    >
                                      {{ ar: "يُقدِّم", sp: "Spanish" }[
                                        cookie.load("Language")
                                      ] || "Submit"}
                                    </Loadingbutton>
                                  </div>
                                </Form>
                              </div>
                            </Box>
                          </Box>
                        </Modal>
                        <Box display={"flex"} justifyContent={"flex-end"}>
                          {" "}
                          <Button className= "create-add-buttons"
                            onClick={handleOpenform0}
                            // style={{
                            //   background: "#001b2b",
                            //   marginLeft: "10px",
                            //   background: "#001b2b",
                            //   color: "white",
                            // }}
                            // variant="contained"
                          >
                            {{ ar: "إضافة قطعة أثرية فرعية", sp: "Spanish" }[
                              cookie.load("Language")
                            ] || "Add Artifact"}
                          </Button>
                        </Box>
                      </Box>
                      <br />
                      {SubArtifact.length == 0 ? (
                        <div>
                          <a style={{ color: "black" }}>no data</a>
                        </div>
                      ) : (
                        <Form>
                          <Modal
                            open={openform}
                            onClose={handleCloseform}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box>
                              <Box sx={style12}>
                                <div style={{ color: "#000" }}>
                                  <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    {{ ar: "تقديم الأدلة", sp: "Spanish" }[
                                      cookie.load("Language")
                                    ] || "Add New Artifact"}
                                  </Typography>
                                  <Form
                                    onSubmit={handleSubmit11}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "20px",
                                    }}
                                  >
                                    {" "}
                                    <TextField
                                      helperText={
                                        Uploading === "error" ? (
                                          <a style={{ color: "red" }}>
                                            Error while uploading, please retry!
                                          </a>
                                        ) : Uploading ? (
                                          <a style={{ color: "orange" }}>
                                            Please wait, uploading...
                                          </a>
                                        ) : S3filelink ? (
                                          <a style={{ color: "green" }}>
                                            File Uploaded
                                          </a>
                                        ) : (
                                          ""
                                        )
                                      }
                                      onChange={(e) => {
                                        handleUpload(e.target.files[0]);
                                      }}
                                      type="file"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      id="standard-basic"
                                      label={
                                        { ar: "رفع ملف", sp: "Spanish" }[
                                        cookie.load("Language")
                                        ] || "Upload File"
                                      }
                                      variant="outlined"
                                      accept="application/pdf,application/vnd.ms-excel"
                                      required
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "20px",
                                      }}
                                    >
                                      {" "}
                                      <TextField
                                        fullWidth
                                        name="eve_capture"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        type="date"
                                        placeholder="dd/mm/yyyy" // added this line
                                        id="standard-basic"
                                        label={
                                          {
                                            ar: "تاريخ الالتقاط",
                                            sp: "Spanish",
                                          }[cookie.load("Language")] ||
                                          "Date of Capture"
                                        }
                                        variant="outlined"
                                        required
                                      />{" "}
                                      <TextField
                                        fullWidth
                                        required
                                        name="eve_end"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        type="date"
                                        placeholder="dd/mm/yyyy" // added this line
                                        id="standard-basic"
                                        label={
                                          {
                                            ar: "تاريخ انتهاء الصلاحية",
                                            sp: "Spanish",
                                          }[cookie.load("Language")] ||
                                          " Expire Date"
                                        }
                                        variant="outlined"
                                      />{" "}
                                    </Box>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <Button className="cancel-button"
                                        onClick={() => {
                                          setS3filelink(null);
                                          handleCloseform();
                                        }}
                                        // style={{
                                        //   background: "#eeeeee",
                                        //   color: "#808080",
                                        //   marginLeft: "10px",
                                        // }}
                                        // variant="contained"
                                      >
                                        {{ ar: "يلغي", sp: "Spanish" }[
                                          cookie.load("Language")
                                        ] || "Cancel"}
                                      </Button>
                                      <Loadingbutton className="submit-button"
                                        type={!Uploading ? "submit" : ""}
                                        loading={Loading}
                                        // style={{
                                        //   background: "#00304c",
                                        //   marginLeft: "10px",
                                        //   color: "#ffffff",
                                        // }}
                                        // variant="contained"
                                      >
                                        {{ ar: "يُقدِّم", sp: "Spanish" }[
                                          cookie.load("Language")
                                        ] || "Submit"}
                                      </Loadingbutton>
                                    </div>
                                  </Form>
                                </div>
                              </Box>
                            </Box>
                          </Modal>

                          <div>
                            {SubArtifact.map((c, i) => {
                              return (
                                <div style={{}} key={i}>
                                  <Accordion sx={{ background: "#eeeeee" }}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                    >
                                      <Badge
                                        overlap="rectangular"
                                        color="error"
                                      >
                                        <Button style={{ color: "black" }}>
                                          {" "}
                                          {i + 1} . {c.sub_artifact_name}{" "}
                                        </Button>
                                      </Badge>{" "}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Timeline
                                        sx={{
                                          [`& .${timelineOppositeContentClasses.root}`]:
                                          {
                                            flex: 0.2,
                                          },
                                        }}
                                      >
                                        <Box
                                          display={"flex"}
                                          justifyContent={"space-between"}
                                          flexDirection={"row"}
                                        >
                                          <a style={{ color: "#808080" }}>
                                            Expire Date
                                          </a>{" "}
                                          <a style={{ color: "#808080" }}>
                                            Version Number
                                          </a>{" "}
                                          <Button className="create-add-buttons"
                                            onClick={() => {
                                              handleOpenform();
                                              setSubArtifactid(
                                                c.sub_artifact_id
                                              );
                                            }}
                                            // style={{
                                            //   background: "#001b2b",
                                            //   marginLeft: "10px",
                                            // }}
                                            // variant="contained"
                                          >
                                            {{ ar: "رفع", sp: "Spanish" }[
                                              cookie.load("Language")
                                            ] || "Renew Artifact"}
                                          </Button>
                                        </Box>
                                        <br />
                                        <Box
                                          sx={{
                                            width: "100%",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {c.artifacts.map((item1, i1) => {
                                            return (
                                              <TimelineItem>
                                                <TimelineOppositeContent color="textSecondary">
                                                  {item1.date_of_expiry}
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                  <TimelineDot />
                                                  <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                  <a
                                                    style={{
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: "nowrap",
                                                      cursor: "pointer", // Makes it clear that it's clickable
                                                    }}
                                                    onClick={() => handleOpenArtifactModal(item1.artifact_path)} // Open modal with selected file
                                                  >
                                                    {" "}
                                                    Version-{item1.version}
                                                  </a>

                                                </TimelineContent>
                                              </TimelineItem>
                                            );
                                          })}
                                        </Box>
                                      </Timeline>{" "}
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              );
                            })}
                          </div>
                        </Form>
                      )}
                    </div>
                  </Drawer>
                  
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", maxWidth: "100%" }}>
                  <CustomTable
      columns={columns}
      data={artifactData}
      totalItems={pagination.totalItems}
      pageSize={pagination.pageSize}
      currentPage={pagination.currentPage}
      totalPages={pagination.totalPages}
      onPageChange={handlePageChange}
      isLoading={isLoading}
    />

 


</Box> 
  
                </div>
             
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        onClose={handleCloseArtifactModal}
        aria-labelledby="artifact-modal-title"
        aria-describedby="artifact-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isFullscreen ? "100%" : "80vw", // Control width based on fullscreen mode
            height: isFullscreen ? "100%" : "80vh", // Control height based on fullscreen mode
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "hidden",
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            {/* Close button */}
            <IconButton
              onClick={handleCloseArtifactModal}
              style={{ background: "#eeeeee", color: "#808080" }}
            >
              <CloseIcon />
            </IconButton>

            {/* Fullscreen toggle button */}
            <IconButton
              onClick={handleToggleFullscreen}
              style={{ background: "#eeeeee", color: "#808080" }}
            >
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>

          {/* Render the file inside the modal */}
          <Box sx={{ height: "100%", width: "100%" }}>
            {artifactLink && renderFileViewer(artifactLink)}
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default Myartifacts;