import React, { createContext, useContext, useState } from "react";
import { Snackbar, Alert, IconButton, Box, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // ✅ Success Icon
import ErrorIcon from "@mui/icons-material/Error"; // ✅ Error Icon
import InfoIcon from "@mui/icons-material/Info"; // ✅ Info Icon

// ✅ Create a context for Snackbar
const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbars, setSnackbars] = useState([]);

  // ✅ Function to show Snackbar one by one with smooth delay
  const showSnackbar = (message, severity = "info") => {
    setSnackbars((prev) => {
      const delay = prev.length * 1500; // ✅ 1.5s delay per snackbar
      setTimeout(() => {
        setSnackbars((prevState) => {
          if (prevState.length >= 5) prevState.shift(); // Remove oldest if max 5 reached
          return [...prevState, { id: Date.now(), message, severity }];
        });
      }, delay);
      return prev; // ✅ Keeps previous state intact
    });
  };

  // ✅ Function to remove Snackbar
  const removeSnackbar = (id) => {
    setSnackbars((prev) => prev.filter((snack) => snack.id !== id));
  };

  // ✅ Function to get correct icon based on severity
  const getSnackbarIcon = (severity) => {
    switch (severity) {
      case "success":
        return <CheckCircleIcon sx={{ marginRight: "8px", color: "#fff" }} />;
      case "error":
        return <ErrorIcon sx={{ marginRight: "8px", color: "#fff" }} />;
      default:
        return <InfoIcon sx={{ marginRight: "8px", color: "#fff" }} />;
    }
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Box
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          zIndex: 9999,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end", // ✅ Ensures all snackbars are aligned properly
          gap: "10px",
        }}
      >
        {snackbars.map((snack, index) => (
          <Slide
            key={snack.id}
            direction="left" // ✅ Slide in from right
            in={true}
            timeout={800} // ✅ Slower transition effect
            mountOnEnter
            unmountOnExit
          >
            <Snackbar
              open={true}
              autoHideDuration={4000}
              onClose={() => removeSnackbar(snack.id)}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              sx={{
                marginBottom: `${index * 80}px`, // ✅ Moves previous snackbars up
                transition: "transform 0.8s ease-in-out", // ✅ Smooth movement
              }}
            >
              <Alert
                severity={snack.severity}
                sx={{
                  width: "350px", // ✅ Fixed width for consistency
                  minWidth: "350px",
                  maxWidth: "350px",
                  backgroundColor:
  snack.severity === "success"
    ? "rgba(0, 128, 0, 0.8)" // Green with 80% opacity
    : snack.severity === "error"
    ? "rgba(255, 0, 0, 0.8)" // Red with 80% opacity
    : "rgba(1, 195, 193, 0.8)", // Custom teal color with 80% opacity
                  color: "#fff",
                  fontFamily: "'Glacial Indifference', sans-serif",
                  transition: "all 0.5s ease-in-out", // ✅ Smooth effect
                  display: "flex",
                  alignItems: "center",
                }}
                icon={false} // ✅ Remove default MUI Alert icon
                action={
                  <IconButton
                    size="small"
                    onClick={() => removeSnackbar(snack.id)}
                    sx={{ color: "#fff" }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              >
                {getSnackbarIcon(snack.severity)} {/* ✅ Show the correct icon */}
                {snack.message}
              </Alert>
            </Snackbar>
          </Slide>
        ))}
      </Box>
    </SnackbarContext.Provider>
  );
};

// ✅ Hook to use Snackbar
export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
