import React, { useState, useEffect } from "react";
import { ReactSession } from "react-client-session";
import { styled } from "@mui/material/styles";
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableSortLabel,
  TextField,
  Typography,
  Drawer,
  Chip,
  InputLabel,
  FormControl,
  Autocomplete,
  CircularProgress,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Skeleton,
  Snackbar,
  Alert,
} from "@mui/material";

import cookie from "react-cookies";
import { useNavigate } from "react-router-dom";
import { Checkbox, ListItemText } from "@mui/material";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import './RiskAssessment.css';

const StyledContent = styled("div")(({ theme }) => ({
  width: "100%",
  margin: "0 auto",
  minHeight: "100vh",
  padding: theme.spacing(1, 2),
  marginBottom: theme.spacing(1),
  color: "black",
}));

// const SearchBox = styled("div")(({ theme }) => ({
//   marginBottom: theme.spacing(3),
//   display: "flex",
//   justifyContent: "left",
//   width: "100%",
// }));

// const TopRightButton = styled("div")(({ theme }) => ({
//   display: "flex",
//   justifyContent: "space-between",
//   marginBottom: theme.spacing(2),
// }));

const CategoryChips = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const FullWidthAccordion = styled(Accordion)(({ theme }) => ({
  width: "100%",
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const RiskAssessments = () => {
  ReactSession.set("PageTitle", "RiskAssessments");

  const navigate = useNavigate();
  const [complianceList, setComplianceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredComplianceList, setFilteredComplianceList] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("risk_assessment_name");
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [createdOn, setCreatedOn] = useState([]);
  const [categories, setCategories] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [submit_loading, setSubmit_loading] = useState(false);
  const [assessmentType, setassessmentType] = useState([])
  const [newRiskAssessment, setNewRiskAssessment] = useState({
    category_ids: [],
    risk_assessment_name: "",
    risk_assessment_type: "",
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (e) => {
    const value = e.target.value;
    setFilterName(value);
    setCurrentPage(1);
    fetchFilteredData(value, selectedTypes, createdOn);
  };

  const handleAssessmentTypeChange = (e) => {
    const value = e.target.value;
    setSelectedTypes(value);
    setCurrentPage(1);
    fetchFilteredData("", value);
  };

  const handleCreatedOnChange = (e) => {
    const value = e.target.value;
    setCreatedOn(value);
    setCurrentPage(1);
    fetchFilteredData(filterName, selectedTypes, value); // Fetch filtered data
  };


  //  useEffect(() => {
  //       const fetchPageData = async () => {
  //         await fetchFilteredData(filterName,selectedTypes,createdOn);
  //        };
  //       fetchPageData();
  //     }, [filterName,selectedTypes,createdOn]); 

  const sortedList = applySortFilter(
    complianceList,
    getComparator(order, orderBy),
    searchTerm
  );

  const sortedAndPaginatedList = complianceList
    .filter((compliance) =>
      compliance.risk_assessment_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort(getComparator(order, orderBy))
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sortedList.length) : 0;

  const subdomain = window.location.host.split(".")[0];

  useEffect(() => {
    fetchFilteredData(filterName, selectedTypes, createdOn, rowsPerPage, currentPage);
  }, [filterName, selectedTypes, createdOn, currentPage, rowsPerPage]);

  //  const fetchPageData = async (page) => {
  //     setLoading(true);
  //     setError(null); 

  //     const url = `${process.env.REACT_APP_BACKEND}${subdomain}/${cookie.load("Language") || "en"}/riskassessment/riskassessment/?page=${page}`;

  //     try {
  //       const res = await fetch(url, {
  //         method: 'GET',
  //         credentials: 'include',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       });

  //       if (res.ok) {
  //         const data = await res.json();
  //         if (data && data.results && data.total_pages) {
  //           setComplianceList(data.results); 
  //           setTotalPages(data.total_pages); 
  //           setTotalItems(data.total_items); 
  //           setPageSize(data.page_size); 
  //         } else {
  //           setError("No data found for this page.");
  //         }
  //       } else {
  //         throw new Error("Unexpected response status: " + res.status);
  //       }
  //     } catch (err) {
  //       setError("Failed to fetch data. Please try again.");
  //       console.error("Error fetching data:", err);
  //     } finally {
  //       setLoading(false); 
  //     }
  //   };

  //   useEffect(() => {
  //     fetchPageData(currentPage); 
  //   }, [currentPage]); 

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= totalPages && newPage !== currentPage) {
      setCurrentPage(newPage);
      try {
        const data = await fetchFilteredData(
          filterName,
          assessmentType,
          pageSize,
          newPage
        );

        if (data && data.totalPages !== undefined) {
          setTotalPages(data.totalPages);
        } else {
          console.error("totalPages is undefined in the response.");
        }
        if (data && data.items) {
          setFilteredData(data.items);
        } else {
          console.error("No data found.");
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };


  function generatePageNumbers() {
    const pageNumbers = [];

    if (totalPages <= 5) {

      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, 5);
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(1);
        pageNumbers.push('...');
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push('...');
        pageNumbers.push(currentPage - 1);
        pageNumbers.push(currentPage);
        pageNumbers.push(currentPage + 1);
        pageNumbers.push('...');
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  }
  const fetchFilteredData = async (
    filterName = "",
    selectedTypes = [],
    createdOn = "",
    pageSize = 10,
    currentPage = 1
  ) => {
    setLoading(true);
    setError(null);

    try {
      const baseURL = `${process.env.REACT_APP_BACKEND}${subdomain}/${cookie.load("Language") || "en"
        }`;
      const url = new URL(`${baseURL}/riskassessment/riskassessment/`);

      url.searchParams.append("search", filterName || "");
      if (selectedTypes.length > 0) {
        url.searchParams.append("assessment_type", selectedTypes.join(","));
      }
      if (createdOn) {
        url.searchParams.append("created_date", createdOn);
      }

      // Pagination filters
      url.searchParams.append("page_size", pageSize);
      url.searchParams.append("page", currentPage);

      // console.log("Fetching data with URL:", url.toString());

      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        const data = await response.json();

        if (data.results) {
          setComplianceList(data.results);
          setTotalPages(data.total_pages);
          setTotalItems(data.total_items);
          setCategories(data.category_list);
        } else {
          throw new Error("Unexpected response structure");
        }
      } else {
        throw new Error(`Failed to fetch data, status: ${response.status}`);
      }
    } catch (error) {
      setError("Failed to fetch data. Please try again.");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Helper function to validate date format (YYYY-MM-DD)
  const isValidDate = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    return regex.test(dateString);
  };




  //   const fetchComplianceList = () => {
  //     setLoading(true);
  //     setError(null); 
  //     fetch(
  //       `${process.env.REACT_APP_BACKEND}${subdomain}/${
  //         cookie.load("Language") || "en"
  //       }/riskassessment/riskassessment/`,
  //       {
  //         credentials: "include",
  //       }
  //     )
  //       .then((res) => {
  //         if (res.ok) {
  //           return res.json();
  //         }
  //         throw new Error("Failed to fetch data, status: " + res.status); // Handle errors if the response is not OK
  //       })
  //       .then((data) => {
  //         setLoading(false);

  //         if (data && data.results && data.total_pages && data.total_items) {
  //           // console.log("Compliance List:", data);


  //           setComplianceList(data.results || []); 
  //           setTotalPages(data.total_pages || 1);
  //           setTotalItems(data.total_items || 0); 
  //           setPageSize(data.page_size || 10); 
  //           setCategories(data.category_list);
  //           // console.log("categories",categories);
  //         } else {
  //           setError("No data found.");
  //           setComplianceList([]);

  //         }
  //       })
  //       .catch((err) => {
  //       setLoading(false);
  //       setError("Failed to fetch data.");
  //       console.error("Error fetching compliance list:", err);
  //     });
  // };

  // const fetchCategories = () => {
  //   fetch(
  //     `${process.env.REACT_APP_BACKEND}${subdomain}/${
  //       cookie.load("Language") || "en"
  //     }/riskassessment/categories/`,
  //     {
  //       credentials: "include",
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((data) => {
  //       if (data) {
  //         // console.log("Categories:", data);
  //         setCategories(data);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("Error fetching categories:", err);
  //     });
  // };

  // useEffect(() => {
  //   fetchComplianceList();

  // }, []);

  const handleViewClick = (riskAssessment) => {
    navigate(`/RiskRequirements/${riskAssessment.risk_assessment_id}`, {
      state: riskAssessment,
    });
  };

  const handleCreateNewClick = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewRiskAssessment({
      ...newRiskAssessment,
      [name]: value,
    });
  };
  const language = cookie.load('Language') || 'en';
  const riskOptions = ["SELF", "AUDITOR"];

  const handleCategoryClick = (categoryId) => {
    // console.log("Category Id :", categoryId);
    setNewRiskAssessment((prevState) => {
      const category_ids = prevState.category_ids.includes(categoryId)
        ? prevState.category_ids.filter((id) => id !== categoryId)
        : [...prevState.category_ids, categoryId];
      return {
        ...prevState,
        category_ids,
      };
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSubmit_loading(true);
    if (newRiskAssessment.category_ids.length === 0) {
      setSnackbarMessage("Please select a category");
      setSnackbarType("warning");
      setOpenSnackbar(true);
      return;
    }

    const csrfToken = cookie.load("csrftoken");

    fetch(
      `${process.env.REACT_APP_BACKEND}${subdomain}/${cookie.load("Language") || "en"
      }/riskassessment/riskassessment/`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": csrfToken,
        },
        body: JSON.stringify(newRiskAssessment),
      }
    )
      .then((res) => {
        if (res.status === 403) {
          throw new Error("Forbidden");
        }
        return res.json();
      })
      .then((data) => {
        if (data) {
          // console.log("New Risk Assessment Created:", data);
          // fetchComplianceList(); 
          fetchFilteredData();
          setDrawerOpen(false); // Close the drawer
          setNewRiskAssessment({
            category_ids: [],
            risk_assessment_name: "",
            risk_assessment_type: "",
          });
        }
      })
      .catch((err) => {
        console.error("Error creating new risk assessment:", err);
      }).finally(() => {
        setSubmit_loading(false);
      });
  };

  return (
    <Box>
      {/* {loading ? (
           <Box style={{ padding: 30, width: "100%", marginTop: "2" }}>
           <Skeleton variant="rectangular" height={50} width="20%"  sx={{ marginBottom: 5 }} />
           <Skeleton style={{ height: "70px", width: "100%" }} animation="wave" />
           <Skeleton style={{ height: "100px" }} animation={false}  />
           <Skeleton style={{ height: "100px" }} />
           <Skeleton style={{ height: "100px" }} animation="wave" />
           <Skeleton style={{ height: "100px" }} animation={false} />
           <Skeleton style={{ height: "100px" }} />
           <Skeleton style={{ height: "100px" }} animation="wave" />
           </Box>
      ) : ( */}
      <>
        <div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <TextField
              label={cookie.load("Language") === "ar" ? "وثيقة سياسة البحث" : "Search Assessment"}
              value={filterName} // The value is controlled by the filterName state
              onChange={handleFilterByName} // Update filterName on change
              className="custom-textfield"
            />
            {/* // <Grid item xs={12} key={compliance.risk_assessment_id}>
                //   <FullWidthAccordion>
                //     <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                //       <Typography>{compliance.risk_assessment_name}</Typography>
                //     </AccordionSummary>
                //     <AccordionDetails>
                //       <Typography
                //         variant="body2"
                //         color="text.secondary"
                //         style={{ color: "black" }}
                //       >
                //         Type: {compliance.risk_assessment_type}
                //       </Typography>
                //       <Typography
                //         variant="body2"
                //         color="text.secondary"
                //         style={{ color: "black" }}
                //       >
                //         Created On:{" "}
                //         {new Date(compliance.created_on).toLocaleDateString()}
                //       </Typography>
                //       <Box
                //         sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}
                //       >
                //         <Button
                //           variant="contained"
                //           color="primary"
                //           onClick={() => handleViewClick(compliance)}
                //         >
                //           View
                //         </Button>
                //       </Box>
                //     </AccordionDetails>
                //   </FullWidthAccordion>
                // </Grid> */}
            <FormControl margin="normal" className="custom-form-control">

              <InputLabel className="riskk-custom-label">
                {cookie.load("Language") === "ar" ? "وثيقة سياسة البحث" : "Assessment Type"}
              </InputLabel>
              <Select
                multiple
                value={selectedTypes}
                onChange={handleAssessmentTypeChange}
                className="custom-select"
                label={cookie.load("Language") === "ar" ? "وثيقة سياسة البحث" : "Vendor type"}
                renderValue={(selected) => selected.join(", ")}
              >
                {riskOptions.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    <Checkbox checked={selectedTypes.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
            <TextField
              type="date"
              label="Created On"
              value={createdOn}
              onChange={handleCreatedOnChange}
              className="date-field"
              InputLabelProps={{
                shrink: true,
              }}

            />
            <Box
              sx={{
                marginTop: 3,
                marginRight: 2,
              }}
            >
              <Button className="create-risk-add-buttons"

                // variant="contained"
                // sx={{ color: "#fff", background: "#001b2b" }}
                // endIcon={<AddIcon />}
                onClick={handleCreateNewClick}
                //color="primary"
                aria-label="add"
              >
                {" "}
                {{ ar: "إنشاء جديد", sp: "Spanish" }[
                  cookie.load("Language")
                ] || "Create New"}
              </Button>
            </Box>
          </div>

        </div>

        <StyledContent>
          {loading ? (
            <Box style={{ padding: 30, width: "100%", marginTop: "2" }}>
              <Skeleton variant="rectangular" height={50} width="20%" sx={{ marginBottom: 5 }} />
              <Skeleton style={{ height: "70px", width: "100%" }} animation="wave" />
              <Skeleton style={{ height: "100px" }} animation={false} />
              <Skeleton style={{ height: "100px" }} />
              <Skeleton style={{ height: "100px" }} animation="wave" />
              <Skeleton style={{ height: "100px" }} animation={false} />
              <Skeleton style={{ height: "100px" }} />
              <Skeleton style={{ height: "100px" }} animation="wave" />
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead className="risk-table-head"
                // style={{
                //    backgroundColor: "#dfdfdf",
                // }}
                >
                  <TableRow>
                    <TableCell sortDirection={orderBy === "risk_assessment_name" ? order : false}
                      // sx={{
                      //     color: "#001b2b",               
                      //     fontFamily: "'Poppins', sans-serif", 
                      //     fontWeight: 500,
                      //     fontSize: "14px", 
                      // }}
                      className="risk-table-cell"
                    >
                      <TableSortLabel
                        active={orderBy === "risk_assessment_name"}
                        direction={orderBy === "risk_assessment_name" ? order : "asc"}
                        onClick={(event) => handleRequestSort(event, "risk_assessment_name")}
                      >
                        {
                          { ar: "اسم التقييم", sp: "spanish" }[
                          cookie.load("Language")
                          ] || "Assessment Name"
                        }
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection={orderBy === "risk_assessment_type" ? order : false}
                      // sx={{
                      //   color: "#001b2b",               
                      //   fontFamily: "'Poppins', sans-serif", 
                      //   fontWeight: 500,
                      //   fontSize: "14px", 
                      // }}  
                      className="risk-table-cell"
                    >
                      <TableSortLabel
                        active={orderBy === "risk_assessment_type"}
                        direction={orderBy === "risk_assessment_type" ? order : "asc"}
                        onClick={(event) => handleRequestSort(event, "risk_assessment_type")}
                      >
                        {
                          { ar: "نوع التقييم", sp: "spanish" }[
                          cookie.load("Language")
                          ] || "Assessment Type"
                        }
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection={orderBy === "created_on" ? order : false}
                      // sx={{
                      //   color: "#001b2b",               
                      //   fontFamily: "'Poppins', sans-serif", 
                      //   fontWeight: 500,
                      //   fontSize: "14px", 
                      // }}  
                      className="risk-table-cell"
                    >
                      <TableSortLabel
                        active={orderBy === "created_on"}
                        direction={orderBy === "created_on" ? order : "asc"}
                        onClick={(event) => handleRequestSort(event, "created_on")}
                      >
                        {
                          { ar: "تاريخ الإنشاء", sp: "spanish" }[
                          cookie.load("Language")
                          ] || "Created On"
                        }
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection={orderBy === "created_on" ? order : false}
                      // sx={{
                      //   color: "#001b2b",               
                      //   fontFamily: "'Poppins', sans-serif", 
                      //   fontWeight: 500,
                      //   fontSize: "14px", 
                      // }}  
                      className="risk-table-cell"
                    >
                      <TableSortLabel
                        active={orderBy === "expiry_date"}
                        direction={orderBy === "expiry_date" ? order : "asc"}
                        onClick={(event) => handleRequestSort(event, "expiry_date")}
                      >
                        {
                          { ar: "تاريخ الإنشاء", sp: "spanish" }[
                          cookie.load("Language")
                          ] || "Expired On"
                        }
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      // sx={{
                      //   color: "#001b2b",               
                      //   fontFamily: "'Poppins', sans-serif", 
                      //   fontWeight: 500,
                      //   fontSize: "14px", 
                      // }}  
                      className="risk-table-cell"
                    >
                      {
                        { ar: "عمل", sp: "spanish" }[
                        cookie.load("Language")
                        ] || "Action"
                      }
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {sortedAndPaginatedList.length > 0 ? (

                    sortedAndPaginatedList.map((compliance) => (
                      <TableRow key={compliance.risk_assessment_id} hover>
                        <TableCell align="left" className="AssessmentName"
                        // sx={{
                        //   color: "#808080",               
                        //   fontFamily: "'Poppins', sans-serif", 
                        //   fontWeight: 400,    
                        //   fontSize: "14px", 
                        //   maxWidth: "27rem", 
                        //   wordBreak: "break-word", 
                        //   whiteSpace: "normal",   
                        // }}
                        >
                          {compliance.risk_assessment_name}
                        </TableCell>
                        <TableCell align="left"
                          // sx={{
                          //   color: "#808080",               
                          //   fontFamily: "'Poppins', sans-serif", 
                          //   fontWeight: 400,  
                          //   fontSize: "14px",               
                          // }}
                          className="table-row"
                        >
                          {compliance.risk_assessment_type}
                        </TableCell>
                        <TableCell align="left"
                          // sx={{
                          //   color: "#808080",               
                          //   fontFamily: "'Poppins', sans-serif", 
                          //   fontWeight: 400,  
                          //   fontSize: "14px",                   
                          // }}
                          className="table-row"
                        >
{new Date(compliance.created_on).toLocaleDateString()}

</TableCell>
                        <TableCell align="left"
                          // sx={{
                          //   color: "#808080",               
                          //   fontFamily: "'Poppins', sans-serif", 
                          //   fontWeight: 400,  
                          //   fontSize: "14px",                   
                          // }}
                          className="table-row"
                        >
                          {compliance.expiry_date ? new Date(compliance.expiry_date).toLocaleDateString() : null}

                        </TableCell>
                        <TableCell align="left"
                          // sx={{
                          //   color: "#808080",               
                          //   fontFamily: "'Poppins', sans-serif", 
                          //   fontWeight: 400, 
                          //   fontSize: "14px",                    
                          // }}
                          className="table-row"
                        >
                          <Button
                            onClick={() => handleViewClick(compliance)}
                            size="small"
                            // sx={{
                            //   backgroundColor: "transparent",
                            //   fontFamily: "'Poppins', sans-serif", 
                            //   color: "primary", 
                            //   border: "1px solid",
                            //   fontWeight: 400,
                            //   borderColor: "primary",
                            //   fontSize: "15px",
                            //   padding: "6px 16px",
                            // }}
                            className="custom-button"
                          >
                            {" "}
                            {{ ar: "عرض", sp: "Spanish" }[
                              cookie.load("Language")
                            ] || "VIEW"}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography>No data available</Typography>
                      </TableCell>
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </TableContainer>
          )}
        </StyledContent>
        <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
          <Box sx={{ width: 350, p: 3 }}>
            <Typography variant="h6" component="div" gutterBottom>
              {
                { ar: "إنشاء تقييم مخاطر جديد", sp: "spanish" }[
                cookie.load("Language")
                ] || "Create New Risk Assessment"
              }
            </Typography>
            
            <form onSubmit={handleFormSubmit}>
          <Typography sx={{marginBottom:"12px",marginTop:"20px"}}>
          {
            { ar: "إنشاء تقييم مخاطر جديد", sp: "spanish" }[cookie.load("Language")] || "Select categories *"
          }
          </Typography>

        <CategoryChips>
         {categories.map((category) => (
         <Chip
          key={category.category_id}
          label={category.category_name}
          clickable
          color={
                 newRiskAssessment.category_ids.includes(category.category_id)
                 ? "primary"
                 : "default"
           }
           onClick={() => handleCategoryClick(category.category_id)}
            />
             ))}
             </CategoryChips>
              <TextField
                required
                fullWidth
                margin="normal"
                label={
                  { ar: "اسم تقييم المخاطر", sp: "spanish" }[
                  cookie.load("Language")
                  ] || "Name of Risk Assessment"
                }
                variant="outlined"
                name="risk_assessment_name"
                value={newRiskAssessment.risk_assessment_name}
                onChange={handleInputChange}
              />
              <FormControl fullWidth required margin="normal">
                <InputLabel id="risk-type-label">
                  {
                    { ar: "النوع", sp: "spanish" }[
                    cookie.load("Language")
                    ] || "Type"
                  }
                </InputLabel>
                <Select
                  labelId="risk-type-label"
                  name="risk_assessment_type"
                  value={newRiskAssessment.risk_assessment_type}
                  onChange={handleInputChange}
                  label={
                    { ar: "النوع", sp: "spanish" }[
                    cookie.load("Language")
                    ] || "Type"
                  }
                >
                  <MenuItem value="SELF">SELF</MenuItem>
                  <MenuItem value="AUDITOR">AUDITOR</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                <LoadingButton
                  loading={submit_loading}
                  className="create-add-buttons"
                  type="submit"
                //variant="contained" sx={{backgroundColor: "#001b2b", color: "white"}}
                >
                  {
                    { ar: "إرسال", sp: "spanish" }[
                    cookie.load("Language")
                    ] || "Submit"
                  }
                </LoadingButton>
              </Box>
              
            </form>
          </Box>
        </Drawer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={sortedList.length}  
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />      */}
        <div
          className="pagination-container"
        >
          <div
            className="pagination-total"
          >
            Total Count: {totalItems}
          </div>
          <div
            className="pagination-controls"
          >

            <button
              className="pagination-icon-button"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <ArrowBackIosOutlinedIcon style={{ fontSize: "14px" }} />
            </button>

            {generatePageNumbers().map((page) => (
              <button
                key={page}
                className={`pagination-button ${page === currentPage ? 'pagination-button-current' : ''}`}
                onClick={() => typeof page === "number" && handlePageChange(page)}
                disabled={page === currentPage || page === '...'}
              >
                {page === '...' ? '...' : page}
              </button>
            ))}

            <button
              className="pagination-icon-button"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <ArrowForwardIosOutlinedIcon style={{ fontSize: "14px" }} />
            </button>
          </div>
        </div>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>

    </Box>
  );
};

export default RiskAssessments;