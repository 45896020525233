import React, { useState } from "react";
import { Box, Avatar, Typography, Popover, TextField } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const AssignUserComponent = ({ selectedUser, profileImage, availableUsers, onUserSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTerm(""); // Clear search when closing
  };

  const filteredUsers = availableUsers.filter((user) =>
    (user.profile_name || "").toLowerCase().includes(searchTerm.toLowerCase())
  );

  const stringToColor = (string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (let i = 0; i < 3; i++) {
      color += ("00" + ((hash >> (i * 8)) & 0xff).toString(16)).substr(-2);
    }
    return color;
  };

  const getInitials = (name) => {
    return name ? name.split(" ").map((n) => n[0]).join("").toUpperCase() : "?";
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Avatar
        sx={{
          bgcolor:
            selectedUser === "Unassigned"
              ? "#9e9e9e"
              : profileImage
              ? "transparent"
              : stringToColor(selectedUser || "-"),
          fontSize: "14px",
          width: 40,
          height: 40,
          cursor: "pointer",
          marginRight: "8px",
          transition: "0.3s",
          "&:hover": {
            transform: "scale(1.1)",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
          },
        }}
        onClick={handleAvatarClick}
        src={profileImage}
      >
        {!profileImage &&
          (selectedUser === "Unassigned" ? (
            <PersonIcon sx={{ fontSize: "20px", color: "#fff" }} />
          ) : (
            getInitials(selectedUser)
          ))}
      </Avatar>

      <Typography
        sx={{
          fontSize: "13px",
          fontFamily: "'Glacial Indifference'",
          color: "#3c3c3c",
        }}
      >
        {selectedUser || "Unassigned"}
      </Typography>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          zIndex: 1300,
          "& .MuiPopover-paper": {
            maxHeight: "270px",
            width: "220px",
            overflowY: "auto",
            padding: "8px",
            backgroundColor: "#fff",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "8px",
            transition: "0.3s ease-in-out",
          },
        }}
      >
        <Box sx={{ padding: "8px", maxWidth: "300px" }}>
          <TextField
            variant="outlined"
            placeholder="Search user"
            fullWidth
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              marginBottom: "10px",
              fontFamily: "'Glacial Indifference'",
              "& .MuiOutlinedInput-root": {
                fontSize: "14px",
              },
            }}
          />

          {filteredUsers.length > 0 ? (
            filteredUsers.map((user) => (
              <Box
                key={user.user}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "8px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#e6f7ff",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  },
                }}
                onClick={() => {
                  onUserSelect(user);
                  handleClose();
                }}
              >
                <Avatar
                  sx={{
                    bgcolor: user.profile_image ? "transparent" : stringToColor(user.profile_name || "-"),
                    fontSize: "14px",
                    width: 36,
                    height: 36,
                    marginRight: "12px",
                  }}
                  src={user.profile_image}
                >
                  {!user.profile_image && getInitials(user.profile_name)}
                </Avatar>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "'Glacial Indifference'",
                    color: "#3c3c3c",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    maxWidth: "200px",
                  }}
                >
                  {user.profile_name || "Unnamed User"}
                </Typography>
              </Box>
            ))
          ) : (
            <Typography sx={{ textAlign: "center", color: "#999", fontSize: "14px" }}>
              No users found
            </Typography>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default AssignUserComponent;
