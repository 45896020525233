import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Typography,
  TextField,
  Card,
  CardContent,
  Divider,
  Drawer,
  Modal,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Autocomplete,
  Paper,
  TablePagination,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import cookie from "react-cookies";
import S3 from "react-aws-s3";
import useWindowDimensions from "../../useWindowDimensions";
import fetchData from "../../api/apiService.js";
import "./RequirementsListing.css";
import { useSnackbar } from "../../ReusableComponents/SnackbarManager.js"; // Import CustomButton
import SearchBox from "../../ReusableComponents/SearchBox.js"; // Import CustomButton
import MultiSelect from "../../ReusableComponents/MultiSelect.js"; // Import CustomButton
import CustomTable from "../../ReusableComponents/CustomTable.js"; // Import CustomButton
import CustomButton from "../../ReusableComponents/CustomButton.js"; // Import CustomButton
import RequirementDrawer from "./RequirementDrawer.js"


import { debounce } from "lodash";




function RequirementsListing() {
  const subdomain = window.location.host.split(".")[0];
  const { complianceId } = useParams();
  const [requirements, setRequirements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequirementId, setSelectedRequirementId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null);
  const [S3filelink, setS3filelink] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [uploadError, setUploadError] = useState("");
  const [subChecks, setSubChecks] = useState([]);
  const [uploadFormOpen, setUploadFormOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    sub_check_name: "",
    artifact_path: S3filelink,
    comment: "",
    date_of_expiry: "",
  });
  const [uploading, setUploading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [artifactLink, setArtifactLink] = useState("");
  const [selectedSubCheck, setSelectedSubCheck] = useState(null);
  const [artifactFormVisible, setArtifactFormVisible] = useState(null); // New state to track which sub-check's form is open
  const fileInput = useRef(null);
  const { height, width } = useWindowDimensions();
  const [companyName, setCompanyName] = useState(""); // State to store company name
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedStandards, setSelectedStandards] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [checks, setChecks] = useState([]);
  const [artifactList, setArtifactList] = useState([]);
  const [selectedSubCheckId, setSelectedSubCheckId] = useState(null);
  const [expandedSubCheck, setExpandedSubCheck] = useState(null);
  const [subcheckLoading, setSubcheckLoading] = useState(false);
  const [artifactLoading, setArtifactLoading] = useState(false);
  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStandard, setSelectedStandard] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [RequirementData, setRequirementData] = useState([]);
  const showSnackbar = useSnackbar(); 
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [standardOptions, setStandardOptions] = useState([]);
  const [documentId, setDocumentId]=useState([]);

  const [open, setOpen] = useState(false);
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages && newPage !== pagination.currentPage) {
      setPagination((prev) => ({ ...prev, currentPage: newPage }));
      fetchRequirementsData(newPage);
    }
  };

  const handlePdfOpen = (id) => {
    setDocumentId(id);
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
    setDocumentId(null);
    setUploadError(""); 
  };
  
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  }




  // const fetchRequirements = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BACKEND}${
  //         window.location.host.split(".")[0]
  //       }/${
  //         cookie.load("Language") || "en"
  //       }/artifact/splitrequirement/${complianceId}?module=compliance`,
  // {
  //   credentials: "include", }
  //     );
  //     const data = await response.json();
  //     setRequirements(data.requirement_list || []);
  //     setCompanyName(data.details.company_name); // Set the company name from API response

  //     // console.log("Requirements data:", data);
  //   } catch (error) {
  //     console.error("Failed to fetch requirements", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
   // Fetch Artifact Data & User List
   const fetchRequirementsData = useCallback(
    async (page = pagination.currentPage) => {
      setIsLoading(true);
      const queryParams = new URLSearchParams({
        page,
        page_size: pagination.pageSize,
      });

      if (selectedCategory.length)
        queryParams.append("category", selectedCategory.map((i) => i.id).join(","));
      if (selectedStandard.length)
        queryParams.append("standard", selectedStandard.map((i) => i.id).join(","));
      if (searchTerm) queryParams.append("search", searchTerm);

      try {
        const data = await fetchData(`artifact/splitrequirement/${complianceId}?module=compliance&${queryParams.toString()}`);
        if (data) {
          setCompanyName(data.company_name);
          setRequirementData(data.result || []);
          setPagination({
            totalItems: data.total_items,
            totalPages: data.total_pages,
            currentPage: data.current_page,
            pageSize: 10,
          });
        }
      } catch (error) {
        showSnackbar("Failed to fetch artifact data. Please try again.", "error");
      } finally {
        setIsLoading(false);
      }
    },
    [pagination.pageSize, selectedCategory, selectedStandard, searchTerm]
  );

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const data = await fetchData(`artifact/splitrequirement/${complianceId}?module=compliance`);
        
        if (data) {
          
          setCategoryOptions(
            data.category_list.map((item) => ({
              id: item.category_id,
              name: item.category_name,
            }))
          );
  
          setStandardOptions(
            data.standard_list.map((item) => ({
              id: item.standard_id,
              name: ` ${item.standard_name} [${item.standard_code} ]`,
            }))
          );
          
        }
      } catch (error) {
        console.error("Error fetching metadata:", error);
        showSnackbar("An unexpected error occurred while fetching metadata.", "error");
      }
    };
  
    fetchMetadata(); // Call the async function inside useEffect
  }, []); // Empty dependency array ensures it runs only once

  // Debounce Search and Filter Changes
  useEffect(() => {
    const debouncedFetch = debounce(() => fetchRequirementsData(), 500);
    debouncedFetch();
    return () => debouncedFetch.cancel();
  }, [fetchRequirementsData]);

  const fetchChecks = async (requirement_id) => {
    // console.log(`Fetching checks for Requirement ID: ${requirement_id}`); // ✅ Debugging log
    setChecks([]);
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${
          cookie.load("Language") || "en"
        }/artifact/splitchecklist/${complianceId}/${requirement_id}?module=compliance`,
        { credentials: "include" }
      );
      const data = await response.json();
      // console.log("Fetched Checks:", data); // ✅ Debugging log
      setChecks(data);
    } catch (error) {
      console.error("Failed to fetch checks", error);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchSubChecks = async (check_id) => {
    setSubcheckLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/splitsubchecklist/${check_id}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setSubChecks(data || []);
      // console.log("Sub-checks data for check_id", check_id, ":", data);
    } catch (error) {
      console.error("Failed to fetch subchecks", error);
    } finally {
      setSubcheckLoading(false);
    }
  };

  const fetchArtifactList = async (sub_check_id) => {
    setArtifactList([]);
    setArtifactLoading(true);
    try {
      setSelectedSubCheckId(sub_check_id);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/splitartifactlist/${sub_check_id}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setArtifactList(data);
    } catch (error) {
      console.error("Failed to fetch Artifact", error);
    } finally {
      setArtifactLoading(false); 
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleOpenDialog = (id) => {
    setSelectedRequirementId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      fetchChecks(panel); 
    }
  };

  const handleSubCheckChange = (subCheckId) => (event, isExpanded) => {
    setExpandedSubCheck(isExpanded ? subCheckId : null); 
    if (isExpanded) {
      fetchArtifactList(subCheckId); 
    }
  };

  useEffect(() => {
    if (!drawerOpen) {
      setExpandedSubCheck(null); 
    }
  }, [drawerOpen]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDrawerOpen = (check) => {
    setSelectedCheck(check);
    fetchSubChecks(check.check_id);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSubChecks([]);
    setArtifactFormVisible(null); // Reset the artifact form visibility state
  };

  const handleUploadButtonClick = () => {
    setUploadFormOpen(true);
    setUploadError("");
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
      
    });
  };
 
  const handleFileUpload = (file, artifactId) => {
      setUploading(true);
      setUploadError("");
      if (!file) {
        setSnackbarMessage("No file selected. Please select a file to upload.");
        setSnackbarType("error"); 
        setOpenSnackbar(true); 
        setUploading(false);
        
        return;
      }
  
      const filePath = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
      const contentType = file.type || "application/octet-stream";
    
      const apiUrl =
        process.env.REACT_APP_BACKEND +
        subdomain +
        "/" +
        (cookie.load("Language") || "en") +
        "/artifact/generate-presigned-urls/";
  
        fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookie.load("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify({
            artifact_id: artifactId,
            files: [
              {
                file_path: filePath,
                content_type: contentType,
              },
            ],
          }),
        })
        .then((response) => {
          if (!response.ok) 
            throw new Error("Error while uploading, please retry!");
          return response.json();
        })
        .then((data) => {
          const presignedUrl = data.presigned_urls[0]?.presigned_url;
          if (!presignedUrl) 
            throw new Error("Failed to upload!");
  
          return fetch(presignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": contentType,
            },
            body: file,
          }).then((uploadResponse) => {
            if (!uploadResponse.ok) 
              throw new Error("Failed to upload file.");
  
            const uploadedFileUrl = presignedUrl.split("?")[0];
            setS3filelink(uploadedFileUrl);
            setUploading(false);
            setSnackbarMessage("File uploaded successfully!");
            setSnackbarType("success");
            setOpenSnackbar(true);
          });
        })
        .catch((error) => {
          setUploading("error");
          setSnackbarMessage(error.message || "An unexpected error occurred.");
          setSnackbarType("error"); 
          setOpenSnackbar(true); 
        }
      );
    };

const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // File selection
    if (selectedFile) {
        handleFileUpload(selectedFile, formValues.artifact_id); 
    }
};

const handleFormSubmit = async (event) => {
  event.preventDefault();

  const dateOfCapture = new Date().toISOString().split("T")[0];
  const artifactPath = S3filelink || ""; 

  const postData = {
    ...formValues,
    artifact_path: artifactPath, 
    is_document_added: artifactPath ? true : false,
    date_of_capture: dateOfCapture,
  };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}${window.location.host.split(".")[0]}/${cookie.load("Language") || "en"}/artifact/addsubcheck/${selectedCheck.check_id}/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": cookie.load("csrftoken"),
        },
        credentials: "include",
        body: JSON.stringify(postData),
      }
    );
    if (response.ok) {
      fetchSubChecks(selectedCheck.check_id); // Refresh sub-checks list
      setUploadFormOpen(false);
      setUploadError("");
      setS3filelink(null); // Reset S3filelink after successful upload
      setFormValues({
        sub_check_name: "",
        artifact_path: S3filelink,  // Reset artifact_path as well
        comment: "",
        date_of_expiry: "",
      });
    } else {
      console.error("Failed to upload sub-check");
    }
  } catch (error) {
    console.error("Failed to upload sub-check", error);
  }
};

  const handleOpenArtifactModal = (link) => {
    setArtifactLink(link);
    setOpenModal(true);
  };

  const handleCloseArtifactModal = () => {
    setOpenModal(false);
    setArtifactLink("");
    setIsFullscreen(false);
  };

  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleSubCheckFormSubmit = async (event) => {
    event.preventDefault();

    const dateOfCapture = new Date().toISOString().split("T")[0];

    const postData = {
      artifact_path:S3filelink,
      comment: formValues.comment,
      date_of_capture: dateOfCapture,
      date_of_expiry: formValues.date_of_expiry,
      artifact_uri: null,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/adddocument/${
          selectedSubCheck.sub_check_id
        }/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookie.load("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify(postData),
        }
      );
      if (response.ok) {
        fetchSubChecks(selectedCheck.check_id); // Refresh sub-checks list
        setSelectedSubCheck(null);
        setS3filelink(null);
        setFormValues({
          sub_check_name: "",
          artifact_path: S3filelink,
          comment: "",
          date_of_expiry: "",
        });
        setArtifactFormVisible(null); // Reset the artifact form visibility state
      } else {
        console.error("Failed to upload document");
      }
    } catch (error) {
      console.error("Failed to upload document", error);
    }
  };

  const renderFileViewer = (link) => {
    const mainPart = link.split("?AWSAccessKeyId")[0];

    const fileExtension = mainPart.split(".").pop().toLowerCase();
    if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={link}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["doc", "docx", "ppt", "pptx", "txt"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(link)}&embedded=true`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    
    if (["xls", "xlsx"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(link)}`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    
    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      return (
        <img
          src={link}
          alt="Artifact"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      );
    }
    
    return <Typography variant="h6">Unsupported file type</Typography>;
  };

  const minExpiryDate = new Date();
  minExpiryDate.setDate(minExpiryDate.getDate() + 1);

  const categoryNames = [
    ...new Set(requirements.map((item) => item.category_name).filter(Boolean)),
  ];

  const standardNames = [
    ...new Set(requirements.flatMap((item) => item.standards).filter(Boolean)),
  ];

  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === "string" ? value.split(",") : value);
  };

  const handleStandardsChange = (event, newValue) => {
    setSelectedStandards(newValue);
  };

  const filteredRequirements = requirements.filter((requirement) => {
    const matchesCategory =
      selectedCategories.length > 0
        ? selectedCategories.includes(requirement.category_name)
        : true;

    const matchesSearch = requirement.requirement_name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());

    const matchesStandard =
      selectedStandards.length > 0
        ? requirement.standards.some((standard) =>
            selectedStandards.includes(standard)
          )
        : true;

    return matchesCategory && matchesSearch && matchesStandard;
  });

  const displayedRequirements = filteredRequirements.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const columns = [
    {
      title: "Artifact Name",
      key: "artifact_name",
      width: "70%",
      render: (row) => (
        <Box>
          {/* Artifact Name in Bold */}
          <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "'Glacial Indifference', sans-serif",
              fontSize: "1rem",
            }}
          >
            {row.requirement_name}
          </Typography>
  
          {/* Artifact Description in Grey */}
          <Typography
            sx={{
              color: "#808080",
              fontFamily: "'Glacial Indifference', sans-serif",
              fontSize: "0.875rem",
            }}
          >
            {row.requirement_description.replace("{Company Name}", companyName)}
          </Typography>
        </Box>
      ),
    },
    
    {
      title: "Status",
      key: "status",
      width: "20%",
      render: (row) => (
        <>
          <Box
  sx={{
    display: 'inline-flex', 
    alignItems: 'center',  
  }}
>
  
  <Typography
    sx={{
      fontFamily: "'Glacial Indifference', sans-serif",
      fontSize: "0.875rem",
      color: row.status === "Pass" ? "green" : "red",
      marginLeft: "4px", // Optional: Adds a little space between the count and the status
    }}
  >
    {row.status}
  </Typography>
</Box>
        </>
      ),
    },
    
    
    {
      title: "Actions",
      key: "actions",
      width: "20%",
      render: (row, toggleSubRow) => (
        <CustomButton
          onClick={() => handleExpand(row.requirement_id, toggleSubRow)} // ✅ Pass toggleSubRow
          label="Checks"
          buttonType="medium"
        />
      ),
    },
  ];
  
  const handleExpand = async (requirement_id, toggleSubRow) => {
    if (expanded === requirement_id) {
      setExpanded(null); // Collapse row
      setChecks([]); // Clear checks
    } else {
      setExpanded(requirement_id); // Set expanded row ID
      setLoading(true); // Show loading indicator before API call
  
      await fetchChecks(requirement_id); // ✅ Wait for checks to load
  
      toggleSubRow(requirement_id); // ✅ Open the sub-row only after checks are loaded
    }
  };
  
  const renderChecksSubRow = (row, closeSubRow) => {
   
    return (
      <Box sx={{ padding: "10px", backgroundColor: "#f9f9f9", position: "relative", marginTop: "20px" }}>
        {/* Close icon positioned at the top right */}
        <Box
          sx={{
            position: "absolute",  // Absolute positioning
            top: "10px",
            right: "10px",
            cursor: "pointer",
          }}
          onClick={closeSubRow} // Close on icon click
        >
          <CloseIcon sx={{ fontSize: "20px" }} /> {/* Adjust fontSize here */}
        </Box>
   
        <Box sx={{ marginTop: "20px" }}>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : checks.length ? (
            checks.map((check, index) => (
              <Box key={check.check_id} sx={{ display: "flex", justifyContent: "space-between", padding: "6px 0" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
            sx={{
              fontWeight: "bold",
              fontFamily: "'Glacial Indifference', sans-serif",
              fontSize: "1rem",
            }}
          >{`${index + 1}. ${check.check_name}`}</Typography>
                  <Typography sx={{ fontSize: "12px", color: "gray", marginBottom: "10px" }}>
                    {check.check_description}
                  </Typography>
                </Box>
                <CustomButton
                  onClick={() => handleDrawerOpen(check)} // ✅ Pass toggleSubRow
                  label="SubChecks"
                  buttonType="medium"
                />
              </Box>
            ))
          ) : (
            <Typography>No checks found.</Typography>
          )}
        </Box>
      </Box>
    );
   
   
 
  }

  return (
    <div className="main-div">
    <Box
  sx={{
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    justifyContent: "flex-start",
    alignItems: "center",
    mb: 2, // Added bottom margin
  }}
>        <SearchBox value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} labelEn="Search Requirements" labelAr="بحث عن القطع الأثرية" />
        <MultiSelect label="Select Category" selectedValues={selectedCategory} options={categoryOptions} onChange={(event, newValue) => setSelectedCategory(newValue)} />
        <MultiSelect label="Select Standard" selectedValues={selectedStandard} options={standardOptions} onChange={(event, newValue) => setSelectedStandard(newValue)} width="450px" />
      </Box>
      <CustomTable
  columns={columns}
  data={RequirementData}
  totalItems={pagination.totalItems}
  pageSize={pagination.pageSize}
  currentPage={pagination.currentPage}
  totalPages={pagination.totalPages}
  onPageChange={handlePageChange}
  isLoading={isLoading}
  invokeSubRow={renderChecksSubRow}
/>



      {displayedRequirements.length ? (
        displayedRequirements.map((requirement) => {
          const modifiedDescription =
            requirement.requirement_description.replace(
              "{Company Name}",
              companyName
            );
          const isCurrentExpanded = expanded === requirement.requirement_id;
          return (
            <Accordion className="req-accordion"
              key={requirement.requirement_id}
              expanded={expanded === requirement.requirement_id}
              onChange={handleChange(requirement.requirement_id)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box>
                  <Typography className="typography-1">{requirement.requirement_name}</Typography>
                  <Typography className="typography-1" variant="body2" color="textSecondary">
                    {modifiedDescription}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className="accord-details-1">
                {isCurrentExpanded && loading ? (
              <Typography className="typography-1">Loading checks...</Typography> 
            ) : checks.length ? (
                  checks.map((check, index) => (
                    <Box className="check-box-1" key={check.check_id}>
                      <Box className="check-box-2">
                        <Box>
                          <Typography className="typography-1">{`Check ${index + 1}: ${check.check_name}`}</Typography>
                        </Box>
                        <Button className="action-button"
                          onClick={() => handleDrawerOpen(check)}
                        >
                          Action
                        </Button>
                        
                      </Box>
                      <Divider className="check-divider"/>
                    </Box>
                  ))
                ) : (
                  <Typography className="typography-1">No checks found.</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <></>
      )}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Edit Requirement</DialogTitle>
      </Dialog>
      

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{
          "& .MuiDrawer-paper": {
            width: "40%",
            padding: "20px",
          },
        }}
      >
        {selectedCheck && (
          <Box>
            <Typography className="typography-1" variant="h6">{selectedCheck.check_name}</Typography>
            <Typography className="typography-1" variant="body1" gutterBottom>
              {selectedCheck.check_description}
            </Typography>
            <Button className="action-button"
              onClick={handleUploadButtonClick}
            >
              Upload
            </Button>
            <Modal
              open={uploadFormOpen}
              onClose={() => {    
                setUploadFormOpen(false); 
                setFormValues({
                  sub_check_name: '',
                  comment: '',
                  date_of_expiry: '',
                });
                setUploadError("");
                setUploading(false);
                setS3filelink(""); 
                // fileInput.current.value = null;
              }}
              aria-labelledby="upload-form-title"
              aria-describedby="upload-form-description"
            >
              <Box 
                component="form"
                onSubmit={handleFormSubmit}
                className="popup-box"
              >
                <Typography className="artifact-typography">
                  Upload Artifact
                </Typography>
                <TextField
                  name="sub_check_name"
                  label="Sub Check Name"
                  variant="outlined"
                  fullWidth
                  required
                  value={formValues.sub_check_name}
                  onChange={handleFormChange}
                  sx={{ mb: 2 }}
                />
                   <TextField
                type="file"
                fullWidth
                required
                onChange={(e) => handleFileUpload(e.target.files[0])}
                // sx={{mb:1}}
              />
            <Typography
              className="reqlist-typography-12"
              sx={{color: uploading ? "#FFC000" : uploadError ? "red" : "green" }}>
              {uploading
                ? "Uploading..."
                : uploadError
                ? uploadError
                : S3filelink
                ? "File uploaded"
                : ""}
            </Typography>
                <TextField
                  name="comment"
                  label="Comment"
                  className="reqlist-textfield-comment"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={formValues.comment}
                  onChange={handleFormChange}
                  sx={{ mb: 2,mt:2 }}
                />
                <TextField
                  name="date_of_expiry"
                  label="Date of Expiry"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: minExpiryDate.toISOString().split("T")[0],
                  }}
                  fullWidth
                  required
                  value={formValues.date_of_expiry}
                  onChange={handleFormChange}
                  sx={{ mb: 2 }}
                />
                <Box className="popup-box-2">
                  <Button className="popup-cancel-button"
                    onClick={() => {
                      setUploadFormOpen(false);
                      setUploadError("");
                      setUploading(false);
                      setS3filelink("");
                      setFormValues({
                        sub_check_name: '',
                        comment: '',
                        date_of_expiry: '',
                      });          
                      // fileInput.current.value = null; 
                    }}
                  >
                    Cancel
                  </Button>
                  <Button className="popup-submit-button"
                    type="submit"
                    disabled={uploading}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Modal>
            <Typography className="typography-2" variant="h6">
              Artifacts
            </Typography>
            {subChecks.length ? (
              subChecks.map((subCheck, index) => (
              <Accordion className="subcheck-accordion"
                key={subCheck.sub_check_id}
                expanded={expandedSubCheck === subCheck.sub_check_id}
                onChange={handleSubCheckChange(subCheck.sub_check_id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box>
                    <Typography className="subcheck-typography">{`Sub Check ${index + 1}: ${subCheck.sub_check_name}`}</Typography>
                  </Box>
                </AccordionSummary>
    
                {expandedSubCheck === subCheck.sub_check_id && (
                <AccordionDetails className="accord-details-2">
                  <Button className="action-button"
                    onClick={() => {
                      setSelectedSubCheck(subCheck);
                      setArtifactFormVisible(subCheck.sub_check_id); 
                    }}
                  >
                    Renew
                  </Button>
                  {artifactLoading ? (
                    <Typography className="artifact-typography-2">
                      Artifact is loading...</Typography> 
                  ) : artifactList.length ? (
                    artifactList.map((artifact, artIndex) => (
                      <Box
                        className="artifact-box"
                        key={artifact.artifact_id}
                      >
                        <Typography className="typography-1" variant="body2" color="textSecondary">
                          Comment: {artifact.comment || "No comment"}
                        </Typography>
                        <Typography className="typography-1" variant="body2" color="textSecondary">
                          Date of Capture:{" "}
                          {new Date(
                            artifact.date_of_capture
                          ).toLocaleDateString() || "N/A"}
                        </Typography>
                        <Typography className="typography-1" variant="body2" color="textSecondary">
                          Date of Expiry:{" "}
                          {new Date(
                            artifact.date_of_expiry
                          ).toLocaleDateString() || "N/A"}
                        </Typography>
                        <Button className="open-artifact-button"
                          variant="outlined"
                          onClick={() =>
                            handleOpenArtifactModal(artifact.artifact_uri)
                          }
                        >
                          Open
                        </Button>
                      </Box>
                    ))
                  ) : (
                    <Typography className="typography-1">
                      No versions found for this artifact.
                    </Typography>
                  )}
                  <Modal
                    open={artifactFormVisible === subCheck.sub_check_id}
                    onClose={() => {
                      setArtifactFormVisible(null);
                      setFormValues({
                        comment: '',
                        date_of_expiry: '',
                      }); 
                      // fileInput.current.value = null; 
                    }}
                    aria-labelledby="renew-form-title"
                    aria-describedby="renew-form-description"
                  >
                    <Box className="popup-box-3"
                      component="form"
                      onSubmit={handleSubCheckFormSubmit}
                    >
                      <Typography className="renew-artifact-typo">
                        Renew Artifact
                      </Typography>
                      <TextField
                        type="file"
                        inputRef={fileInput}
                        fullWidth
                        required
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                        sx={{ mb: 2 }}
                      />
                        <Typography
                        className="reqlist-typography-12"
                         sx={{color: uploading ? "#FFC000" : uploadError ? "red" : "green"}}>
                         {uploading
                           ? "Uploading..."
                           : uploadError
                           ? uploadError
                           : S3filelink
                           ? "File uploaded"
                           : ""}
                     </Typography>
                      <TextField
                        name="comment"
                        label="Comment"
                        className="reqlist-textfield-comment"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        value={formValues.comment}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        name="date_of_expiry"
                        label="Date of Expiry"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          min: minExpiryDate.toISOString().split("T")[0],
                        }}
                        fullWidth
                        required
                        value={formValues.date_of_expiry}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          className="popup-cancel-button"
                          onClick={() => {
                            setArtifactFormVisible(null); 
                            setFormValues({
                              comment: '',
                              date_of_expiry: '',
                            });
                            setUploadError("");
                            setUploading(false);
                            setS3filelink("");
                            // fileInput.current.value = null;
                          }}
                        >
                          Cancel
                        </Button>
                        <Button className="popup-submit-button"
                          type="submit"
                          disabled={uploading}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                  </AccordionDetails>
                  )}
                </Accordion>
              ))
            ) : (
              <Typography className="typography-1">No sub checks found.</Typography>
            )}
          </Box>
        )}
      </Drawer>

      <Modal
        open={openModal}
        onClose={handleCloseArtifactModal}
        aria-labelledby="artifact-modal-title"
        aria-describedby="artifact-modal-description"
      >
        <Box className="iframe-box-1"
          sx={{
            width: isFullscreen ? "100%" : width * 0.8,
            height: isFullscreen ? "100%" : height * 0.8,
          }}
        >
          <Box className="iframe-box-2">
            <IconButton className="icon-button-1"
              onClick={handleCloseArtifactModal}
            >
              <CloseIcon />
            </IconButton>
            <IconButton className="icon-button-1"
              onClick={handleToggleFullscreen}
            >
              {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Box>
          <Box className="iframe-box-3">
            {renderFileViewer(artifactLink)}
          </Box>
        </Box>
      </Modal>
      <RequirementDrawer drawerOpen={open} policy_document_id={documentId} handleDrawerClose={handleClose}/>
      <Snackbar
       open={openSnackbar}
       autoHideDuration={4000} 
       onClose={handleCloseSnackbar}
       anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
      >
       <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
         {snackbarMessage}
       </Alert>
     </Snackbar>
    </div>
  );
}

export default RequirementsListing;
