import React, { useState, useEffect } from "react";
import cookie from "react-cookies";
import useWindowDimensions from "../../useWindowDimensions";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import EditRiskRegister from "./EditRiskRegister";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CreateRiskRegister from "./CreateRiskRegister";
import "./RiskRegister.css";

import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  TableContainer,
  MenuItem,
  Select,
  Skeleton,
  Table,
  Checkbox,
  ListItemText,
  Chip,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";

function RiskRegister({ riskAssessmentId }) {
  const [risks, setRisks] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRisk, setSelectedRisk] = useState(null);
  const [error, setError] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [createOpen,setCreateOpen]=useState(false);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [selectedInherentRisk, setSelectedInherentRisk] = useState("");
  const [selectedResidualRisk, setSelectedResidualRisk] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedTreatment, setSelectedTreatment] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { height } = useWindowDimensions();
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({
      risk_name: "",
      impact_c: "",
      impact_i: "",
      impact_a: "",
      likelihood: "",
      inherent_risk_value: "",
      inherent_risk: "",
      mitigation_plan: "",
      modified_impact_c: "",
      modified_impact_i: "",
      modified_impact_a: "",
      modified_likelihood: "",
      residual_risk_value: "",
      residual_risk: "",
      risk_observation: "",
      risk_identification: "",
      target_date: null,
      alias_name: null,
      artifact_capture_date: null,
      artifact_expiry_date: null,
      artifact_name: null,
      artifact_path: null,
      status: null,
    });
  
  const initialFormData = {
    risk_name: "",
    impact_c: 0,
    impact_i: 0,
    impact_a: 0,
    likelihood: "",
    inherent_risk_value: "",
    inherent_risk: "",
    mitigation_plan: "",
    modified_impact_c: "",
    modified_impact_i: "",
    modified_impact_a: "",
    modified_likelihood: "",
    residual_risk_value: "",
    residual_risk: "",
    risk_observation: "",
    risk_identification: "",
    target_date: null,
    alias_name: null,
    artifact_capture_date: null,
    artifact_expiry_date: null,
    artifact_name: null,
    artifact_path: null,
    status: null,
  };
  const handleCreateClick = () => {
    setCreateOpen(true);
    setFormData(initialFormData); 
  };

  const handleDrawerOpen = (risk) => {
    setSelectedRisk(risk);
    setDrawerOpen(true);
    setFormData({
      ...risk,
      alias_name: risk.alias_name || null,
      artifact_capture_date: risk.artifact_capture_date || null,
      artifact_expiry_date: risk.artifact_expiry_date || null,
      artifact_name: risk.artifact_name || null,
      artifact_path: risk.artifact_path || null,
      status: risk.status || null,
    });
  };
  
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setCreateOpen(false);
    setSelectedRisk(null);
    setFormData(initialFormData);
    // setFile(null);
  };

  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= totalPages && newPage !== currentPage) {
      setCurrentPage(newPage);

      await filterRisks(
        selectedInherentRisk,
        selectedResidualRisk,
        selectedStatus,
        selectedTreatment,
        selectedCategoryFilter,
        searchFilter,
        pageSize,
        newPage
      );
    }
  };
  function generatePageNumbers() {
    const pageNumbers = [];
    const maxPagesToShow = 3;
    if (totalPages <= maxPagesToShow + 2) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= maxPagesToShow) {
        for (let i = 1; i <= maxPagesToShow + 1; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - (maxPagesToShow - 1)) {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = totalPages - maxPagesToShow; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (
          let i = currentPage - Math.floor(maxPagesToShow / 2);
          i <= currentPage + Math.floor(maxPagesToShow / 2);
          i++
        ) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }
    return pageNumbers;
  }

  const handleCategoryFilterChange = (event) => {
    const selectedCategories = event.target.value;

    const selectedCategoryIds = Array.isArray(selectedCategories)
      ? categories
        .filter((category) => selectedCategories.includes(category.category_name))
        .map((category) => category.category_id)
      : [];

    setSelectedCategoryFilter(selectedCategoryIds);
    setCategoryFilter(selectedCategories);
    setCurrentPage(1);
  };

  const subdomain = window.location.host.split(".")[0];

  const filterRisks = async (
    inherentRisk = "",
    residualRisk = "",
    status = "",
    treatment = "",
    category = selectedCategoryFilter,
    search = "",
    pageSize = 10,
    currentPage = 1
  ) => {
    setLoading(true);
    setError(null);

    const baseURL = `${process.env.REACT_APP_BACKEND}${subdomain}/${cookie.load("Language") || "en"
      }/riskassessment/risk-register-list/${riskAssessmentId}`;

    const url = new URL(baseURL);

    if (inherentRisk) url.searchParams.append("inherent_risk", inherentRisk);
    if (residualRisk) url.searchParams.append("residual_risk", residualRisk);
    if (status) url.searchParams.append("status", status);
    if (treatment) url.searchParams.append("treatment", treatment);
    if (Array.isArray(category) && category.length > 0) {
      url.searchParams.append("category", category.join(","));
    }
    if (search) url.searchParams.append("search", search);
    url.searchParams.append("page_size", pageSize);
    url.searchParams.append("page", currentPage);

    try {
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRisks(data.risk_register || []);
        setCategories(data.categories || []);
        setTotalPages(data.pagination.total_pages || 1);
        setTotalRecords(data.pagination.total_records || 0);
      } else {
        throw new Error(`Failed to fetch data, status: ${response.status}`);
      }
    } catch (error) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    filterRisks(selectedInherentRisk,selectedResidualRisk,selectedStatus,selectedTreatment,selectedCategoryFilter,searchFilter,pageSize);
  }, [selectedInherentRisk,selectedResidualRisk,selectedStatus,selectedTreatment,selectedCategoryFilter,searchFilter,pageSize]);

  const getChipColor = (riskLevel) => {
    if (!riskLevel) return "default";
    switch (riskLevel.toLowerCase()) {
      case "low":
        return "#a5d6a7";
      case "medium":
        return "#fff59d";
      case "high":
        return "#ef9a9a";
      default:
        return "default";
    }
  };

  return (
    <div
      className="risk-reg-main-div"
    >
      <div

        style={{
          height: height - 115,
          overflow: "hidden",
          overflowY: "scroll",
        }}
      >
        <TableContainer>
          <div className="risk-reg-div-1">
            <Box className="risk-reg-box-1">
              <Box sx={{ flex: 1, maxWidth: "30%" }}>
                <TextField
                  fullWidth
                  label="Search Risks"
                  variant="outlined"
                  value={searchFilter}
                  onChange={(event) =>setSearchFilter(event.target.value)}
                />
              </Box>
              <Box className="risk-reg-box-4" >
                <Button
                  className="risk-reg-btn-1"
                  onClick={handleCreateClick}
                  aria-label="add"
                >
                  Create Custom Risk
                </Button>
                <Button
                  className="risk-reg-btn-1"
                  aria-label="add"
                >
                  Generate Report
                </Button>
              </Box>
            </Box>
            <Box className="risk-reg-box-3">

              <FormControl fullWidth variant="outlined">
                <InputLabel>Category</InputLabel>
                <Select
                  multiple
                  value={categoryFilter}
                  onChange={handleCategoryFilterChange}
                  renderValue={(selected) => selected.join(", ")}
                  label="Category"
                  className="menu-item-font"
                >
                  {categories.map((category) => (
                    <MenuItem
                      key={category.category_id}
                      value={category.category_name}
                    >
                      <Checkbox
                        size="small"
                        checked={
                          categoryFilter.indexOf(category.category_name) > -1
                        }
                      />
                      <ListItemText primary={category.category_name}
                        primaryTypographyProps={{
                          style: { fontFamily: "'Glacial Indifference', sans-serif" },
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Inherent Risk</InputLabel>
                <Select
                  value={selectedInherentRisk}
                  onChange={(event) =>setSelectedInherentRisk(event.target.value)}
                  label="Inherent Risk"
                  className="menu-item-font"
                >
                  <MenuItem className="menu-item-font" value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem className="menu-item-font" value="Low">Low</MenuItem>
                  <MenuItem className="menu-item-font" value="Medium">Medium</MenuItem>
                  <MenuItem className="menu-item-font" value="High">High</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Residual Risk</InputLabel>
                <Select
                  value={selectedResidualRisk}
                  onChange={(event) => setSelectedResidualRisk(event.target.value)}
                  label="Residual Risk"
                  className="menu-item-font"
                >
                  <MenuItem className="menu-item-font" value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem className="menu-item-font" value="Low">Low</MenuItem>
                  <MenuItem className="menu-item-font" value="Medium">Medium</MenuItem>
                  <MenuItem className="menu-item-font" value="High">High</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={selectedStatus}
                  onChange={(event) => setSelectedStatus(event.target.value)}
                  label="Status"
                  className="menu-item-font"
                >
                  <MenuItem className="menu-item-font" value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem className="menu-item-font" value="Open">Open</MenuItem>
                  <MenuItem className="menu-item-font" value="In Progress">In Progress</MenuItem>
                  <MenuItem className="menu-item-font" value="Closed">Closed</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Treatment</InputLabel>
                <Select
                  value={selectedTreatment}
                  onChange={ (event) => setSelectedTreatment(event.target.value)}
                  label="Treatment"
                  className="menu-item-font"
                >
                  <MenuItem value="">
                    <em>All</em>
                  </MenuItem>
                  <MenuItem className="menu-item-font" value="MITIGATE">Mitigate</MenuItem>
                  <MenuItem className="menu-item-font" value="TRANSFER">Transfer</MenuItem>
                  <MenuItem className="menu-item-font" value="AVOID">Avoid</MenuItem>
                  <MenuItem className="menu-item-font" value="ACCEPT">Accept</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
          <div style={{ flexGrow: 1, minHeight: "0", overflowY: "auto" }}>
            {loading ? (
              <Box className="risk-reg-box-5">
                <Skeleton height={60} width="20%" />
                <Skeleton height="100px" animation="wave" />
                <Skeleton height="100px" animation={false} />
                <Skeleton height="100px" animation="wave" />
              </Box>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-cell-1"> Risk Name </TableCell>
                    <TableCell className="table-cell-1"> Category </TableCell>
                    <TableCell className="table-cell-1"> Treatment </TableCell>
                    <TableCell className="table-cell-1"> Inherent Risk </TableCell>
                    <TableCell className="table-cell-1"> Residual Risk </TableCell>
                    <TableCell className="table-cell-1"> Status </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {risks.length ? (
                    risks.map((risk, index) => (
                      <TableRow
                        key={risk.risk_register_risk_assessment_id}
                        onClick={() => handleDrawerOpen(risk)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell className="table-cell-2">
                          {risk.requirement_name === "Custom"
                            ? risk.alias_name
                            : risk.requirement_name}
                        </TableCell>
                        <TableCell className="table-cell-2">
                          {risk.category_name}
                        </TableCell>
                        <TableCell className="table-cell-2">
                          {risk.treatment ? (
                            <Typography className="risk-typography-2">
                              {risk.treatment}
                            </Typography>
                          ) : (
                            <Typography className="risk-typography-3">
                              -
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Chip
                            className="table-cell-2"
                            label={`IR: ${risk.inherent_risk}`}
                            style={{
                              backgroundColor: getChipColor(risk.inherent_risk),
                              minWidth: "90px",
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Chip
                            className="table-cell-2"
                            label={`RR: ${risk.residual_risk}`}
                            style={{
                              backgroundColor: getChipColor(risk.residual_risk),
                              minWidth: "90px",
                            }}
                          />
                        </TableCell>
                        <TableCell className="table-cell-2">
                          {risk.status}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <Box className="risk-reg-box-2">
                        <Typography className="risk-typography-4"> risk register not found </Typography>
                      </Box>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </div>
          {drawerOpen && (
          <EditRiskRegister drawerModelOpen={drawerOpen} filterRisks={filterRisks} selectedRiskData={selectedRisk} handleDrawerModelClose={handleDrawerClose}/>
         )}
         {createOpen && (
          <CreateRiskRegister drawerModel1Open={createOpen} riskAssessmentId={riskAssessmentId} handleDrawerModelClose={handleDrawerClose}/>
         )}

        </TableContainer>
        <div className="pagination-container">
          <div className="pagination-total">
            Total Records: {totalRecords}
          </div>
          <div className="pagination-controls">
            {/* Previous Button */}
            <button
              className="pagination-icon-button"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              style={{
                color: currentPage === 1 ? "#ccc" : "#000",
              }}
            >
              <ArrowBackIosOutlinedIcon style={{ fontSize: "14px" }} />
            </button>
            {generatePageNumbers().map((page, index) => (
              <button
                key={index}
                className={`pagination-button ${page === currentPage ? "pagination-button-current" : ""
                  }`}
                onClick={() => typeof page === "number" && handlePageChange(page)}
                disabled={page === currentPage || page === "..."}
              >
                {page === "..." ? "..." : page}
              </button>
            ))}
            <button
              className="pagination-icon-button"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              style={{
                color: currentPage === totalPages ? "#ccc" : "#000",
              }}
            >
              <ArrowForwardIosOutlinedIcon style={{ fontSize: "14px" }} />
            </button>
          </div>
        </div>
      </div>
    </div>


  );
}

export default RiskRegister;

