import React, { useState, useEffect, lazy, Suspense, useContext } from "react";
import cookie from "react-cookies";
import Loadingimg from "./img/loadinghumming.gif";
import RoutesConfig from "./Routes";
import { Helmet } from "react-helmet-async";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";
import { decryption, encryption } from "./auth/utilities";
import "./frameBuster.js";
import { UserContext } from "./auth/UserContext.js";
import { SnackbarProvider } from "./ReusableComponents/SnackbarManager.js"; // ✅ Ensure correct import path


// ----------------------------------------------------------------------
const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

function App() {
  const subdomain = window.location.host.split(".")[0];
    const [isTenant, setIsTenant] = useState(null);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const { sessionUser, updateUser } = useContext(UserContext);

  // Global state for notification count
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const checkTenant = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND}${subdomain}/${
            cookie.load("Language") || "en"
          }/auth/checktenant/`,
          { credentials: "include" }
        );
        const data = await res.json();
        if (data.tenant) {
          cookie.save("isTenant", true, { path: "/" });
          await getSession(); // Wait for session authentication
        } else {
          cookie.save("isTenant", false, { path: "/" });
          setIsTenant(false);
          setIsUserDataLoaded(true);
        }
      } catch (error) {
        console.error("Error checking tenant:", error);
        setIsUserDataLoaded(true);
      }
    };

    checkTenant();
  }, [subdomain]); // ✅ Runs only when the subdomain changes (Fixes infinite loop)

  const getUserData = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND}${subdomain}/${
          cookie.load("Language") || "en"
        }/auth/user-data/`,
        { credentials: "include" }
      );
      const response = await res.json();
  
  
      if (!response || Object.keys(response).length === 0) {
        console.error("❌ API returned empty user data!");
        return;
      }
  
      const decryptedUserData = {
        userEmail: decryption(response.user_email) || "",
        profileImage: decryption(response.profile_image) || "",
        profileName: decryption(response.profile_name) || "Guest User",
        userRole: decryption(response.user_role) || "guest",
        userId: decryption(response.user_id) || null,
        tenantId: decryption(response.tenant_id) || null,
        subdomain: decryption(response.subdomain) || "",
        notificationCount: notificationCount,
      };
  
  
      updateUser(decryptedUserData);
    } catch (error) {
    }
  };
  


  const getSession = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND}${subdomain}/${
          cookie.load("Language") || "en"
        }/auth/authenticate/`,
        { credentials: "include" }
      );
      const data = await res.json();
      setIsTenant(data.tenant);

      if (data.tenant) {
        cookie.save("isTenant", true, { path: "/" });

        if (data.isAuthenticated) {
          cookie.save("isAuthenticated", true, { path: "/" });
          cookie.save("isTenantUser", data.isTenantUser, { path: "/" });

          // ✅ Update the notification count globally before updating user data
          setNotificationCount(data.notification_count || 0);

          if (sessionUser.userRole !== "guest") {
            // ✅ Update sessionUser directly without fetching again
            updateUser({
              ...sessionUser,
              notificationCount: data.notification_count, // ✅ Updates notification count
            });
          } else {
            await getUserData();
          }

          // cookie.save("user_role", encryption(data.user_role), { path: "/" });
        } else {
          cookie.save("isAuthenticated", false, { path: "/" });
        }
        if (window.location.hostname.endsWith(".localhost") && data.isAuthenticated)  {
          // For local environment: Save cookies on the frontend
          cookie.save("sessionid", data.sessionid.toString(), { path: "/" });
          cookie.save("csrftoken", data.csrftoken.toString(), { path: "/" });
      }
      } else {
        cookie.save("isTenant", false, { path: "/" });
        setIsTenant(false);
      }

      setIsUserDataLoaded(true);
    } catch (error) {
      console.error("Error in getSession:", error);
      setIsUserDataLoaded(true);
    }
  };

 
  if (!isUserDataLoaded) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "#fefefe",
        }}
      >
        <img src={Loadingimg} alt="Loading" />
      </div>
    );
  }

  return (
    <SnackbarProvider> {/* ✅ Wrapping Entire App with SnackbarProvider */}

    <div>
      {isTenant !== null ? (
        <div>
          {isTenant === true ? (
            <Suspense
              fallback={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    background: "#fefefe",
                  }}
                >
                  <img src={Loadingimg} alt="Loading" />
                </div>
              }
            >
              <RoutesConfig role1={sessionUser.userRole} />
            </Suspense>
          ) : (
            <Container>
              <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
                <Typography variant="h4" paragraph>
                  Sorry, tenant not found!
                </Typography>

                <Typography sx={{ color: "text.secondary" }}>
                  Sorry, we couldn’t find the tenant you’re looking for. Perhaps
                  you’ve mistyped the tenant name? Be sure to check your
                  spelling.
                </Typography>

                <Box
                  component="img"
                  src="https://minimal-kit-react.vercel.app/assets/illustrations/illustration_404.svg"
                  sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
                />
                <Link
                  underline="hover"
                  color="inherit"
                  href="https://www.whizzc.com/"
                >
                  <Button
                    to="/d-error-404-page-not-foundd"
                    size="large"
                    variant="contained"
                  >
                    Go to whizzc
                  </Button>
                </Link>
              </StyledContent>
            </Container>
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            background: "#fefefe",
          }}
        >
          <img src={Loadingimg} alt="Loading" />
        </div>
      )}
    </div>
    </SnackbarProvider>

  );
}

export default App;
