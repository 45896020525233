import React, { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
  Skeleton,
  IconButton,
} from "@mui/material";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

const CustomTable = ({ 
  columns, 
  data, 
  totalItems, 
  pageSize, 
  currentPage, 
  totalPages, 
  onPageChange, 
  isLoading,
  invokeSubRow 
}) => {
  const [openSubRow, setOpenSubRow] = useState(null);

  const toggleSubRow = (rowIndex) => {
    if (!invokeSubRow) return;
    setOpenSubRow((prevRow) => (prevRow === rowIndex ? null : rowIndex));
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 3;
    if (totalPages <= maxPagesToShow + 2) {
      for (let i = 1; i <= totalPages; i++) pageNumbers.push(i);
    } else {
      if (currentPage <= maxPagesToShow) {
        for (let i = 1; i <= maxPagesToShow + 1; i++) pageNumbers.push(i);
        pageNumbers.push("...", totalPages);
      } else if (currentPage >= totalPages - (maxPagesToShow - 1)) {
        pageNumbers.push(1, "...");
        for (let i = totalPages - maxPagesToShow; i <= totalPages; i++) pageNumbers.push(i);
      } else {
        pageNumbers.push(1, "...");
        for (let i = currentPage - Math.floor(maxPagesToShow / 2); i <= currentPage + Math.floor(maxPagesToShow / 2); i++)
          pageNumbers.push(i);
        pageNumbers.push("...", totalPages);
      }
    }
    return pageNumbers;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px", maxWidth: "100%" }}>
      <TableContainer sx={{ minHeight: "500px", overflowY: "auto", borderRadius: "8px" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#dfdfdf" , border: "1px solid #ddd"}}>
              {columns.map((column, index) => (
                <TableCell 
                  key={index} 
                  sx={{ 
                    fontWeight: "bold", 
                    backgroundColor: "#dfdfdf", 
                    fontFamily: "'Glacial Indifference', sans-serif",
                    width: column.width || "auto", // Apply column width
                  }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? Array.from({ length: pageSize }).map((_, rowIndex) => (
                  <TableRow key={rowIndex} sx={{  border: "1px solid #ddd"}} >
                    {columns.map((column, colIndex) => (
                      <TableCell key={colIndex} sx={{ width: column.width || "auto" }}>
                        <Skeleton variant="text" width={column.width || "100%"} height={20} />
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : data.length > 0
              ? data.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    <TableRow hover >
                      {columns.map((column, colIndex) => (
                        <TableCell key={colIndex} sx={{ width: column.width || "auto" }}>
                          {column.render ? column.render(row, () => toggleSubRow(rowIndex)) : row[column.key]}
                        </TableCell>
                      ))}
                    </TableRow>
                    {openSubRow === rowIndex && invokeSubRow && (
                      <TableRow>
                        <TableCell colSpan={columns.length}>
                          {invokeSubRow(row, () => toggleSubRow(null))}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))
              : !isLoading && (
                  <TableRow>
                   <TableCell 
  colSpan={columns.length} 
  align="center"
  sx={{ fontFamily: "'Glacial Indifference', sans-serif", fontSize: "1rem", color: "#808080" }}
>
  No data available
</TableCell>

                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 1 }}>
        <Typography variant="body2" sx={{ fontWeight: "bold", color: "#000035", fontSize: "12px" }}>
          Total Count: <span style={{ fontWeight: "normal", color: "#000035" }}>{totalItems}</span>
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "9px" }}>
          <button 
            onClick={() => onPageChange(currentPage - 1)} 
            disabled={currentPage === 1}
            style={{ background: "none", border: "none", color: currentPage === 1 ? "#ccc" : "#000" }}
          >
            <ArrowBackIosOutlinedIcon style={{ fontSize: "14px" }} />
          </button>

          {generatePageNumbers().map((page, index) => (
            <button
              key={index}
              onClick={() => typeof page === "number" && onPageChange(page)}
              disabled={page === currentPage || page === "..."}
              style={{
                color: page === currentPage ? "#fff" : "#000035",
                padding: "3px 6px",
                border: "1px solid #000035",
                borderRadius: "4px",
                backgroundColor: page === currentPage ? "#000035" : "#fff",
                cursor: page === currentPage || page === "..." ? "default" : "pointer",
                fontSize: "14px",
                fontWeight: page === currentPage ? "bold" : "normal",
              }}
            >
              {page}
            </button>
          ))}

          <button
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{ background: "none", border: "none", color: currentPage === totalPages ? "#ccc" : "#000" }}
          >
            <ArrowForwardIosOutlinedIcon style={{ fontSize: "14px" }} />
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomTable;
