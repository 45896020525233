import { useEffect, useState, useCallback, useRef } from "react";
import fetchData from "../../../api/apiService.js";
import MultiSelect from "../../../ReusableComponents/MultiSelect.js";
import SearchBox from "../../../ReusableComponents/SearchBox.js";
import CustomTable from "../../../ReusableComponents/CustomTable.js";
import AssignUserComponent from "../../../ReusableComponents/AssignUserComponent.js";
import CustomButton from "../../../ReusableComponents/CustomButton.js"; // Import CustomButton
import { useSnackbar } from "../../../ReusableComponents/SnackbarManager.js"; // Import CustomButton

import { Box, Typography, TextField, Button, IconButton, InputAdornment } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { debounce } from "lodash";
import cookie from "react-cookies";

function ArtifactListingPage() {
  const [complianceOptions, setComplianceOptions] = useState([]);
  const [processOptions, setProcessOptions] = useState([]);
  const [standardOptions, setStandardOptions] = useState([]);
  const [selectedCompliance, setSelectedCompliance] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [artifactData, setArtifactData] = useState([]);
  const [userList, setUserList] = useState([]); // Stores the user list
  const [isLoading, setIsLoading] = useState(true);
  const [selectedArtifact, setSelectedArtifact] = useState(null);
  const [uploading, setUploading] = useState(false); // Track upload status
  const [S3filelink0, setS3filelink0] = useState(null); // Store uploaded file link
  const showSnackbar = useSnackbar(); // ✅ Assign function correctly



  const [pagination, setPagination] = useState({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 10,
  });
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);

  const [artifactName, setArtifactName] = useState("");
const [captureDate, setCaptureDate] = useState("");
const [expiryDate, setExpiryDate] = useState("");
const [presignedUrl, setPresignedUrl] = useState(""); // Store generated URL
const [filePath, setFilePath] = useState(""); 

  // Fetch Artifact Data & User List
  const fetchMasterArtifactData = useCallback(
    async (page = pagination.currentPage) => {
      setIsLoading(true);
      const queryParams = new URLSearchParams({
        page,
        page_size: pagination.pageSize,
      });

      if (selectedCompliance.length)
        queryParams.append("compliance", selectedCompliance.map((i) => i.id).join(","));
      if (selectedProcess.length)
        queryParams.append("business_process", selectedProcess.map((i) => i.id).join(","));
      if (selectedStandard.length)
        queryParams.append("standard", selectedStandard.map((i) => i.id).join(","));
      if (searchTerm) queryParams.append("search", searchTerm);

      try {
        const data = await fetchData(`artifact/artifactmasterlist/?${queryParams.toString()}`);
        if (data) {
          setArtifactData(data.artifact_master || []);
          setUserList(data.user_list || []); // Store the user list

          setPagination({
            totalItems: data.total_items,
            totalPages: data.total_pages,
            currentPage: data.current_page,
            pageSize: data.page_size,
          });
        }
      } catch (error) {
        showSnackbar("Failed to fetch artifact data. Please try again.", "error");
      } finally {
        setIsLoading(false);
      }
    },
    [pagination.pageSize, selectedCompliance, selectedProcess, selectedStandard, searchTerm]
  );
  const handleClick = (event) => {
    event.stopPropagation(); // Prevents bubbling up to parent elements
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleUpload = async (file, artifactId) => {
  
    if (!file) {
      console.error("No file selected");
      return;
    }
  
    const filePath = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const contentType = file.type || "application/octet-stream";
  
  
    try {
      const endpoint = "artifact/generate-presigned-urls/";
      const requestBody = {
        files: [
          {
            file_path: filePath,
            content_type: contentType,
          },
        ],
      };
  
  
      const data = await fetchData(endpoint, {
        method: "POST",
        body: requestBody,
      });
  
  
      const presignedUrl = data?.presigned_urls?.[0]?.presigned_url;
      if (!presignedUrl) {
        console.error("No pre-signed URL received!");
        throw new Error("Failed to fetch presigned URL");
      }
  
      // ✅ Split at "?" and take the first part (base URL)
      const cleanUrl = presignedUrl.split("?AWSAccessKeyId")[0];
  
      // Upload the file to S3 using the pre-signed URL
      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": contentType,
        },
        body: file,
      });
  
  
      if (!uploadResponse.ok) {
        console.error("Failed to upload file to S3");
        throw new Error("Failed to upload file.");
      }
  
  
      setPresignedUrl(cleanUrl);
      showSnackbar("File uploaded successfully!", "success");
    } catch (error) {
      console.error("Upload error:", error.message);
      showSnackbar("Error: The file format is not supported. Please check the file and try again.", "error");
    }
  };
  

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setFileName(file.name);
    await handleUpload(file);
  };
  
  
  

  // ✅ Set default values when the component mounts
useEffect(() => {
  const today = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD
  setCaptureDate(today);

  const nextYear = new Date();
  nextYear.setFullYear(nextYear.getFullYear() + 1);
  setExpiryDate(nextYear.toISOString().split("T")[0]);
}, []);


const handleSubmit = async (event, artifactId, closeSubRow) => {
  event.preventDefault();
  setUploading(true); // Start uploading state

  // ✅ Validate required fields before proceeding
  if (!captureDate || !expiryDate) {
    showSnackbar("Error: Capture date and expiry date are required. Please check the file and try again.", "error");
    setUploading(false);
    return;
  }
  if (captureDate > expiryDate) {
    showSnackbar("The expiry date should not be earlier than the captured date.", "error");
    setUploading(false);
    return;
  }

  if (!selectedArtifact || !selectedArtifact.artifact_name) {
    showSnackbar("Error: Artifact name is missing. Please select an artifact.", "error");
    setUploading(false);
    return;
  }

  if (!presignedUrl) {
    showSnackbar("Error: The file is missing. Please check the file and try again.", "error");
    setUploading(false);
    return;
  }

  // ✅ Construct request payload
  const requestBody = {
    sub_artifact_name: selectedArtifact.artifact_name, 
    artifact_path: presignedUrl, 
    date_of_capture: captureDate, 
    date_of_expiry: expiryDate, 
  };

  try {
    // ✅ Send the API request
    const response = await fetchData(
      `artifact/artifactupload/${artifactId}/`, // API endpoint including artifact ID
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRFToken": cookie.load("csrftoken"), // Include CSRF token for security
        },
        credentials: "include",
        body: JSON.parse(JSON.stringify(requestBody)), // Convert payload to JSON
      }
    );

    // ✅ Handle the response
    if (response) {
      showSnackbar("Artifact uploaded successfully!", "success");
      showSnackbar("Artifact moved to Org Directory.", "info");
      
      // ✅ Clear file input, presigned URL, and file name after successful submission
      setFileName("");
      setPresignedUrl("");
      fileInputRef.current.value = null;

      // Reset selected artifact state
      setSelectedArtifact(null);
      setS3filelink0(null);
      fetchMasterArtifactData(); 

      // ✅ Close the sub-row after successful upload
      closeSubRow();
    } else {
      showSnackbar("Upload failed. Please check the file and try again.", "error");
      closeSubRow();

    }
  } catch (error) {
    showSnackbar("An error occurred while uploading the artifact. Please try again later.", "error");
    closeSubRow();

  } finally {
    setUploading(false); // Stop the loading state
    closeSubRow();

  }
};








  // Fetch Metadata for Dropdowns (Runs Only Once)
  useEffect(() => {
    fetchData("artifact/artifactmasterlist/")
    .then((data) => {
      if (data) {
        const complianceMap = new Map(
          data.compliance_list.map((item) => [item.compliance_id, item.compliance_name])
        );
  
        setComplianceOptions(
          data.compliance_list.map((item) => ({
            id: item.compliance_id,
            name: item.compliance_name,
          }))
        );
  
        setProcessOptions(
          data.business_process_list.map((item) => ({
            id: item.business_process_id,
            name: item.business_process_name,
          }))
        );
  
        setStandardOptions(
          data.standard_list.map((item) => ({
            id: item.standard_id,
            name: ` ${item.standard_name} [ ${complianceMap.get(item.compliance) || "Unknown"} : ${item.standard_code} ]`,
          }))
        );
  
        setUserList(data.user_list || []); // Store user list
      }
    })
    .catch((error) => showSnackbar("An unexpected error occurred", "error"));
  
  }, []);

  // Debounce Search and Filter Changes
  useEffect(() => {
    const debouncedFetch = debounce(() => fetchMasterArtifactData(), 500);
    debouncedFetch();
    return () => debouncedFetch.cancel();
  }, [fetchMasterArtifactData]);

  // Pagination Handling
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages && newPage !== pagination.currentPage) {
      setPagination((prev) => ({ ...prev, currentPage: newPage }));
      fetchMasterArtifactData(newPage);
    }
  };

  const handleCaptureDateChange = (event) => {
    const newCaptureDate = event.target.value;
    const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  
    // Validate the selected date
    if (!newCaptureDate || isNaN(new Date(newCaptureDate).getTime())) {
      return;
    }
  
    // Ensure the selected date is not in the future
    if (newCaptureDate > today) {
      showSnackbar("Capture date cannot be in the future. Please select a valid date.", "error");
      return;
    }
  
    setCaptureDate(newCaptureDate);
  
    try {
      // Calculate the expiry date (1 year later)
      const expiryDateObj = new Date(newCaptureDate);
      expiryDateObj.setFullYear(expiryDateObj.getFullYear() + 1);
  
      // Ensure the expiry date is valid before setting it
      if (!isNaN(expiryDateObj.getTime())) {
        setExpiryDate(expiryDateObj.toISOString().split("T")[0]); // Format YYYY-MM-DD
      } else {
        showSnackbar("Error calculating expiry date. Please try again.", "error");
      }
    } catch (error) {
      showSnackbar("An unexpected error occurred while setting the expiry date.", "error");
    }
  };
  
  
  
  


  const handleAssignUser = async (artifactId, user) => {
    setIsLoading(true);
  
    const requestBody = {
      assigned_to: user.user,
    };
  
    try {
      await fetchData(`artifact/assignartifactmaster/${artifactId}/`, {
        method: "POST",
        body: requestBody,
      });

      // ✅ Use Snackbar correctly
      showSnackbar(`User ${user.profile_name} assigned successfully!`, "success");
  
      // ✅ Refresh Artifact Data
      fetchMasterArtifactData();
    } catch (error) {
  
      // ✅ Show error notification
      showSnackbar("Failed to assign user. Please try again!", "error");
    } finally {
      setIsLoading(false);
      showSnackbar("Artifact Moved to Org Directory", "info");
    }
  };


  const renderUploadSubRow = (row, closeSubRow) => {
    const handleClose = () => {
      setFileName("");
      setPresignedUrl("");
      fileInputRef.current.value = null;
      setSelectedArtifact(null);
      closeSubRow(); // Close the sub-row
    };
  
    return (
      
      <Box sx={{ padding: "15px", border: "1px solid #ccc", borderRadius: "8px", backgroundColor: "#f9f9f9" }}>
        {/* Header with Close Button */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "10px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>Upload Artifact Details</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
  
        {/* First Row: Artifact Name + File Upload */}
        <Box sx={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
          {/* Editable Artifact Name */}
          <TextField
            label="Artifact Name"
            fullWidth
            size="small"
            sx={{ width: "50%" }}
            value={selectedArtifact?.artifact_name || ""}
            onChange={(e) =>
              setSelectedArtifact((prev) => ({ ...prev, artifact_name: e.target.value }))
            }
          />
  
          {/* File Upload (Grouped TextField + Button) */}
          <Box sx={{ display: "flex", alignItems: "center", width: "50%" }}>
            <TextField
              label="Attach File"
              size="small"
              fullWidth
              value={fileName}
              onClick={handleClick} // Make the whole text field act like the button
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        minWidth: "40px",
                        padding: "5px",
                        backgroundColor: "#01c3c1",
                        "&:hover": { backgroundColor: "#00a7a5" },
                      }}
                    >
                      <UploadFileIcon fontSize="small" />
                      <input
                        type="file"
                        hidden
                        ref={fileInputRef}
                        onChange={handleFileChange} // Call function to generate presigned URL
                      />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
  
        {/* Second Row: Date of Capture + Date of Expiry */}
        <Box sx={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
          <TextField
            label="Date of Capture"
            type="date"
            fullWidth
            size="small"
            sx={{ width: "50%" }}
            value={captureDate}
            onChange={handleCaptureDateChange}
          />
          <TextField
            label="Date of Expiry"
            type="date"
            fullWidth
            size="small"
            sx={{ width: "50%" }}
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
          />
        </Box>
  
        {/* Submit Button */}
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}> 
          <CustomButton onClick={(e) => handleSubmit(e, row.artifact_master_id, closeSubRow)} label="Submit" buttonType="medium"  />
        </Box>
      </Box>
    );
  };
  
  // Table Columns
  const columns = [
    {
      title: "Artifact Name",
      key: "artifact_name",
      width: "60%",
      render: (row) => (
        <Box>
          {/* Artifact Name in Bold */}
          <Typography 
            sx={{ 
              fontWeight: "bold", 
              fontFamily: "'Glacial Indifference', sans-serif", 
              fontSize: "1rem" 
            }}
          >
            {row.artifact_name}
          </Typography>
  
          {/* Artifact Description in Grey */}
          <Typography 
            sx={{ 
              color: "#808080", 
              fontFamily: "'Glacial Indifference', sans-serif", 
              fontSize: "0.875rem" 
            }}
          >
            {row.artifact_description}
          </Typography>
        </Box>
      ),
    },   {
      title: "Assign User",
      key: "assign_user",
      width: "20%",
      render: (row) => (
        <AssignUserComponent
          selectedUser={row.assigned_to_profile_name || "Unassigned"} // Default to "Unassigned"
          profileImage={row.profile_image} // Use profile image if available
          availableUsers={userList} // User list from API
          onUserSelect={(user) => handleAssignUser(row.artifact_master_id, user)} // Pass API function
        />
      ),
    },
    {
      title: "Upload",
      key: "upload",
      width: "20%",

      render: (row, toggleSubRow) => (
        <CustomButton
        onClick={() => {
          setSelectedArtifact(row); // Set the selected artifact for editing
          toggleSubRow(row.artifact_master_id);
        }}
        label="Upload"
        buttonType="medium"
      />
      ),
   }
   
  ];

  return (
    
<Box sx={{ height: "calc(100vh - 150px)", overflowY: "auto", padding: "16px" }}>
<Box
  sx={{
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    justifyContent: "flex-start",
    alignItems: "center",
    mb: 2, // Added bottom margin
  }}
>        <SearchBox value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} labelEn="Search Artifacts" labelAr="بحث عن القطع الأثرية" />
        <MultiSelect label="Select Compliance" selectedValues={selectedCompliance} options={complianceOptions} onChange={(event, newValue) => setSelectedCompliance(newValue)} />
        <MultiSelect label="Select Process" selectedValues={selectedProcess} options={processOptions} onChange={(event, newValue) => setSelectedProcess(newValue)} width="350px" />
        <MultiSelect label="Select Standard" selectedValues={selectedStandard} options={standardOptions} onChange={(event, newValue) => setSelectedStandard(newValue)} width="450px" />
      </Box>
      <CustomTable
      columns={columns}
      data={artifactData}
      totalItems={pagination.totalItems}
      pageSize={pagination.pageSize}
      currentPage={pagination.currentPage}
      totalPages={pagination.totalPages}
      onPageChange={handlePageChange}
      isLoading={isLoading}
      invokeSubRow={renderUploadSubRow}
    />
    </Box>
  );
}

export default ArtifactListingPage;
