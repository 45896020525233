import cookie from "react-cookies";

const fetchData = async (endpoint, options = {}) => {
  try {
    const subdomain = window.location.host.includes(".")
      ? window.location.host.split(".")[0]
      : "default"; // Fallback subdomain if not found

    const backendURL = process.env.REACT_APP_BACKEND;
    if (!backendURL) {
      console.error("⚠️ REACT_APP_BACKEND is not defined.");
      throw new Error("Backend URL is missing.");
    }

    const language = cookie.load("Language") || "en"; // Default to English

    // Prevent duplicate subdomain addition
    let baseUrl = `${backendURL}${subdomain}/${language}/`;
    
    // Ensure `backendURL` does not already contain `subdomain`
    if (backendURL.includes(`/${subdomain}/`)) {
      baseUrl = `${backendURL}${language}/`; // Remove duplicate subdomain
    }

    const defaultHeaders = {
      "Content-Type": "application/json",
      "X-CSRFToken": cookie.load("csrftoken"),
    };

    const apiUrl = baseUrl + endpoint;

    const response = await fetch(apiUrl, {
      method: options.method || "GET",
      credentials: "include",
      headers: { ...defaultHeaders, ...options.headers },
      body: options.body ? JSON.stringify(options.body) : undefined,
    });

    const responseText = await response.text();

    if (!response.ok) {
      throw new Error(`API Error: ${response.status} - ${response.statusText} | Response: ${responseText}`);
    }

    return JSON.parse(responseText);
  } catch (error) {
    console.error("🚨 Error fetching data:", error.message);
    throw error;
  }
};

export default fetchData;
