import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import cookie from "react-cookies";

const SearchBox = ({ value, onChange, labelEn, labelAr }) => {
  return (
    <TextField
      variant="outlined"
      label={cookie.load("Language") === "ar" ? labelAr : labelEn}
      value={value}
      onChange={(e) => {
        if (e.target.value.length <= 40) {
          onChange(e);
        }
      }}
      sx={{
        width: "250px",
        minWidth: "200px",
        maxWidth: "100%",
        minHeight: "40px",
        "& .MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.85) !important", // Ensures label moves up properly
          backgroundColor: "#eeeeee", // Light gray background when shrunk
          padding: "0 4px", // Adds spacing to prevent overlap
          borderRadius: "4px", // Optional: smooth rounded corners
        },
      }}
      inputProps={{ maxLength: 40 }} // ✅ Ensures max length of 40 characters
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon sx={{ fontSize: "20px", color: "#808080" }} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBox;
