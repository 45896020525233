import { Button } from "@mui/material";
import PropTypes from "prop-types";

const CustomButton = ({ onClick, width = "auto", label, buttonType = "large", disabled = false }) => {
  const buttonStyles =
    buttonType === "large"
      ? {
          width: width || "200px",
          backgroundColor: disabled ? "#a0a0a0" : "#001b2b",
          display: "flex",
          justifyContent: "flex-end",
          cursor: disabled ? "not-allowed" : "pointer",
        }
      : buttonType === "medium"
      ? {
          width: width || "90px",
          backgroundColor: disabled ? "#a0a0a0" : "#01c3c1",
          display: "block",
          cursor: disabled ? "not-allowed" : "pointer",
        }
      : {
          width: width || "70px",
          backgroundColor: disabled ? "#a0a0a0" : "#01c3c1",
          display: "block",
          cursor: disabled ? "not-allowed" : "pointer",
        };

  return (
    <div style={buttonType === "large" ? { display: "flex", justifyContent: "flex-end" } : {}}>
      <Button
        onClick={disabled ? undefined : onClick}
        variant="contained"
        disabled={disabled}
        sx={{
          ...buttonStyles,
          fontFamily: "Glacial Indifference, sans-serif",
          color: "#fff",
          fontSize: "12px",
          marginTop: "1px",
          marginBottom: "10px",
          borderRadius: "4px",
          transition: "background-color 0.3s ease",
          "&:hover": {
            backgroundColor: disabled ? "#a0a0a0" : buttonType === "large" ? "#003d5c" : "#00a7a5",
          },
        }}
      >
        {label}
      </Button>
    </div>
  );
};

// Prop Validation
CustomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  width: PropTypes.string,
  label: PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf(["large", "medium", "small"]),
  disabled: PropTypes.bool, // ✅ Added prop validation for disabled
};

export default CustomButton;
