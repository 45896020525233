import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Drawer,
  Typography,
  TextField,
  Card,
  CardContent,
  Modal,
  Tabs,
  Tab,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  Chip,
  Skeleton,
  Alert,
  FormControlLabel,
  Tooltip,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import CloseIcon from "@mui/icons-material/Close";
import cookie from "react-cookies";
import S3 from "react-aws-s3";
import useWindowDimensions from "../../useWindowDimensions";
import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";
import { decryption } from "../../auth/utilities";
import RiskRegister from "./RiskRegister";
import "../../ButtonStyles/AppStyles.css";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import "./RiskRequirements.css";
import CustomTable from "../../ReusableComponents/CustomTable";
import { UserContext } from "../../auth/UserContext";

const style = {
  position: "absolute",
  color: "#001b2b",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RiskRequirements(inactivedata) {
  const { RiskAssessmentId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const Inactivedata = inactivedata.inactivedata;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const subdomain = window.location.host.split(".")[0];
  const [requirements, setRequirements] = useState([]);
  const [riskAssessmentDetail, setRiskAssessmentDetail] = useState(null);
  const [companyName, setCompanyName] = useState(""); // State to store company name
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRequirementId, setSelectedRequirementId] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState(null);
  const [subChecks, setSubChecks] = useState([]);
  const [uploadFormOpen, setUploadFormOpen] = useState(false);
  const [artifactFormVisible, setArtifactFormVisible] = useState(null);

  const [acknowledgmentChecked, setAcknowledgmentChecked] = useState(false);
  const { sessionUser, updateUser } = useContext(UserContext);
  

  const [formValues, setFormValues] = useState({
    sub_check_name: "",
    artifact_path: "",
    comment: "",
    date_of_expiry: "",
  });
  const [uploading, setUploading] = useState(false);
  const [fileWarning, setFileWarning] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [artifactLink, setArtifactLink] = useState("");
  const fileInput = useRef(null);
  const { height, width } = useWindowDimensions();
  const [selectedSubCheck, setSelectedSubCheck] = useState(null);

  const [value, setValue] = useState(0);
  const [statusFilter, setStatusFilter] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [checksData, setChecksData] = useState({});
  const [artifactList, setArtifactList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState([]);
  const [selectedRiskStatus, setSelectedRiskStatus] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]); 
  const [selectedSubCheckId, setSelectedSubCheckId] = useState(null);
  const [expandedSubCheck, setExpandedSubCheck] = useState(null);
  const [artifactLoading, setArtifactLoading] = useState(false);
  const [checksDrawerOpen, setChecksDrawerOpen] = React.useState(false);
  const [selectedChecks, setSelectedChecks] = React.useState([]);
  const [selectedRequirement, setSelectedRequirement] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [checkLoading, setCheckLoading] = useState(false);
  const [subChecksLoading, setSubchecksLoading] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [uploadedFileUrl, setUploadedFileUrl] = useState(""); 

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleCancel = () => {
    setFormValues({
      sub_check_name: "",
      artifact_path: "",
      comment: "",
      date_of_expiry: "",
    });
    setUploadFormOpen(false);
    setAcknowledgmentChecked(false);
    setUploadError(""); 
    setUploading(false); 
    setUploadedFileUrl(""); 
    resetFormState();
  };

  const handleRequirementClick = async (requirement) => {
    // console.log("Requirement clicked:", requirement); 
    setSelectedRequirement(requirement);
    setChecksDrawerOpen(true);
  
    if (!checksData[requirement.requirement_id]) {
      await fetchChecks(RiskAssessmentId, requirement.requirement_id);
    } else {
      setSelectedChecks(checksData[requirement.requirement_id]);
    }
  };

  useEffect(() => {
      if (selectedRequirement && !checksData[selectedRequirement.requirement_id]) {
          fetchChecks(RiskAssessmentId, selectedRequirement.requirement_id);
      }
  }, [selectedRequirement]);

  const containerRef = useRef(null);

  const columns = [
    {
      title: "Requirement Name",
      key: "requirement_name",
      render: (row) => (
        <Box 
          className="req-box-3"
          onClick={() => handleRequirementClick(row)} 
        >
          <Typography className="req-typography-1">
            {row.requirement_name}
          </Typography>
          <Typography className="req-typography-2">
            {row.requirement_description.replace("{Company Name}", companyName)}
          </Typography>
        </Box>
      ),
    },
    {
      title: "Category",
      key: "category_name",
      render: (row) => (
        <Box className="req-box-3"
          onClick={() => handleRequirementClick(row)}
        >
         <Typography className="req-typography-3">
           {row.category_name} 
         </Typography>
        </Box>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (row) => (
        <Box className="req-box-4"
         onClick={() => handleRequirementClick(row)}
          style={{
            backgroundColor: row.status === "pass" ? "rgba(0, 128, 0, 0.5)" : "rgba(255, 0, 0, 0.5)",
          }}
        >
          <Typography className="req-typography-4">
            {row.status === "pass" ? "Pass" : "Failed"}
          </Typography>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    fetchRiskAssessment();
  }, [RiskAssessmentId]);

  const fetchRiskAssessment = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${
          cookie.load("Language") || "en"
        }/riskassessment/riskassessment/${RiskAssessmentId}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setRiskAssessmentDetail(data || []);
      setCompanyName(data.company_name); // Set the company name from API response
      fetchRequirements(data.company_name); // Fetch requirements after getting company name
    } catch (error) {
      console.error("Failed to fetch requirements", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRequirements = async ({
    companyName = "",
    requirementName = "",
    category = selectedCategoryFilter,
    riskStatus = "",
    pageSize = 10,
    currentPage = 1,
    isFilter = false, // Flag to check if the call is for filtering
  } = {}) => {
    setLoading(true);
    setError(null);
  
    try {
      // Construct the base URL
      const baseURL = `${process.env.REACT_APP_BACKEND}${
        window.location.host.split(".")[0]
      }/${
        cookie.load("Language") || "en"
      }/artifact/splitrequirement/${RiskAssessmentId}/?module=risk`;
  
      const url = new URL(baseURL);
  
      // Append filter parameters if provided
      if (requirementName) url.searchParams.append("requirement_name", requirementName);
      if (category) url.searchParams.append("category", category);
      if (Array.isArray(riskStatus) && riskStatus.length > 0) {
        url.searchParams.append("risk_status", riskStatus.join(","));
      } else if (typeof riskStatus === "string" && riskStatus) {
        url.searchParams.append("risk_status", riskStatus);
      }
      url.searchParams.append("page_size", pageSize);
      url.searchParams.append("page", currentPage);
  
      // Fetch data from API
      const response = await fetch(url, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data, status: ${response.status}`);
      }
  
      const data = await response.json();
      const { results = [] } = data;
  
      // Replace {Company Name} only when fetching all requirements
      const updatedRequirements = results.map((requirement) => ({
        ...requirement,
        requirement_description: isFilter
          ? requirement.requirement_description // No change for filter
          : requirement.requirement_description.replace("{Company Name}", companyName),
      }));
  
      // Extract categories (only on fetching, not filtering)
      if (!isFilter) {
        const categoryList = data.category_list || [];
        const extractedCategories = categoryList.map((cat) => ({
          id: cat.category__category_id,
          name: cat.category__category_name,
        }));
        setCategories(extractedCategories);
      }
  
      // Set the requirements
      setRequirements(updatedRequirements);
      setTotalPages(data.total_pages || 1);
      setTotalRecords(data.total_items || 0);
      setPageSize(data.page_size || 10);
    } catch (error) {
      setError("Failed to fetch data. Please try again.");
      console.error("Error fetching/filtering requirements:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchChecks = async (RiskAssessmentId, requirementId) => {
    setCheckLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${window.location.host.split(".")[0]}/${
          cookie.load("Language") || "en"
        }/artifact/splitchecklist/${RiskAssessmentId}/${requirementId}/?module=risk`,
        { credentials: "include" }
      );
  
      const data = await response.json();
      if (Array.isArray(data)) {
        setSelectedChecks(data); 
      }
    } catch (error) {
      console.error("Failed to fetch checks", error);
    } finally {
      setCheckLoading(false);
    }
  };

  const fetchSubChecks = async (check_id) => {
    try {
      setSubchecksLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/splitsubchecklist/${check_id}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setSubChecks(data || []);
    } catch (error) {
      console.error("Failed to fetch subchecks", error);
    } finally {
      setSubchecksLoading(false);
    }
  };

  const fetchArtifactList = async (subCheckId) => {
    setArtifactLoading(true);
    setArtifactList([]);
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("language") || "en"}/artifact/splitartifactlist/${subCheckId}`,
        { credentials: "include" }
      );
      const data = await response.json();
      setArtifactList(data);
    } catch (error) {
      console.error("Failed to fetch Artifact", error);
    } finally {
      setArtifactLoading(false);
    }
  };


  const handlePageChange = async (newPage) => {
    if (newPage >= 1 && newPage <= totalPages && newPage !== currentPage) {
      setCurrentPage(newPage);
      await fetchRequirements({
        requirementName: searchFilter,
        category: selectedCategoryFilter,
        riskStatus: selectedRiskStatus,
        pageSize,
        currentPage: newPage,
      });
    }
  };

  const handleOpenDialog = (id) => {
    setSelectedRequirementId(id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };


  const resetFormState = () => {
    setFormValues({
      comment: "",
      date_of_expiry: "",
      artifact_path: "",
    });
    setUploading(false);
    setUploadError("");
    setUploadedFileUrl("");
    setArtifactFormVisible(null); 
  };

  const handleSubCheckChange = (subCheckId) => (event, isExpanded) => {
    setExpandedSubCheck(isExpanded ? subCheckId : null);
    if (isExpanded) {
      fetchArtifactList(subCheckId);
    }
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    setCurrentPage(1);
  fetchRequirements({
      requirementName: searchValue,
      category: selectedCategoryFilter,
      riskStatus: selectedRiskStatus,
      pageSize,
      currentPage: 1,
    });
  };

  const handleCategoryFilterChange = (event) => {
    const selectedIds = event.target.value;
    setSelectedCategoryFilter(selectedIds);

    setCurrentPage(1);

    fetchRequirements({
      requirementName: searchFilter,
      category: selectedIds,
      riskStatus: selectedRiskStatus,
      pageSize,
      currentPage: 1,
    });
  };

  const handleRiskStatusChange = (event) => {
    const status = event.target.value; 
    setSelectedRiskStatus(status);

    setCurrentPage(1);

    
    fetchRequirements({
      requirementName: searchFilter,
      category: selectedCategoryFilter,
      riskStatus: status, 
      pageSize,
      currentPage: 1,
    });
  };

  useEffect(() => {
    if (!artifactFormVisible) {
      setFormValues({ comment: "", date_of_expiry: "" });
    }
  }, [artifactFormVisible]);

  const handleDrawerOpen = (check) => {
    setSelectedCheck(check);
    fetchSubChecks(check.check_id);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSubChecks([]);
    setUploadFormOpen(false);
    setSelectedSubCheck(null);
    setArtifactFormVisible(null); // Reset artifact form visibility state
    setExpandedSubCheck(null);
  };

  const handleUploadButtonClick = () => {
    setUploadFormOpen(true);
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileUpload = async (file, artifactId) => {
    setUploading(true);
    setUploadError("");
    setUploadedFileUrl("");
    if (!file) {
        setSnackbarMessage("No file selected. Please select a file to upload.");
        setSnackbarType("error");
        setOpenSnackbar(true);
        setUploading(false);
        return;
    }

    const newFileName = file.name.replace(/[^a-zA-Z0-9.]/g, "_");
    const contentType = file.type || "application/octet-stream";

    const apiUrl = 
        process.env.REACT_APP_BACKEND + 
        subdomain + 
        "/" + 
        (cookie.load("Language") || "en") + 
        "/artifact/generate-presigned-urls/";

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": cookie.load("csrftoken"),
            },
            credentials: "include",
            body: JSON.stringify({
                artifact_id: artifactId,
                files: [
                    {
                        file_path: newFileName,
                        content_type: contentType,
                    },
                ],
            }),
        });

        if (!response.ok) throw new Error("Error generating pre-signed URL");

        const data = await response.json();
        const presignedUrl = data.presigned_urls[0]?.presigned_url;
        if (!presignedUrl) throw new Error("Failed to get pre-signed URL");

        const uploadResponse = await fetch(presignedUrl, {
            method: "PUT",
            headers: {
                "Content-Type": contentType,
            },
            body: file,
        });

        if (!uploadResponse.ok) throw new Error("Failed to upload file to S3");

        const uploadedFileUrl = presignedUrl.split("?")[0];
        setUploadedFileUrl(uploadedFileUrl);
        setFormValues({
          ...formValues,
          artifact_path: uploadedFileUrl,
        });
        setSnackbarMessage("File uploaded successfully!");
        setSnackbarType("success");
        setOpenSnackbar(true);
    } catch (error) {
        setSnackbarMessage(error.message || "An unexpected error occurred.");
        setSnackbarType("error");
        setOpenSnackbar(true);
    } finally {
        setUploading(false);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setUploadError(""); 
    setUploading(false); 
    setUploadedFileUrl(""); 

    // Format date_of_capture to 'YYYY-MM-DD'
    const dateOfCapture = new Date().toISOString().split("T")[0];

    const postData = {
      ...formValues,
      is_document_added: formValues.artifact_path ? true : false,
      date_of_capture: dateOfCapture,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/addsubcheck/${
          selectedCheck.check_id
        }/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookie.load("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify(postData),
        }
      );

      if (response.ok) {
        fetchSubChecks(selectedCheck.check_id); // Refresh sub-checks list
        setFormValues({
          sub_check_name: "",
          artifact_path: "",
          comment: "",
          date_of_expiry: "",
        });
        setAcknowledgmentChecked(false);

        // Ensure checks drawer does NOT open
        setUploadFormOpen(false); // Keep the sub-checks drawer open
      } else {
        console.error("Failed to upload sub-check");
      }
    } catch (error) {
      console.error("Failed to upload sub-check", error);
    }
  };


  const handleAddDocument = async (event) => {
    event.preventDefault();

    // Format date_of_capture to 'YYYY-MM-DD'
    const dateOfCapture = new Date().toISOString().split("T")[0];

    const postData = {
      artifact_path: formValues.artifact_path,
      comment: formValues.comment,
      date_of_capture: dateOfCapture,
      date_of_expiry: formValues.date_of_expiry,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND}${
          window.location.host.split(".")[0]
        }/${cookie.load("Language") || "en"}/artifact/adddocument/${
          selectedSubCheck.sub_check_id
        }/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": cookie.load("csrftoken"),
          },
          credentials: "include",
          body: JSON.stringify(postData),
        }
      );
      if (response.ok) {
        fetchSubChecks(selectedCheck.check_id); // Refresh sub-checks list
        const newArtifact = await response.json(); 
        setArtifactList((prevList) => [...prevList, newArtifact]);
        setSelectedSubCheck(null);
        fetchRequirements();
        resetFormState();
        setArtifactFormVisible(null); // Close the form after successful submission
        setFormValues({
          sub_check_name: "",
          artifact_path: "",
          comment: "",
          date_of_expiry: "",
        });
      } else {
        console.error("Failed to upload document");
      }
    } catch (error) {
      console.error("Failed to upload document", error);
    }
  };

  const handleOpenArtifactModal = (link) => {
    setArtifactLink(link);
    setOpenModal(true);
  };

  const handleCloseArtifactModal = () => {
    setOpenModal(false);
    setArtifactLink("");
    setIsFullscreen(false);
  };

  const handleToggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleSubmit = (event) => {
    setButtonLoading(true);
    event.preventDefault();

    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": cookie.load("csrftoken"),
      },
      credentials: "include",
      body: JSON.stringify({
        created_by: sessionUser.userId,
      }),
    };
    fetch(
      `${process.env.REACT_APP_BACKEND}${subdomain}/${
        cookie.load("Language") || "en"
      }/riskassessment/riskregister/${
        riskAssessmentDetail.risk_assessment_id
      }/`,
      requestOption
    ).then(
      (res) => {
        if (res.status === 201) {
          fetchRiskAssessment();
          setOpen(false);
          setButtonLoading(false);
          setValue(1);
        } else {
          console.error("Failed to create risk register");
        }
      },
      (error) => {
        // console.log("error", error);
      }
    );
  };

  const renderFileViewer = (link) => {
    const mainPart = link.split("?AWSAccessKeyId")[0];

    const fileExtension = mainPart.split(".").pop().toLowerCase();
    if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={link}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["doc", "docx", "ppt", "pptx", "txt"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://docs.google.com/gview?url=${encodeURIComponent(link)}&embedded=true`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    
    if (["xls", "xlsx"].includes(fileExtension)) {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(link)}`}
          title="Artifact"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      );
    }
    if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
      return (
        <img
          src={link}
          alt="Artifact"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
            flexGrow: 1,
          }}
        />
      );
    }
    return <Typography variant="h6">Unsupported file type</Typography>;
  };

  const filteredRequirements = requirements
    .filter((requirement) =>
      requirement.requirement_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    .filter(
      (requirement) =>
        (statusFilter.length === 0 ||
          statusFilter.includes(requirement.status)) &&
        (categoryFilter.length === 0 ||
          categoryFilter.includes(requirement.category_name))
    );

  const minExpiryDate = new Date();
  minExpiryDate.setDate(minExpiryDate.getDate() + 1); // Set to tomorrow to ensure it's not today or earlier

  const uniqueCategories = [
    ...new Set(requirements.map((req) => req.category_name)),
  ];

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {cookie.load("Language") === "ar" ? "ملحوظة⚠️" : "Note ⚠️"}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {cookie.load("Language") === "ar"
              ? "بمجرد تقديمه، لا يمكن تعديل تقييمك. يرجى التأكد من الإجابة على كل سؤال. سيتم نقل البيانات المفقودة إلى الفجوة. إذا كان موافق انقر فوق إرسال"
              : "Once the Risk Register is created, all the checks that failed will be documented as risks. Please ensure that all information is up-to-date before proceeding. "}
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "30px",
              float: "right",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{ background: "#eeeeee", color: "#808080" }}
              onClick={handleClose}
            >
              {cookie.load("Language") === "ar" ? "يلغي" : "Cancel"}
            </Button>
            <LoadingButton
              style={{
                background: !buttonLoading ? "#00324e" : "#808080",
                color: "#ffff",
              }}
              onClick={handleSubmit}
              loading={buttonLoading}
              variant="contained"
            >
              {cookie.load("Language") === "ar" ? "يُقدِّم" : "Create"}
            </LoadingButton>
          </div>
        </Box>
      </Modal>

      <Box
        sx={{
          marginTop: "-15px",
          height: "100%",
          width: Inactivedata ? width - 250 : width - 55,
          zIndex: 10,
          overflow: "hidden",
          position: "fixed",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            background: "#ffffff",
            zIndex: 10,
          }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {!["auditee"].includes(sessionUser.userRole) && (
              <Tab
                value={0}
                style={{
                  fontFamily: "'Glacial Indifference'",
                  fontWeight: "bold",
                }}
                label="Requirements"
                {...a11yProps(0)}
              />
            )}
            {riskAssessmentDetail?.is_submitted ? (
              <Tab
                value={1}
                style={{
                  fontFamily: "'Glacial Indifference'",
                  fontWeight: "bold",
                }}
                label="Risk Register"
                {...a11yProps(1)}
              />
            ) : (
              <Tooltip
                title="Risk Register is not Created yet!"
                disableInteractive
              >
                <span>
                  <Tab
                    value={1}
                    style={{
                      fontFamily: "'Glacial Indifference'",
                      fontWeight: "bold",
                      pointerEvents: riskAssessmentDetail?.is_submitted
                        ? "auto"
                        : "none",
                      color: "#808080",
                      opacity: riskAssessmentDetail?.is_submitted ? 1 : 0.5,
                    }}
                    label="Risk Register"
                    {...a11yProps(1)}
                  />
                </span>
              </Tooltip>
            )}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
        <div className="req-main-div"
          style={{
            height: height - 115,
          }}
        >
          <div style={{ flexShrink: 0 }}>
            <Box className="req-box-1">
              <TextField
                fullWidth
                label="Search Requirements"
                variant="outlined"
                value={searchFilter}
                onChange={handleSearch}
              />
              <FormControl fullWidth variant="outlined">
                <InputLabel>Status</InputLabel>
                  <Select
                    value={selectedRiskStatus}
                    onChange={handleRiskStatusChange}
                    label="Status"
                  >
                    <MenuItem className="req-menu-item" value=""><em>All</em></MenuItem>
                    <MenuItem value="pass" className="req-menu-item">Pass</MenuItem>
                    <MenuItem value="fail" className="req-menu-item">Failed</MenuItem>
                  </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel className="req-menu-item">Category</InputLabel>
                  <Select
                    value={selectedCategoryFilter}
                    onChange={handleCategoryFilterChange}
                    label="Category"
                  >
                    <MenuItem className="req-menu-item" value=""><em>All</em></MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category.id} value={category.id} className="req-menu-item">
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
              </FormControl>
              <Box sx={{ flexShrink: 0 }}>
                <Button
                  onClick={
                    riskAssessmentDetail
                      ? !riskAssessmentDetail.is_submitted
                        ? handleOpen
                        : null
                      : null
                  }
                  disabled={riskAssessmentDetail?.is_submitted}
                  variant="contained"
                  sx={{
                    color: "#ffffff",
                    background: "#001b2b",
                    zIndex: 11,
                    fontFamily: "'Glacial Indifference', sans-serif",
                  }}
                  color="primary"
                  aria-label="add"
                >
                  {riskAssessmentDetail?.is_submitted
                    ? "Risk Register Created"
                    : "Create Risk Register"}
                </Button>
              </Box>
            </Box>
          </div>

          <div style={{ flexGrow: 1 }}>
            {loading ? (
              <Box 
                className="req-box-2">
                <Skeleton
                  variant="rectangular"
                  height={50}
                  width="20%"
                  sx={{ marginBottom: 5 }}
                />
                <Skeleton style={{ height: "100px" }} animation={false} />
                <Skeleton style={{ height: "100px" }} />
                <Skeleton style={{ height: "100px" }} animation="wave" />
                <Skeleton style={{ height: "100px" }} animation={false} />
                <Skeleton style={{ height: "100px" }} />
                <Skeleton style={{ height: "100px" }} animation="wave" />
              </Box>
            ) : (
              <div>    
                <CustomTable 
                  columns={columns} 
                  data={filteredRequirements} 
                  totalItems={totalRecords} 
                  pageSize={pageSize} 
                  currentPage={currentPage} 
                  totalPages={totalPages} 
                  onPageChange={handlePageChange} 
                  // isLoading={isLoading}      
                />
                <Drawer
                  anchor="right"
                  open={checksDrawerOpen}
                  onClose={() => setChecksDrawerOpen(false)}
                  sx={{
                    "& .MuiDrawer-paper": {
                      width: "40%",
                      padding: "24px",
                      backgroundColor: "#eeeeee", 
                      borderTopLeftRadius: "2px",
                      borderBottomLeftRadius: "2px",
                    },
                  }}
                > 
                  <Box>
                    <IconButton  
                      className="req-iconbutton-1"
                      onClick={() => setChecksDrawerOpen(false)} 
                    >
                      <CloseIcon />
                    </IconButton>
                    <Typography 
                      className="req-typography-5"
                      variant="h6" 
                    >
                      Checks
                    </Typography>
                    {checkLoading ? (
                      <Box className="req-box-7">
                        <CircularProgress />
                      </Box>
                     ) : selectedChecks && selectedChecks.length > 0 ? (
                        selectedChecks.map((check) => (
                        <Box 
                          className="req-box-5"
                          key={check.check_id} 
                          onClick={() => handleDrawerOpen(check)}
                        >
                          <Box className="req-box-6">
                            <Box>
                              <Typography 
                                className="req-typography-6"
                              >
                                {check.check_name}
                              </Typography>
                            </Box>
                            <Box sx={{ flexShrink: "0", marginLeft: "12px" }}>
                            <OpenInNewIcon 
                              className="req-iconbutton-2"
                            />
                            </Box>
                          </Box>
                        </Box>
                      ))
                    ) : (
                      <Typography className="req-typography-7">No checks found.</Typography>
                    )}
                  </Box>
                </Drawer>
              </div>
             )}
            </div>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={handleDrawerClose}
              sx={{
                "& .MuiDrawer-paper": {
                  width: "40%",
                  padding: "20px",
                  backgroundColor: "#eeeeee"
                },
              }}
            >
              {selectedCheck && (
                <Box>
                 <Box display="flex" alignItems="center" mb={2}>
                    <IconButton 
                      onClick={handleDrawerClose} 
                      className="req-iconbutton-3"
                    >
                      <CloseIcon />
                    </IconButton>

                    <Typography 
                      variant="h6" 
                      className="req-typography-13"
                    >
                      {selectedCheck.check_name}
                    </Typography>
                  </Box>
                  <Typography variant="body1" gutterBottom className="req-menu-item">
                    {selectedCheck.check_description}
                  </Typography>
                  <Box display="flex" justifyContent="flex-end" mb={2}>
                    <Tooltip title={riskAssessmentDetail?.is_submitted ? "Upload disabled" : ""} arrow>
                      <span>
                        <Button
                          variant="contained"
                          disabled={riskAssessmentDetail?.is_submitted}
                          onClick={handleUploadButtonClick}
                          sx={{
                            marginBottom: "10px",
                            marginRight: "10px",
                            fontFamily: "'Glacial Indifference', sans-serif",
                            backgroundColor: riskAssessmentDetail?.is_submitted ? "#d6d6d6" : "#001b2b",
                            color: riskAssessmentDetail?.is_submitted ? "#666" : "white",
                            "&:disabled": {
                              backgroundColor: "#d6d6d6",
                              color: "#666",
                              cursor: "not-allowed",
                            },
                          }}
                        >
                          Upload
                        </Button>
                      </span>
                    </Tooltip>
                  </Box>

                <Modal
                  open={uploadFormOpen}
                  onClose={() => {
                    setUploadFormOpen(false);
                    setFormValues({
                      sub_check_name: "",
                      comment: "",
                      date_of_expiry: "",
                    });
                    setAcknowledgmentChecked(false);
                    setUploadError(""); 
                    setUploading(false); 
                    setUploadedFileUrl(""); 
                  }}
                  aria-labelledby="upload-form-title"
                  aria-describedby="upload-form-description"
                >
                    <Box
                      component="form"
                      onSubmit={handleFormSubmit}
                      className="req-popup-1"
                      sx={{ mt: 2 }}
                    >
                      <Typography className="req-typography-8" sx={{ color: "black" }}>
  Upload Artifact
</Typography>
          
                      <TextField
                        name="sub_check_name"
                        label="Artifact Name"
                        variant="outlined"
                        fullWidth
                        required
                        value={formValues.sub_check_name}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      {fileWarning && (
                        <Alert severity="warning" sx={{ mb: 2 }}>
                          {fileWarning}
                        </Alert>
                      )}
                      <TextField
                        type="file"
                        inputRef={fileInput}
                        fullWidth
                        required
                        onChange={(e) => handleFileUpload(e.target.files[0])}
                        sx={{ mb: 0.5 }}
                      />
                      <Typography
                        className="req-typography-12"
                        sx={{color: uploading ? "#FFC000" : uploadError ? "red" : "green"}}>
                        {uploading
                          ? "Uploading..."
                          : uploadError
                          ? uploadError
                          : uploadedFileUrl
                          ? "File uploaded"
                          : ""}
                      </Typography>
                      <TextField
                        required
                        name="comment"
                        label="Comment"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        value={formValues.comment}
                        onChange={handleFormChange}
                        className="req-textfield-comment"
                        sx={{ mb: 2 }}
                      />
                      <TextField
                        name="date_of_expiry"
                        label="Date of Expiry"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          min: minExpiryDate.toISOString().split("T")[0],
                        }}
                        fullWidth
                        required
                        value={formValues.date_of_expiry}
                        onChange={handleFormChange}
                        sx={{ mb: 2 }}
                      />
                      <FormControlLabel
                        className="req-formcontrol-1"
                        control={
                          <Checkbox
                            checked={acknowledgmentChecked}
                            onChange={(e) =>
                              setAcknowledgmentChecked(e.target.checked)
                            }
                            name="acknowledgment"
                            color="primary"
                          />
                        }
                        label={
                          <Typography
                            className="req-typography-10" sx={{ color: "black" }}
                          >
                            I acknowledge that the evidence uploaded is within the 1-year range
                            and it is from my own organization.
                          </Typography>
                        }
                      />
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          onClick={handleCancel}
                          variant="contained"
                          className="req-cancel-button-2"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          className="req-font-1"
                          disabled={uploading || !acknowledgmentChecked}
                          style={{
                            background:
                              uploading || !acknowledgmentChecked
                                ? "#aaaaaa"
                                : "#001b2b",
                            color: "#ffffff",
                          }}
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                </Modal>
                {subChecksLoading ? (
                 <Box className="req-box-7">
                    <CircularProgress />
                  </Box>
                ) : subChecks.length > 0 ? (
                  subChecks.map((subCheck, index) => (
                      <Accordion
                        key={subCheck.sub_check_id}
                        expanded={expandedSubCheck === subCheck.sub_check_id}
                        onChange={handleSubCheckChange(subCheck.sub_check_id)}
                        className="req-accordion-container"
                      >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} className="req-accordion-summary">
                        <Typography 
                          className="subcheck-title" 
                          style={{ maxWidth: riskAssessmentDetail?.is_submitted ? "100%" : "calc(100% - 120px)" }}
                        >
                          {`Sub Check ${index + 1}: ${subCheck.sub_check_name}`}
                        </Typography>
                        {expandedSubCheck === subCheck.sub_check_id && (
                         <div className="req-div-2">
                         <Tooltip title={riskAssessmentDetail?.is_submitted ? "Disabled" : ""} arrow placement="top">
                          <span>
                           <Button
                             variant="contained"
                             disabled={riskAssessmentDetail?.is_submitted}
                             onClick={(event) => {
                               event.stopPropagation(); 
                               setSelectedSubCheck(subCheck);
                               setArtifactFormVisible(subCheck.sub_check_id); 
                             }}
                             style={{
                               backgroundColor: riskAssessmentDetail?.is_submitted ? "#d6d6d6" : "#001b2b",
                               color: riskAssessmentDetail?.is_submitted ? "#666" : "white",
                               fontSize: "14px",
                               padding: "6px 16px",
                               borderRadius: "4px",
                               fontFamily: "'Glacial Indifference', sans-serif",
                               fontWeight: "500",
                               cursor: riskAssessmentDetail?.is_submitted ? "not-allowed" : "pointer",
                               textTransform: "none",
                             }}
                           >
                             Add Artifact
                           </Button>
                           </span>
                         </Tooltip>
                       </div>
                     )}
                      </AccordionSummary>
                      {expandedSubCheck === subCheck.sub_check_id && (
                        <AccordionDetails className="req-accordion-details">
                        {artifactLoading ? (
                          <Typography className="loading-text">Loading artifacts...</Typography>
                        ) : artifactList.length ? (
                          <div className="artifact-list-container">
                          {artifactList.map((artifact, index) => (
                            <div key={artifact.artifact_id} className="artifact-item">
                              <div className="artifact-header">
                                <div className="artifact-info">
                                <InsertDriveFileIcon className="file-icon" />
                                  <a
                                    onClick={() => handleOpenArtifactModal(artifact.artifact_uri)}
                                    className="artifact-link"
                                  >
                                    Document <OpenInNewIcon className="open-icon" />
                                  </a>
                                </div>
                              </div>
                              <div className="artifact-meta">
                                <div>
                                <label className="artifact-label">Date of Capture</label>
                                  <span className="artifact-value">
                                    {new Date(artifact.date_of_capture).toLocaleDateString() || "N/A"}
                                  </span>
                                </div>
                                <div>
                                <label className="artifact-label">Date of Expiry</label>
                                  <span className="artifact-value">
                                    {new Date(artifact.date_of_expiry).toLocaleDateString() || "N/A"}
                                  </span>
                                </div>
                                <div className="artifact-comment">
                                <label className="artifact-label">Comment</label>
                                  <p className="artifact-value italic-text">
                                    {artifact.comment || "No comment provided"}
                                  </p>
                                </div>
                              </div>
                              {index < artifactList.length - 1 && <hr className="req-divider" />}
                            </div>
                          ))}
                        </div>
                        ) : (
                          <Typography className="no-artifact-text">No artifacts found for this sub-check</Typography>
                        )}

                       <Modal
                          open={artifactFormVisible === subCheck.sub_check_id}
                          onClose={resetFormState}
                          aria-labelledby="upload-form-title"
                          aria-describedby="upload-form-description"
                        >
                          <div className="req-popup-1">
                            <Typography className="req-typography-11"> Add Artifact </Typography>
                            {fileWarning && (
                              <Alert severity="warning" sx={{ mb: 2 }}>
                                {fileWarning}
                              </Alert>
                            )}
                            <Box component="form" onSubmit={handleAddDocument}>
                            <TextField
                              type="file"
                              inputRef={fileInput}
                              fullWidth
                              required
                              onChange={(e) =>
                                handleFileUpload(e.target.files[0])
                              }
                              sx={{ mb: 0.5 }}
                            />
                            <Typography
                              className="req-typography-12"
                              sx={{color: uploading ? "#FFC000" : uploadError ? "red" : "green"}}>
                              {uploading
                                ? "Uploading..."
                                : uploadError
                                ? uploadError
                                : uploadedFileUrl
                                ? "File uploaded"
                                : ""}
                            </Typography>
                            <TextField
                              required
                              name="comment"
                              label="Comment"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={3}
                              value={formValues.comment}
                              className="req-textfield-comment"
                              onChange={handleFormChange}
                              sx={{ mb: 2 }}
                            />
                            <TextField
                              name="date_of_expiry"
                              label="Date of Expiry"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              inputProps={{
                                min: minExpiryDate.toISOString().split("T")[0],
                              }}
                              fullWidth
                              required
                              value={formValues.date_of_expiry}
                              onChange={handleFormChange}
                              sx={{ mb: 2 }}
                            />
                            <Box display="flex" justifyContent="flex-end">
                              <Button
                                onClick={handleCancel} // Close the form on cancel
                                className="req-cancel-button-1"
                              >
                                Cancel
                              </Button>
                              <Button
                                variant="contained"
                                type="submit"
                                disabled={uploading}
                                className="req-submit-button-1"
                              >
                                Submit
                              </Button>
                            </Box>
                          </Box>
                        </div>
                      </Modal>
                      </AccordionDetails>
                     )}
                   </Accordion>
                    ))
                  ) : (
                    <Typography className="req-typography-9">No subchecks found.</Typography>
                  )}
                </Box>
              )}
            </Drawer>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div style={{ width: "100%", height: "100%" }}>
            {/* <RiskRegister riskAssessmentId={RiskAssessmentId} /> */}
            <RiskRegister riskAssessmentId={RiskAssessmentId}/>
          </div>
        </TabPanel>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseArtifactModal}
        aria-labelledby="artifact-modal-title"
        aria-describedby="artifact-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "relative",
            width: isFullscreen ? "100%" : "80%",
            height: isFullscreen ? "100%" : "80%",
            maxWidth: "100vw",
            maxHeight: "100vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 3,
            p: 4,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <IconButton
            onClick={handleCloseArtifactModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            onClick={handleToggleFullscreen}
            sx={{ position: "absolute", top: 10, right: 50 }}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
          <Typography id="artifact-modal-title" variant="h6" component="h2">
            Artifact Viewer
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              mt: 2,
              overflow: "auto",
              flexGrow: 1,
            }}
          >
            {renderFileViewer(artifactLink)}
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default RiskRequirements;