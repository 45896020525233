import React, { useState, useEffect  } from 'react';
import { Drawer, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import fetchData from "../../api/apiService.js";


const RequirementDrawer = ({drawerOpen , handleDrawerClose, policy_document_id}) => {
const [documentUrl, setDocumentUrl] = useState(null);

useEffect(() => {
  if (drawerOpen && policy_document_id) {
    fetchPdfUrl(policy_document_id);
  }
}, [drawerOpen, policy_document_id]);

const fetchPdfUrl = async (id) => {
  try {
const data=await fetchData(`policy/open-policy-document/${id}/?access=read`)
if (data && data.pdf_url){
  setDocumentUrl(data.pdf_url);
}
} catch (error) {
      console.error("Error fetching PDF:", error);
    }
};

  return (
    <div>
      <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={handleDrawerClose}
      PaperProps={{ style: { width: "70%", height: "100%" } }}
    >
      <Box sx={{ position: "relative", height: "100%", backgroundColor: "white" }}>
        {/* Header */}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            height: "60px",
            backgroundColor: "#f8f9fc",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0 16px",
          }}
        >
          <IconButton onClick={handleDrawerClose} sx={{ zIndex: 1 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ marginTop: "60px", height: "calc(100% - 60px)" }}>
          {documentUrl ? (
            <iframe
              src={documentUrl}
              width="100%"
              height="100%"
              style={{ border: "none" }}
              title="PDF Viewer"
            />
          ) : (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
              <Typography variant="h6" color="textSecondary">
                No document available
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
    </div>
  );
};

export default RequirementDrawer;

