import { Autocomplete, TextField, Checkbox, Chip, Box, Tooltip } from "@mui/material";

const MultiSelect = ({ label, selectedValues, options, onChange, width = "250px" }) => {
  return (
    <Autocomplete
      multiple
      value={selectedValues}
      onChange={onChange}
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name} // Ensures only the name is displayed
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          sx={{
            width: width, // Dynamically update width
            minWidth: "200px",
            maxWidth: "100%",
            minHeight: "10px", // ✅ Set minimum height
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            "& .MuiInputBase-root": {
              minHeight: "10px", // ✅ Apply to input container
            },
            "& .MuiInputLabel-shrink": {
  transform: "translate(14px, -6px) scale(0.85) !important", // Ensures label moves up properly
  backgroundColor: "#eeeeee",
  padding: "0 4px", // Optional: Adds some spacing to avoid text overlap
}
          }}
        />
      )}
      renderTags={(selected, getTagProps) => {
        if (selected.length === 0) return null;

        const firstItem = selected[0]; // Show only the first selected item
        const remainingCount = selected.length - 1; // Count of hidden items

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              minHeight: "10px", // ✅ Apply min height to selected tags
            }}
          >
            <Chip
              key={firstItem.id}
              label={firstItem.name}
              {...getTagProps({ index: 0 })}
              sx={{
                maxWidth: "150px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginRight: "4px",
                minHeight: "10px", // ✅ Set min height for the chip
              }}
            />
            {remainingCount > 0 && (
              <Tooltip title={selected.map((s) => s.name).join(", ")}>
                <Chip
                  label={`+${remainingCount} more`}
                  sx={{
                    backgroundColor: "#e0e0e0",
                    color: "#000",
                    fontSize: "12px",
                    cursor: "pointer",
                    minHeight: "10px", // ✅ Apply to extra items indicator
                  }}
                />
              </Tooltip>
            )}
          </Box>
        );
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <li
            {...props}
            key={option.id}
            style={{ display: "flex", alignItems: "center", minHeight: "10px" }} // ✅ Apply min height
          >
            <Checkbox checked={selected} sx={{ color: "black", minHeight: "10px" }} />
            {option.name}
          </li>
        );
      }}
      sx={{
        minHeight: "10px", // ✅ Apply globally to the component
        "& .MuiAutocomplete-listbox": {
          color: "black",
          minHeight: "10px", // ✅ Ensure the dropdown also has min height
        },
      }}
    />
  );
};

export default MultiSelect;
