import React, { useState,useEffect } from 'react';
import { Drawer, 
  TextField, 
  Typography,
  Grid,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  Modal,
  Box, 
}
 from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import {Snackbar} from '@mui/material';
import cookie from "react-cookies";
import AWS from "aws-sdk";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { Error } from '@mui/icons-material';
import InfoIcon from "@mui/icons-material/Info"; 
import CloseIcon from "@mui/icons-material/Close";
import RiskPopup from "./RiskPopup";
import "./RiskRegister.css";



function CreateRiskRegister({ drawerModel1Open, riskAssessmentId, handleDrawerModelClose }) {
const [showDropdown, setShowDropdown] = useState(false);
const [isSubmitting, setIsSubmitting] = useState(false);
const [customMitigationPlan, setCustomMitigationPlan] = useState("");
const [artifactLink, setArtifactLink] = useState("");
const [openModal, setOpenModal] = useState(false);
const [isFullscreen, setIsFullscreen] = useState(false);
const [file, setFile] = useState(null);
const [selectedOption, setSelectedOption] = useState("");
const [dropdownOptions, setDropdownOptions] = useState([]);
const [uploadError, setUploadError] = useState("");
const [uploading,setUploading]=useState(false);
const [uploadedFileUrl, setUploadedFileUrl] = useState("");
const [popupOpen,setPopupOpen]=useState(false);
const [openSnackbar, setOpenSnackbar] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarType, setSnackbarType] = useState('');
const [formData, setFormData] = useState({
  risk_name: "",
  impact_c: null,
  impact_i:null,
  impact_a: null,
  likelihood: null,
  inherent_risk_value: "",
  inherent_risk: "",
  mitigation_plan: "",
  modified_impact_c: null,
  modified_impact_i:null,
  modified_impact_a:  null,
  modified_likelihood: null,
  residual_risk_value: "",
  residual_risk: "",
  risk_observation: "",
  risk_identification: "",
  target_date: null,
  alias_name: null,
  artifact_capture_date: null,
  artifact_expiry_date: null,
  artifact_name: null,
  artifact_path: "",
  status: null,
});
 const style = {
    position: "absolute",
    color: "#001b2b",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    p: 4,
    display: "flex",
    flexDirection: "column",
  };
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const [validationErrors, setValidationErrors] = useState({
      artifact_name: false,
      artifact_expiry_date: false,
    });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };
      const newStatus = determineStatus(updatedFormData);
      return {
        ...updatedFormData,
        status: newStatus,
      };
    });
  };
  const subdomain = window.location.host.split(".")[0];

  const handleSelectSuggestion = () => {
    if (formData.mitigation_plan) {
      const options = formData.mitigation_plan
        .split("\n")
        .filter((item) => item.trim() !== "");
      setDropdownOptions(options);
      setShowDropdown(true);
    } else { 
      setSnackbarMessage(" Mitigation plan is Empty.");
      setSnackbarType("error");
      setOpenSnackbar(true);
      setDropdownOptions([]);
      setShowDropdown(false);
    }
  };
  const handleOptionSelect = (event) => {
    const selected = event.target.value;
    const cleanedSelected = selected.replace(/^\d+\)\s*/, ""); 
    setSelectedOption(cleanedSelected);
    setFormData({ ...formData, applied_mitigation_plan: cleanedSelected });
    setShowDropdown(false);
  };
  const handleOpenArtifactModal = (link) => {
    setArtifactLink(link);
    setOpenModal(true);
  };
  const calculateResidualRiskValue = () => {
    const {
      modified_impact_c,
      modified_impact_i,
      modified_impact_a,
      modified_likelihood,
    } = formData;
    return (
      modified_likelihood *
      (parseInt(modified_impact_c) +
        parseInt(modified_impact_i) +
        parseInt(modified_impact_a))
    );
  };
    const determineStatus = (data) => {
    const { treatment, justification, artifact_path, target_date } =data || formData;
        if (
          (treatment === "ACCEPT" && justification) ||
          (treatment === "AVOID" && justification) ||
          (treatment === "TRANSFER" && justification) ||
          (treatment === "MITIGATE" && justification && artifact_path)
        ) {
          return "Closed";
        }

        if (
          treatment === "MITIGATE" &&
          target_date &&
          (!artifact_path || !justification)
        ) {
          return "In Progress";
        }
        return "Open";
      };
    
    useEffect(() => {
      const inherentRiskValue = calculateInherentRiskValue();
      setFormData((prevFormData) => ({
        ...prevFormData,
        inherent_risk_value: inherentRiskValue,
        inherent_risk: determineRiskLevel(inherentRiskValue),
      }));
    }, [
      formData.impact_c,
      formData.impact_i,
      formData.impact_a,
      formData.likelihood,
    ]);
    const calculateInherentRiskValue = () => {
      const { impact_c, impact_i, impact_a, likelihood } = formData;
      return (
        likelihood *
        (parseInt(impact_c) + parseInt(impact_i) + parseInt(impact_a))
      );
    };
    

    const handleFileUpload = async (file, artifactId) => {
      if (!file) {
          setSnackbarMessage("No file selected. Please select a file to upload.");
          setSnackbarType("error");
          setOpenSnackbar(true);
          return;
      }
  
      setUploading(true);
      setUploadError("");
      setUploadedFileUrl("");
  
      const newFileName = `${Date.now()}_${file.name.replace(/[^a-zA-Z0-9.]/g, "_")}`;
      const contentType = file.type || "application/octet-stream";
  
      const apiUrl = `${process.env.REACT_APP_BACKEND}${subdomain}/${
        cookie.load("Language") || "en"
      }/artifact/generate-presigned-urls/`;
  
      try {
          const response = await fetch(apiUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "X-CSRFToken": cookie.load("csrftoken"),
              },
              credentials: "include",
              body: JSON.stringify({
                  artifact_id: artifactId,
                  files: [{ file_path: newFileName, content_type: contentType }],
              }),
          });
  
          if (!response.ok) throw new Error("Error generating pre-signed URL");
  
          const data = await response.json();
          const presignedUrl = data.presigned_urls[0]?.presigned_url;
          if (!presignedUrl) throw new Error("Failed to get pre-signed URL");
  
          const uploadResponse = await fetch(presignedUrl, {
              method: "PUT",
              headers: { "Content-Type": contentType },
              body: file,
          });
  
          if (!uploadResponse.ok) throw new Error("Failed to upload file to S3");
  
          const uploadedFileUrl = presignedUrl.split("?")[0];
          const updatedFormData = { ...formData, artifact_path: uploadedFileUrl };
          const newStatus = determineStatus(updatedFormData);
  
          setFormData({ ...updatedFormData, status: newStatus });
          setUploadedFileUrl(uploadedFileUrl);
          setSnackbarMessage("File uploaded successfully!");
          setSnackbarType("success");
          setOpenSnackbar(true);
      } catch (error) {
          setSnackbarMessage(error.message || "An unexpected error occurred.");
          setSnackbarType("error");
          setOpenSnackbar(true);
      } finally {
          setUploading(false);
      }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // File selection
    if (selectedFile) {
        handleFileUpload(selectedFile, formData.artifact_id); // Pass file and artifact_id
    }
};
  
    const handleCloseArtifactModal = () => {
      setOpenModal(false);
      setArtifactLink("");
      setIsFullscreen(false);
    };
    const handleToggleFullscreen = () => {
      setIsFullscreen(!isFullscreen);
    };
  
  
    const renderFileViewer = (link) => {
      const fileExtension = link.split(".").pop().toLowerCase();
      if (["pdf"].includes(fileExtension)) {
        return (
          <iframe
            src={link}
            title="Artifact"
            width="100%"
            height="100%"
            style={{ border: "none" }}
          />
        );
      }
      if (["doc", "docx", "ppt", "pptx", "txt"].includes(fileExtension)) {
        return (
          <iframe
            src={`https://docs.google.com/gview?url=${encodeURIComponent(link)}&embedded=true`}
            title="Artifact"
            width="100%"
            height="100%"
            style={{ border: "none" }}
          />
        );
      }
      
      if (["xls", "xlsx"].includes(fileExtension)) {
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(link)}`}
            title="Artifact"
            width="100%"
            height="100%"
            style={{ border: "none" }}
          />
        );
      }
      if (["png", "jpg", "jpeg", "gif"].includes(fileExtension)) {
        return (
          <img
            src={link}
            alt="Artifact"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
              flexGrow: 1,
            }}
          />
        );
      }
      return <Typography variant="h6">Unsupported file type</Typography>;
    };
    const determineRiskLevel = (value) => {
      if (value <= 9) return "Low";
      if (value > 9 && value <= 18) return "Medium";
      return "High";
      };
      const validateField = (value) => {
        if (value === null) return true;
        return value >= 1 && value <= 3;
    };
     const isFormValid = () => {
      return (
        formData.impact_c >= 1 && formData.impact_c <= 3 &&
        formData.impact_i >= 1 && formData.impact_i <= 3 &&
        formData.impact_a >= 1 && formData.impact_a <= 3 &&
        formData.likelihood >= 1 && formData.likelihood <= 3 &&
        (!formData.modified_likelihood || (formData.modified_likelihood >= 1 && formData.modified_likelihood <= 3))
      );
    }
    const getErrorMessage = (value) => {
      if (value === null || value === "") return "";
      return value < 1 || value > 3 ? "Value must be between 1 and 3" : "";
    };
      useEffect(() => {
        if (drawerModel1Open) {
          setCommentError("");
          setArtifactNameError("");
          setTargetDateError(""); 
          setArtifactExpiryDateError("");
          setMitigationError("");
        }
      }, [drawerModel1Open]);

    const [comment, setComment] = useState("");
    const [commentError, setCommentError] = useState(false);
    const commentMaxLength = 300;

    const handleCommentChange = (event) => {
      const value = event.target.value;
      setComment(value);
      
      if (!value) {
        setCommentError(`Comment is required`);
      } else if (value.length > commentMaxLength) {
        setCommentError(`Comment cannot exceed ${commentMaxLength} characters.`);
      } else {
        setCommentError("");
      }
     };
    const [mitigation, setMitigation] = useState("");
    const [mitigationError, setMitigationError] = useState(false);
    const mitigationMaxLength = 100;
   
    const handleMitigationChange = (event) => {
      const value = event.target.value;
      setMitigation(value);
      
      if (!value) {
        setMitigationError(`Mitigation plan is required`);
      } else if (value.length > mitigationMaxLength) {
        setMitigationError(`Mitigation plan cannot exceed ${mitigationMaxLength} characters.`);
      } else {
        setMitigationError("");
      }
    };
     const [targetDate, setTargetDate] = useState("");
     const [targetDateError, setTargetDateError] = useState(false);
   
     const handleTargetDateChange = (event) => {
       const value = event.target.value;
       setTargetDate(value);
       
       if (!value) {
         setTargetDateError(`Target Date is required`);
       } else {
         setTargetDateError("");
       }
     };
     const [artifactName, setArtifactName] = useState("");
     const [artifactNameError, setArtifactNameError] = useState(false);
     const artifactNameMaxLength = 100;
     const handleArtifactNameChange = (event) => {
        const value = event.target.value;
        setArtifactName(value);
        
        if (!value) {
         setArtifactNameError(`Artifact Name is required`);
        } else if (value.length > artifactNameMaxLength ) {
         setArtifactNameError(`Artifact Name cannot exceed ${artifactNameMaxLength } characters.`);
        } else {
         setArtifactNameError("");
        }
      };
      const [artifactExpiryDate, setArtifactExpiryDate] = useState("");
      const [artifactExpiryDateError,  setArtifactExpiryDateError] = useState(false);
         
           const handletArtifactExpiryDateChange = (event) => {
             const value = event.target.value;
             setArtifactExpiryDate(value);
             if (!value) {
              setArtifactExpiryDateError(`Artiact expiry Date is required`);
            } else {
              setArtifactExpiryDateError("");
            }
           };
           const handleCreateSubmit = async () => {
            
            setIsSubmitting(true);
            setUploadedFileUrl("");
            let hasError = false;
  
            let errorMessage = "";
            
            if (formData.treatment === "MITIGATE") {
              let hasError = false;
      
              if (!formData.artifact_name) {
                  setArtifactNameError("Artifact Name is Required");
                  hasError = true;
              } else {
                  setArtifactNameError(""); 
              }
          
              if (!formData.artifact_expiry_date) {
                  setArtifactExpiryDateError("Artifact Expiry Date is Required");
                  hasError = true;
              } else {
                  setArtifactExpiryDateError("");
              }
          
              if (!formData.target_date) {
                  setTargetDateError("Target Date is Required");
                  hasError = true;
              } else {
                  setTargetDateError("");
              }
          
              if (!formData.justification) {
                  setCommentError("Comment is Required");
                  hasError = true;
              } else {
                  setCommentError("");
              }
          
              if (hasError) {
                  setSnackbarType("error"); 
                  setSnackbarMessage("Please fill in required fields.");
                  setOpenSnackbar(true);
                  setIsSubmitting(false); 
                  return;
              }
          }if (!formData.treatment) {
            setCommentError("comment is required");
            hasError = true;
            errorMessage = "Please select a treatment type & fill required field.";
        } 
        else if (["TRANSFER", "AVOID", "ACCEPT"].includes(formData.treatment)) {
            if (!formData.justification) {
                setCommentError("Comment is required");
                hasError = true;
                errorMessage = "Comment is required for this treatment type.";
            } else {
                setCommentError(""); 
            }
        }
        
        if (hasError) {
            setSnackbarType("error"); 
            setSnackbarMessage(errorMessage);
            setOpenSnackbar(true);
            setIsSubmitting(false); 
            return;
        }

            if (file || formData.artifact_path) {
              if (!formData.artifact_name || !formData.artifact_expiry_date) {
                alert("Please fill in the artifact name and expiry date.");
                setIsSubmitting(false);
                return;
              }
            }
        
            let artifactPath = formData.artifact_path || null;   
            if (file) {
                artifactPath = await handleFileUpload(file, formData.artifact_id);
                if (!artifactPath) {
                    setSnackbarType("error");
                    setSnackbarMessage("File upload failed. Please try again.");
                    setOpenSnackbar(true);
                    setIsSubmitting(false);
                    return;
                }
            }
        
            const updatedFormData = {
              ...formData,
              alias_name: formData.risk_name || null,
              mitigation_plan: customMitigationPlan || formData.mitigation_plan,
              inherent_risk_value: calculateInherentRiskValue(),
              inherent_risk: determineRiskLevel(calculateInherentRiskValue()),
              residual_risk_value: calculateResidualRiskValue(),
              residual_risk: determineRiskLevel(calculateResidualRiskValue()),
              target_date: formData.target_date || null,
              artifact_name: formData.artifact_name || null,
              artifact_expiry_date: formData.artifact_expiry_date || null,
              artifact_path: artifactPath,
            };
        
            const csrfToken = cookie.load("csrftoken");
        
            try {
              await fetch(
                `${process.env.REACT_APP_BACKEND}${subdomain}/${
                  cookie.load("Language") || "en"
                }/riskassessment/customriskregister/${riskAssessmentId}/`,
                {
                  method: "POST",
                  credentials: "include",
                  headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken,
                  },
                  body: JSON.stringify(updatedFormData),
                }
              );
              handleDrawerModelClose();
            } catch (error) {
            } finally {
              setIsSubmitting(false);
            }
          };

  return (
    <Drawer
      anchor="right"
      open={drawerModel1Open}
      onClose={handleDrawerModelClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: "40%",
          padding: "20px",
        },
      }}
    >
         <Typography variant="h6" className="risk-typography-5">
              Create Risk Register
            </Typography>
    <Grid container spacing={2}>
  
      <Grid item xs={12}>
        <TextField
          label="Risk Name"
          name="risk_name"
          value={formData.risk_name}
          onChange={(e) => {
            handleInputChange(e); 
          }}
          fullWidth
          type="text"
          margin="normal"
        />
      </Grid>
      <Grid item xs={12}>
      <TextField
        label="Confidentiality Impact"
        name="impact_c"
        value={formData.impact_c}
        onChange={handleInputChange}
        fullWidth
        type="number"
        margin="normal"
        inputProps={{
          min: 1,  
          max: 3,  
          step: 1,  
        }}
        InputProps={{
          endAdornment: (
            <Tooltip title="Severity / Impact">
            <IconButton onClick={() => setPopupOpen("impact")}>
            <InfoIcon />
            </IconButton>
            </Tooltip>
          ),
        }}
        error={!validateField(formData.impact_c)}  
        helperText={getErrorMessage(formData.impact_c)} 
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Integrity Impact"
        name="impact_i"
        value={formData.impact_i}
        onChange={handleInputChange}
        fullWidth
        type="number"
        margin="normal"
        inputProps={{
          min: 1,  
          max: 3,  
          step: 1,  
        }}
        InputProps={{
          endAdornment: (
            <Tooltip title="Severity / Impact">
             <IconButton onClick={() => setPopupOpen("impact")}>
            <InfoIcon />
          </IconButton>
            </Tooltip>
          ),
        }}
        error={!validateField(formData.impact_i)}  
        helperText={getErrorMessage(formData.impact_i)}  
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Availability Impact"
        name="impact_a"
        value={formData.impact_a}
        onChange={handleInputChange}
        fullWidth
        type="number"
        margin="normal"
        inputProps={{
          min: 1, 
          max: 3,  
          step: 1,  
        }}
        InputProps={{
          endAdornment: (
            <Tooltip title="Severity / Impact">
               <IconButton onClick={() => setPopupOpen("impact")}>
                 <InfoIcon />
               </IconButton>
            </Tooltip>
          ),
        }}
        error={!validateField(formData.impact_a)}  
        helperText={getErrorMessage(formData.impact_a)}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label="Likelihood"
        name="likelihood"
        value={formData.likelihood}
        onChange={handleInputChange}
        fullWidth
        type="number"
        margin="normal"
        inputProps={{
          min: 1,  
          max: 3, 
          step: 1, 
        }}
        InputProps={{
          endAdornment: (
            <Tooltip title="Probability / Likelihood">
               <IconButton onClick={() => {setPopupOpen("likelihood");}} >
                 <InfoIcon />
              </IconButton>
            </Tooltip>    
          ),
        }}
        error={!validateField(formData.modified_likelihood)}  
        helperText={getErrorMessage(formData.modified_likelihood)}  
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Inherent Risk Value"
        value={formData.inherent_risk_value}
        fullWidth
        disabled
        margin="normal"
        InputProps={{
          endAdornment: (
            <Tooltip title="Inherent Risk Value Criteria">
              <IconButton onClick={() => setPopupOpen("criteria")}>
               <InfoIcon />
            </IconButton>
            </Tooltip>
          ),
        }}
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Inherent Risk"
        value={formData.inherent_risk}
        fullWidth
        disabled
        margin="normal"
        InputProps={{
          endAdornment: (
            <Tooltip title="Inherent Risk Matrix">
              <IconButton onClick={() => setPopupOpen("riskMatrix")}>
                <InfoIcon />
               </IconButton>
            </Tooltip>
          ),
        }}
      />
    </Grid>
    <Grid item xs={12}>
      <FormControl fullWidth disabled margin="normal">
        <InputLabel>Status</InputLabel>
        <Select
          name="status"
          value={formData.status || ""}
          onChange={handleInputChange}
          label="Status"
        >
          <MenuItem value="Open">Open</MenuItem>
          <MenuItem value="In Progress">In Progress</MenuItem>
          <MenuItem value="Closed">Closed</MenuItem>
        </Select>
      </FormControl>
    </Grid>
     <Grid item xs={12}>
          <FormControl fullWidth  margin="normal">
            <InputLabel>Treatment</InputLabel>
            <Select
              name="treatment"
              value={formData.treatment || ""}
              onChange={handleInputChange}
              label="Treatment"
            >
              <MenuItem value="MITIGATE">Mitigate</MenuItem>
              <MenuItem value="TRANSFER">Transfer</MenuItem>
              <MenuItem value="AVOID">Avoid</MenuItem>
              <MenuItem value="ACCEPT">Accept</MenuItem>
            </Select>
          </FormControl>
        </Grid>
           {formData.treatment && (
          <Grid item xs={12}>
            <TextField
              label="Comment"
              name="justification"
              value={formData.justification || ""}
              onChange={(e) => {
                handleCommentChange(e);
                handleInputChange(e);
              }}
              error={!!commentError}
              helperText={commentError}
              fullWidth
              required
              margin="normal"
            />
          </Grid>
        )}
            {formData.treatment === "MITIGATE" && (
              <>
              <Grid item xs={12}>
                 <Box>
                   <Box>
                     <Box
                       className="risk-reg-box-7"
                     >
                       <Box display="flex" alignItems="flex-end">
                         <TextField
                           required
                           label="Mitigation Plan"
                           name="mitigation_plan"
                           value={formData.applied_mitigation_plan || ""}
                           onChange={(e) => {
                             setFormData({
                               ...formData,
                               applied_mitigation_plan: e.target.value,
                             });
                             handleMitigationChange(e);
                           }}
                           error={!!mitigationError}
                           helperText={mitigationError}
                           fullWidth
                           margin="normal"
                           rows={4}
                           sx={{ marginBottom: 0 }}
                          />
                     <Button
                        variant="contained"
                        onClick={() => {
                          handleSelectSuggestion();
                        }}
                        sx={{
                          ml: 2,
                          backgroundColor: "#001b2b",
                          color: "#ffffff",
                          "&:hover": { backgroundColor: "#001b2b" },
                        }}
                     >
                       Suggestions
                     </Button>
                       </Box>
       
                       {showDropdown && (
                         <FormControl fullWidth sx={{ mt: 2 }}>
                           <InputLabel id="select-suggestion-label">
                             Select Suggestion
                           </InputLabel>
                           <Select
                             labelId="select-suggestion-label"
                             value={selectedOption}
                             onChange={handleOptionSelect}
                             fullWidth
                             MenuProps={{
                               PaperProps: {
                                 sx: { maxHeight: 200, maxWidth: 500 },
                               },
                             }} 
                           >
                             {dropdownOptions.map((option, index) => (
                               <MenuItem
                                 key={index}
                                 value={option}
                                 sx={{
                                   whiteSpace: "normal",
                                   wordWrap: "break-word",
                                 }}
                               >
                                 {option}
                               </MenuItem>
                             ))}
                           </Select>
                         </FormControl>
                       )}
                     </Box>
                   </Box>
                 </Box>
               </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Target Date"
            name="target_date"
            type="date"
            value={formData.target_date || ""}
            onChange={(e) => {
              handleTargetDateChange(e); 
              handleInputChange(e); 
            }}
            error={!!targetDateError}
            helperText={targetDateError}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Artifact Name"
            name="artifact_name"
            value={formData.artifact_name || ""}
            onChange={(e) => {
              handleArtifactNameChange(e); 
              handleInputChange(e); 
            }}
            error={!!artifactNameError}
            helperText={artifactNameError}
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Artifact Expiry Date"
            name="artifact_expiry_date"
            type="date"
            value={formData.artifact_expiry_date || ""}
            onChange={(e) => {
              handletArtifactExpiryDateChange(e); 
              handleInputChange(e); 
            }}
            error={!!artifactExpiryDateError}
            helperText={artifactExpiryDateError}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {formData.artifact_path && (
              <Button
                variant="contained"
                onClick={() =>
                  handleOpenArtifactModal(formData.artifact_path)
                }
                fullWidth
                style={{
                  marginTop: "16px",
                  backgroundColor: "#001b2b",
                }}
              >
                View Artifact
              </Button>
            )}
            <input
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-input"
            />
            <Box display="flex" justifyContent="flex-end" width="100%">
              <label htmlFor="file-input" style={{ width: "100%" }}>
                <Button
                  variant="contained"
                  component="span"
                  fullWidth  
                  style={{
                    marginTop: "16px",
                    backgroundColor: "#001b2b", 
                    color: "#fff",
                    width: "100%", 
                  }}
                >
                  Choose File
                </Button>
              </label>
            </Box>
            {validationErrors.artifact_path && (
              <Typography variant="body2" color="error">
                Artifact Path is required
              </Typography>
            )}
            {file && (
              <Typography
                variant="body2"
                style={{ marginTop: "10px", color: "black" }}
              >
                {file.name}
              </Typography>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Modified Likelihood"
            name="modified_likelihood"
            value={formData.modified_likelihood}
            onChange={handleInputChange}
            fullWidth
            type="number"
            margin="normal"
            inputProps={{
              min: 1,  
              max: 3,  
              step: 1,  
            }}
            InputProps={{
              endAdornment: (
                <Tooltip title="Probability / Likelihood">
                 <IconButton onClick={() => {
                     setPopupOpen("likelihood"); }}>
                 <InfoIcon />
                 </IconButton>
                </Tooltip>
              ),
            }}
            error={!validateField(formData.modified_likelihood)}  
            helperText={getErrorMessage(formData.modified_likelihood)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Residual Risk Value"
            value={calculateResidualRiskValue()}
            fullWidth
            disabled
            margin="normal"
            InputProps={{
              endAdornment: (
                <Tooltip title="Residual Risk Value Criteria">
                 <IconButton onClick={() => {setPopupOpen("criteria"); }}>
                 <InfoIcon />
                </IconButton>
                </Tooltip>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Residual Risk"
            value={determineRiskLevel(calculateResidualRiskValue())}
            fullWidth
            disabled
            margin="normal"
            InputProps={{
              endAdornment: (
                <Tooltip title="Residual Risk Matrix">
                  <IconButton onClick={() => setPopupOpen("riskMatrix")}>
                  <InfoIcon />
                 </IconButton>
                </Tooltip>
              ),
            }}
          />
        </Grid>
      </>
    )}
        {formData.treatment === "TRANSFER" && (
      <>
        <Grid item xs={12}>
          <TextField
            label="Modified Likelihood"
            name="modified_likelihood"
            value={formData.modified_likelihood}
            onChange={handleInputChange}
            fullWidth
            type="number"
            margin="normal"
            inputProps={{
              min: 1,  
              max: 3,  
              step: 1,  
            }}
            InputProps={{
              endAdornment: (
                <Tooltip title="Probability / Likelihood">
                  <IconButton onClick={() => { setPopupOpen("likelihood");}}>
                 <InfoIcon />
                </IconButton>
                </Tooltip>
              ),
            }}
            error={!validateField(formData.modified_likelihood)}  
            helperText={getErrorMessage(formData.modified_likelihood)}  
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Residual Risk Value"
            value={calculateResidualRiskValue()}
            fullWidth
            disabled
            margin="normal"
            InputProps={{
              endAdornment: (
                <Tooltip title="Residual Risk Value Criteria">
                   <IconButton onClick={() => {setPopupOpen("criteria");
                    }}>
              <InfoIcon />
            </IconButton>
                </Tooltip>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Residual Risk"
            value={determineRiskLevel(calculateResidualRiskValue())}
            fullWidth
            disabled
            margin="normal"
            InputProps={{
              endAdornment: (
                <Tooltip title="Residual Risk Matrix">
                   <IconButton onClick={() => setPopupOpen("riskMatrix")}>
                <InfoIcon />
              </IconButton>
                </Tooltip>
              ),
            }}
          />
        </Grid>
      </>
    )}
     {formData.risk_assessment_type === "AUDITOR" && (
          <>
            <Grid item xs={12}>
              <TextField
                label="Risk Observation"
                name="risk_observation"
                value={formData.risk_observation || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Risk Identification"
                name="risk_identification"
                value={formData.risk_identification || ""}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </Grid>
          </>
        )}
          <Modal
        open={openModal}
        onClose={handleCloseArtifactModal}
        aria-labelledby="artifact-modal-title"
        aria-describedby="artifact-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
       >
        <Box
          sx={{
            ...style,
            width: isFullscreen ? "100%" : "80%",
            height: isFullscreen ? "100%" : "80%",
          }}
        >
          <IconButton
            onClick={handleCloseArtifactModal}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
          <CloseIcon />
          </IconButton>
          <IconButton
            onClick={handleToggleFullscreen}
            sx={{ position: "absolute", top: 10, right: 50 }}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
          <Typography id="artifact-modal-title" variant="h6" component="h2">
            Artifact Viewer
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              mt: 2,
              overflow: "auto",
              flexGrow: 1,
            }}
          >
            {renderFileViewer(artifactLink)}
          </Box>
        </Box>
      </Modal>
 <RiskPopup
      open={Boolean(popupOpen)} 
      onClose={() => setPopupOpen(null)}
      type={popupOpen} 
    />


  <Box display="flex" justifyContent="flex-end" mt={2}>
      <Button
        className="risk-reg-btn-3"
        onClick={handleDrawerModelClose}
      >
        Cancel
      </Button>
      
      <Button
      className="risk-reg-btn-2"
      onClick={ handleCreateSubmit}
      disabled={!isFormValid() || isSubmitting}  
     >
      
     Submit
     </Button>
    </Box>
      <Snackbar
            open={openSnackbar}
            autoHideDuration={4000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} 
          >
              <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
                {snackbarMessage}
              </Alert>
           </Snackbar>
    </Grid>
    </Drawer>
  );
}

export default CreateRiskRegister;
